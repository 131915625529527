import React, { useState, useRef } from "react";
import Footer from "../../../Components/Footer";
import Header from "../../../Components/Header/Header";
import "./mvp.css"
const URLs = {
	"Invoice": "https://api.staging.metashape.ai/prolab/invoice/v1/service/file/upload",
	"Receipt": "https://gpu-ai.metashape.ai/upload",
	"Czech_Receipt": BASE+"companies/prolab"
}
const ProlabMVP = () => {
	const inputRef = useRef();
	const [imgSrc, setImgSrc] = useState("")
	const [file, setFile] = useState(null);
	const [loadingState, setLoadingState] = useState(false);
	const [apiResponse, setApiResponse] = useState(null);
	const [docTypeValue, setdocTypeValue] = useState("Czech_Receipt");

	const handleUploadBtnClick = event => {
		if (loadingState || !event) {
			return
		} else {
			inputRef.current.click();
		}
	}

	const handleFileChange = event => {
		const selectedFile = event.target.files[0];
		setFile(selectedFile);
		var reader = new FileReader();
		var url = reader.readAsDataURL(selectedFile);

		reader.onloadend = function (e) {
			setImgSrc([reader.result])
		};
		getResults(selectedFile)
	}

	const getResults = async (selectedFile) => {
		setLoadingState(true)
		const body = new FormData()
		body.append('file', selectedFile)
		if(docTypeValue=='Czech_Receipt') {
			body.append('lang', 'Czech')
			body.append('doc_type', 'Receipt')
			body.append('client_id', 'Xyb1cMVqtz')
			body.append('client_secret', '4tW.p14EZ@rpvBDB5DFRA6fBe')
		}
		fetch(URLs[docTypeValue], {
			// headers: {
			// 	'Accept': 'application/json',
			// 	'Content-Type': 'application/json'
			// },
			method: "POST",
			redirect: "follow",
			body: body,
			referrerPolicy: "unsafe-url"
		})
			.then(function (response) {
				return response.json();
			})
			.then(function (result) {
				setApiResponse(result)
				setLoadingState(false)
			})
			.catch(function (error) {
				console.error(error);
				alert('Request failed. Please contact admin');
				setLoadingState(false)
			});
	}

	return (
		<div>
			<Header />
			<input
				ref={inputRef}
				accept=".jpeg,.jpg,.png,.bmp"
				style={{ display: "none" }}
				id="prolab-mvp-upload-file"
				type="file"
				onChange={handleFileChange}
			/>
			<div className="text-center button-wrapper">
				{/* <label htmlFor="prolab-mvp-upload-file"> */}
				<select name="doc-type" id="doc-type"
					className="doc-type-select" value={docTypeValue}
					onChange={e => { setdocTypeValue(e.target.value) }}>
					<option value="Invoice">Invoice</option>
					<option value="Receipt">GreekReceipt</option>
					<option value="Czech_Receipt">CzechReceipt</option>
				</select>
				{!loadingState ?
					<button className="btn btn-submit upload-file-btn d-inline-block" onClick={handleUploadBtnClick} disabled={loadingState}>Upload</button> :
					<p className="progress-status d-inline-block">Processing ...</p>
				}
				{/* </label> */}
			</div>

			<div className="mvp-body">
				<div className="col-sm-5 div-blocks d-inline-block">
					<img src={imgSrc} className="selected-img" />
				</div>
				<div className="col-sm-5 pull-right div-blocks d-inline-block">
					{apiResponse ?
						<>
							<a
								href={`data:text/json;charset=utf-8,${encodeURIComponent(
									JSON.stringify(apiResponse)
								)}`}
								download={file.name.split('.').slice(0, -1).join('.') + ".json"}
								className="btn download-file-btn"
							>
								Download Json
							</a>
							<div className="result-block text-left">
								<pre>{JSON.stringify(apiResponse, null, 2)}</pre>
							</div>
						</>
						: <></>}
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default ProlabMVP;
