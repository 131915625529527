import { _via_event } from './viaEvent'
import { _via_metadata, _via_util_float_arr_to_fixed, _VIA_RSHAPE, _via_util_date_to_filename_str, _via_view, _via_util_infer_file_type_from_filename, _VIA_FILE_LOC, _via_util_msg_show, _via_file, _via_util_infer_file_loc_from_filename } from './utils'
const _VIA_PROJECT_ID_MARKER = '__VIA_PROJECT_ID__';
const _VIA_FLOAT_FIXED_POINT = 3;
let showCalculatorModal = {}
export class _via_data extends _via_event {
	constructor(showModalFn) {
		super()
		this._ID = "_via_data_";
		showCalculatorModal = showModalFn
		this.store = {};
		this.store["project"] = {
			pid: "__VIA_PROJECT_ID__",
			rev: "__VIA_PROJECT_REV_ID__",
			rev_timestamp: "__VIA_PROJECT_REV_TIMESTAMP__",
			pname: "Unnamed VIA Project",
			data_format_version: this.DATA_FORMAT_VERSION,
			creator:
				"VGG Image Annotator (http://www.robots.ox.ac.uk/~vgg/software/via)",
			created: Date.now(),
			vid_list: [],
		};
		this.store["config"] = {
			file: {
				loc_prefix: { 1: "", 2: "", 3: "", 4: "" }, // constants defined in _via_file._VIA_FILE_LOC
			},
			ui: {
				file_content_align: "left",
				file_metadata_editor_visible: true,
				spatial_metadata_editor_visible: true,
				spatial_region_label_attribute_id: "",
				gtimeline_visible_row_count: "4",
			},
		};
		this.store["attribute"] = {};
		this.store["file"] = {};
		this.store["metadata"] = {};
		this.store["view"] = {};
		this.cache = {};
		this.cache["mid_list"] = {};
		this.cache["attribute_group"] = {};
		this.file_ref = {}; // ref. to files selected using browser's file selector
		this.file_object_uri = {}; // WARNING: cleanup using file_object_url[fid]._destroy_file_object_url()

		this.DATA_FORMAT_VERSION = "3.1.1";

		// // registers on_event(), emit_event(), ... methods from
		// new _via_event(this);
	}
	_attribute_get_new_id() {
		var aid_list = Object.keys(this.store.attribute).map(Number).sort();
		var n = aid_list.length;
		var aid;
		if (n) {
			aid = aid_list[n - 1] + 1;
		} else {
			aid = 1;
		}
		return aid;
	};
	_init_default_project() {
		const p = {};
		p['project'] = {
			'pid': '__VIA_PROJECT_ID__',
			'rev': '__VIA_PROJECT_REV_ID__',
			'rev_timestamp': '__VIA_PROJECT_REV_TIMESTAMP__',
			'pname': 'Unnamed VIA Project',
			'data_format_version': this.DATA_FORMAT_VERSION,
			'creator': 'VGG Image Annotator (http://www.robots.ox.ac.uk/~vgg/software/via)',
			'created': Date.now(),
			'vid_list': [],
		}
		p['config'] = {
			'file': {
				'loc_prefix': { '1': '', '2': '', '3': '', '4': '' }, // constants defined in _via_file._VIA_FILE_LOC
			},
			'ui': {
				'file_content_align': 'left',
				'file_metadata_editor_visible': true,
				'spatial_metadata_editor_visible': true,
				'spatial_region_label_attribute_id': '',
				'gtimeline_visible_row_count': '4',
			},
		};
		p['attribute'] = {};
		p['file'] = {};
		p['metadata'] = {};
		p['view'] = {};
		this.cache = {};
		this.cache['mid_list'] = {};
		this.cache['attribute_group'] = {};

		return p;
	}

	_attribute_exist(aname) {
		var aid;
		for (aid in this.store["attribute"]) {
			if (this.store["attribute"][aid].aname === aname) {
				return true;
			}
		}
		return false;
	};

	attribute_add(
		name,
		anchor_id,
		type,
		desc,
		options,
		default_option_id
	) {
		return new Promise(
			function (ok_callback, err_callback) {
				if (this._attribute_exist(name)) {
					err_callback("attribute already exists");
					return;
				}

				var aid = this._attribute_get_new_id();
				var desc = desc || "";
				var options = options || {};
				var default_option_id = default_option_id || "";
				this.store["attribute"][aid] = new _via_attribute(
					name,
					anchor_id,
					type,
					desc,
					options,
					default_option_id
				);
				this._cache_update_attribute_group();
				this.emit_event("attribute_add", { aid: aid });
				ok_callback(aid);
			}.bind(this)
		);
	};

	attribute_del(aid) {
		return new Promise(
			function (ok_callback, err_callback) {
				if (this._attribute_exist(name)) {
					err_callback("attribute already exists");
					return;
				}

				// delete all metadata entries for aid
				for (var mid in this.store.metadata) {
					if (this.store.metadata[mid].av.hasOwnProperty(aid)) {
						delete this.store.metadata[mid].av[aid];
					}
				}

				// delete aid
				delete this.store.attribute[aid];
				this._cache_update_attribute_group();
				this.emit_event("attribute_del", { aid: aid });
				ok_callback(aid);
			}.bind(this)
		);
	};

	attribute_update_anchor_id(aid, anchor_id) {
		return new Promise(
			function (ok_callback, err_callback) {
				if (!this.store.attribute.hasOwnProperty(aid)) {
					err_callback("aid does not exist");
					return;
				}
				this.store.attribute[aid].anchor_id = anchor_id;
				this._cache_update_attribute_group();
				this.emit_event("attribute_update", { aid: aid });
				ok_callback(aid);
			}.bind(this)
		);
	};

	attribute_anchor_value_to_name(anchor_value) {
		for (var anchor_name in _VIA_ATTRIBUTE_ANCHOR) {
			if (_via_util_array_eq(_VIA_ATTRIBUTE_ANCHOR[anchor_name], anchor_value)) {
				return anchor_name;
			}
		}
		return "";
	};

	attribute_update_aname(aid, new_aname) {
		return new Promise(
			function (ok_callback, err_callback) {
				if (!this.store.attribute.hasOwnProperty(aid)) {
					err_callback("aid does not exist");
					return;
				}
				this.store["attribute"][aid]["aname"] = new_aname;
				this.emit_event("attribute_update", { aid: aid, aname: new_aname });
				ok_callback(aid);
			}.bind(this)
		);
	};

	attribute_update_type(aid, new_type) {
		return new Promise(
			function (ok_callback, err_callback) {
				if (!this.store.attribute.hasOwnProperty(aid)) {
					err_callback("aid does not exist");
					return;
				}
				this.store["attribute"][aid]["type"] = new_type;
				this.emit_event("attribute_update", { aid: aid, type: new_type });
				ok_callback(aid);
			}.bind(this)
		);
	};

	// option_csv = option1,*default_option,option2,...
	attribute_update_options_from_csv(
		aid,
		options_csv
	) {
		return new Promise(
			function (ok_callback, err_callback) {
				if (!this.store.attribute.hasOwnProperty(aid)) {
					err_callback("aid does not exist");
					return;
				}
				options_csv = options_csv.trim();
				this.store["attribute"][aid]["options"] = {};
				this.store["attribute"][aid]["default_option_id"] = "";
				var options = options_csv.split(",");
				for (var oid = 0; oid < options.length; ++oid) {
					var oval = options[oid];
					oval = oval.trim();
					if (oval.startsWith("*")) {
						this.store["attribute"][aid]["default_option_id"] = oid.toString();
						oval = oval.substring(1); // remove *
					}
					this.store["attribute"][aid]["options"][oid] = oval;
				}
				this.emit_event("attribute_update", { aid: aid });
				ok_callback(aid);
			}.bind(this)
		);
	};

	//
	// file
	//
	_file_get_new_id() {
		var max_fid = -Infinity;
		var fid;
		for (var fid_str in this.store.file) {
			fid = parseInt(fid_str);
			if (fid > max_fid) {
				max_fid = fid;
			}
		}
		if (max_fid === -Infinity) {
			return "1";
		} else {
			return (max_fid + 1).toString();
		}
	};

	file_add(name, type, loc, src) {
		return new Promise(
			function (ok_callback, err_callback) {
				try {
					var fid = this._file_get_new_id();
					this.store.file[fid] = new _via_file(fid, name, type, loc, src);
					this.emit_event("file_add", { fid: fid });
					ok_callback(fid);
				} catch (ex) {
					err_callback(ex);
				}
			}.bind(this)
		);
	};

	file_update(fid, name, value) {
		return new Promise(
			function (ok_callback, err_callback) {
				try {
					if (this.store.file.hasOwnProperty(fid)) {
						if (name === "src") {
							if (this.store.file[fid].loc === _VIA_FILE_LOC.LOCAL) {
								this.file_ref[fid] = value;
								this.store.file[fid]["src"] = "";
							} else {
								this.store.file[fid]["src"] = value;
							}
						} else {
							if (name === "loc_prefix") {
								this.store.config.file.loc_prefix[this.store.file[fid].loc] =
									value;
							} else {
								this.store.file[fid][name] = value;
							}
						}
						this.emit_event("file_update", { fid: fid });
						ok_callback(fid);
					} else {
						err_callback("fid=" + fid + " does not exist!");
					}
				} catch (ex) {
					err_callback(ex);
				}
			}.bind(this)
		);
	};

	file_get_src(fid) {
		if (this.store.file[fid].loc === _VIA_FILE_LOC.LOCAL) {
			if (this.file_object_uri.hasOwnProperty(fid)) {
				return this.file_object_uri[fid];
			}

			if (this.file_ref.hasOwnProperty(fid)) {
				this.file_object_uri[fid] = URL.createObjectURL(this.file_ref[fid]);
				return this.file_object_uri[fid];
			} else {
				return "";
			}
		} else {
			return (
				this.store.config.file.loc_prefix[this.store.file[fid].loc] +
				this.store.file[fid].src
			);
		}
	};

	file_get_uri(fid) {
		if (this.store.file[fid].loc === _VIA_FILE_LOC.LOCAL) {
			return this.store.file[fid].fname;
		} else {
			return (
				this.store.config.file.loc_prefix[this.store.file[fid].loc] +
				this.store.file[fid].src
			);
		}
	};

	file_free_resources(fid) {
		// for files selected using browser's File Selector, we do not
		// need to revoke the ObjectURL because these are simply pointers
		// to the user selected files. If you use revokeObjectURL() to free
		// resources, it will invalidate the pointer to user selected file
		// see https://stackoverflow.com/a/49346614
		//URL.revokeObjectURL( this.file_object_uri[fid] );
		//delete this.file_object_uri[fid];
	};

	//
	// Metadata
	//
	_metadata_get_new_id(vid) {
		return vid + "_" + _via_util_uid6();
	};

	metadata_add(vid, z, xy, av) {
		return new Promise(
			function (ok_callback, err_callback) {
				try {
					if (!this.store["view"].hasOwnProperty(vid)) {
						err_callback({ vid: vid });
						return;
					}
					var mid = this._metadata_get_new_id(vid);
					var z_fp = _via_util_float_arr_to_fixed(z, _VIA_FLOAT_FIXED_POINT);
					var xy_fp = _via_util_float_arr_to_fixed(xy, _VIA_FLOAT_FIXED_POINT);
					this.store.metadata[mid] = new _via_metadata(vid, z_fp, xy_fp, av);
					if (!this.cache.mid_list.hasOwnProperty(vid)) {
						this.cache.mid_list[vid] = [];
					}
					this.cache.mid_list[vid].push(mid);

					this.emit_event("metadata_add", { vid: vid, mid: mid });
					ok_callback({ vid: vid, mid: mid });
				} catch (ex) {
					err_callback(ex);
				}
			}.bind(this)
		);
	};

	metadata_add_bulk(metadata_list, emit) {
		return new Promise(
			function (ok_callback, err_callback) {
				try {
					var added_mid_list = [];
					for (var mindex in metadata_list) {
						var vid = metadata_list[mindex].vid;
						var mid = this._metadata_get_new_id(vid);
						var z_fp = _via_util_float_arr_to_fixed(
							metadata_list[mindex].z,
							_VIA_FLOAT_FIXED_POINT
						);
						var xy_fp = _via_util_float_arr_to_fixed(
							metadata_list[mindex].xy,
							_VIA_FLOAT_FIXED_POINT
						);
						this.store.metadata[mid] = new _via_metadata(
							vid,
							z_fp,
							xy_fp,
							metadata_list[mindex].av
						);
						if (!this.cache.mid_list.hasOwnProperty(vid)) {
							this.cache.mid_list[vid] = [];
						}
						this.cache.mid_list[vid].push(mid);
						added_mid_list.push(mid);
					}
					if (typeof emit !== "undefined" && emit === true) {
						this.emit_event("metadata_add_bulk", { mid_list: added_mid_list });
					}
					ok_callback({ mid_list: added_mid_list });
				} catch (ex) {
					err_callback(ex);
				}
			}.bind(this)
		);
	};

	metadata_update(vid, mid, z, xy, av) {
		return new Promise(
			function (ok_callback, err_callback) {
				try {
					if (!this.store.view.hasOwnProperty(vid)) {
						err_callback({ vid: vid });
						return;
					}

					if (!this.store.metadata.hasOwnProperty(mid)) {
						err_callback({ mid: mid });
						return;
					}

					var z_fp = _via_util_float_arr_to_fixed(z, _VIA_FLOAT_FIXED_POINT);
					var xy_fp = _via_util_float_arr_to_fixed(xy, _VIA_FLOAT_FIXED_POINT);
					this.store.metadata[mid] = new _via_metadata(vid, z_fp, xy_fp, av);
					this.emit_event("metadata_update", { vid: vid, mid: mid });
					ok_callback({ vid: vid, mid: mid });
				} catch (ex) {
					err_callback(ex);
				}
			}.bind(this)
		);
	};

	metadata_update_xy(vid, mid, xy) {
		return new Promise(
			function (ok_callback, err_callback) {
				try {
					if (!this.store.view.hasOwnProperty(vid)) {
						err_callback({ vid: vid });
						return;
					}
					if (!this.store.metadata.hasOwnProperty(mid)) {
						err_callback({ mid: mid });
						return;
					}
					var xy_fp = _via_util_float_arr_to_fixed(xy, _VIA_FLOAT_FIXED_POINT);
					this.store.metadata[mid].xy = xy_fp;
					this.emit_event("metadata_update", { vid: vid, mid: mid });
					ok_callback({ vid: vid, mid: mid });
				} catch (ex) {
					console.log(xy);
					err_callback(ex);
				}
			}.bind(this)
		);
	};

	metadata_update_av(vid, mid, aid, avalue) {
		return new Promise(
			function (ok_callback, err_callback) {
				try {
					if (!this.store.view.hasOwnProperty(vid)) {
						err_callback({ vid: vid });
						return;
					}
					if (!this.store.metadata.hasOwnProperty(mid)) {
						err_callback({ mid: mid });
						return;
					}

					this.store.metadata[mid].av[aid] = avalue;
					this.emit_event("metadata_update", { vid: vid, mid: mid });
					ok_callback({ vid: vid, mid: mid });
				} catch (ex) {
					console.log(ex);
					err_callback(ex);
				}
			}.bind(this)
		);
	};

	metadata_update_av_bulk(vid, av_list) {
		return new Promise(
			function (ok_callback, err_callback) {
				try {
					if (!this.store.view.hasOwnProperty(vid)) {
						err_callback({ vid: vid });
						return;
					}
					var updated_mid_list = [];
					var mid, aid, avalue;
					for (var i in av_list) {
						mid = av_list[i].mid;
						aid = av_list[i].aid;
						avalue = av_list[i].avalue;
						this.store.metadata[mid].av[aid] = avalue;
						updated_mid_list.push(mid);
					}
					var event_payload = { vid: vid, mid_list: updated_mid_list };
					this.emit_event("metadata_update_bulk", event_payload);
					ok_callback(event_payload);
				} catch (ex) {
					console.log(ex);
					err_callback(ex);
				}
			}.bind(this)
		);
	};

	metadata_update_z(vid, mid, z) {
		return new Promise(
			function (ok_callback, err_callback) {
				if (!this.store.view.hasOwnProperty(vid)) {
					err_callback({ vid: vid });
					return;
				}
				if (!this.store.metadata.hasOwnProperty(mid)) {
					err_callback({ mid: mid });
					return;
				}

				var z_fp = _via_util_float_arr_to_fixed(z, _VIA_FLOAT_FIXED_POINT);
				this.store.metadata[mid].z = z_fp;
				this.emit_event("metadata_update", { vid: vid, mid: mid });
				ok_callback({ vid: vid, mid: mid });
			}.bind(this)
		);
	};

	metadata_update_zi(vid, mid, zindex, zvalue) {
		return new Promise(
			function (ok_callback, err_callback) {
				if (!this.store.view.hasOwnProperty(vid)) {
					err_callback({ vid: vid });
					return;
				}
				if (!this.store.metadata.hasOwnProperty(mid)) {
					err_callback({ mid: mid });
					return;
				}

				var zvalue_fp = _via_util_float_to_fixed(zvalue, _VIA_FLOAT_FIXED_POINT);
				this.store.metadata[mid].z[zindex] = zvalue_fp;
				this.emit_event("metadata_update", { vid: vid, mid: mid });
				ok_callback({ vid: vid, mid: mid });
			}.bind(this)
		);
	};

	metadata_delete(vid, mid) {
		return new Promise(
			function (ok_callback, err_callback) {
				try {
					if (!this.store.view.hasOwnProperty(vid)) {
						err_callback({ vid: vid });
						return;
					}
					if (!this.store.metadata.hasOwnProperty(mid)) {
						err_callback({ mid: mid });
						return;
					}

					this._cache_mid_list_del(vid, [mid]);
					delete this.store.metadata[mid];
					this.emit_event("metadata_delete", { vid: vid, mid: mid });
					ok_callback({ vid: vid, mid: mid });
				} catch (ex) {
					err_callback(ex);
				}
			}.bind(this)
		);
	};

	metadata_delete_bulk(vid, mid_list, emit) {
		return new Promise(
			function (ok_callback, err_callback) {
				try {
					if (!this.store.view.hasOwnProperty(vid)) {
						err_callback({ vid: vid });
						return;
					}
					var deleted_mid_list = [];
					var mid, cindex;
					for (var mindex in mid_list) {
						mid = mid_list[mindex];
						delete this.store.metadata[mid];
						deleted_mid_list.push(mid);
					}
					this._cache_mid_list_del(vid, deleted_mid_list);
					if (typeof emit !== "undefined" && emit === true) {
						this.emit_event("metadata_delete_bulk", {
							vid: vid,
							mid_list: deleted_mid_list,
						});
					}
					ok_callback({ vid: vid, mid_list: deleted_mid_list });
				} catch (ex) {
					console.log(ex);
					err_callback(ex);
				}
			}.bind(this)
		);
	};

	metadata_delete_all(vid, emit) {
		return new Promise(
			function (ok_callback, err_callback) {
				try {
					if (!this.store.view.hasOwnProperty(vid)) {
						err_callback({ vid: vid });
						return;
					}
					var deleted_mid_list = [];
					var mid, cindex;
					for (var mid in this.store.metadata) {
						delete this.store.metadata[mid];
						deleted_mid_list.push(mid);
					}
					this._cache_mid_list_del(vid, deleted_mid_list);
					if (typeof emit !== "undefined" && emit === true) {
						this.emit_event("metadata_delete_all", {
							vid: vid,
							mid_list: deleted_mid_list,
						});
					}
					ok_callback({ vid: vid, mid_list: deleted_mid_list });
				} catch (ex) {
					console.log(ex);
					err_callback(ex);
				}
			}.bind(this)
		);
	};

	//
	// View
	//
	_view_get_new_id() {
		var max_vid = -Infinity;
		var vid;
		for (var vid_str in this.store.view) {
			vid = parseInt(vid_str);
			if (vid > max_vid) {
				max_vid = vid;
			}
		}
		if (max_vid === -Infinity) {
			return "1";
		} else {
			return (max_vid + 1).toString();
		}
	};

	view_add(fid_list) {
		return new Promise(
			function (ok_callback, err_callback) {
				try {
					var vid = this._view_get_new_id();
					this.store.view[vid] = new _via_view(fid_list);
					this.store.project.vid_list.push(vid);
					this.emit_event("view_add", { vid: vid });
					ok_callback(vid);
				} catch (ex) {
					console.log(ex);
					err_callback(ex);
				}
			}.bind(this)
		);
	};

	view_get_file_vid(fid) {
		console.log('called view_get_file_vid');
		console.log(fid);
		console.log(this.store.view);
		for (var vid in this.store.view) {
			if (_via_util_array_eq(this.store.view[vid].fid_list, [fid])) {
				console.log('returning ' + vid);
				return vid;
			}
		}
		return -1;
	};

	// add view with single file
	view_bulk_add_from_filelist(filelist) {
		console.log('view_bulk_add_from_filelist');
		return new Promise(
			function (ok_callback, err_callback) {
				try {
					var added_fid_list = [];
					var added_vid_list = [];
					for (var i = 0; i < filelist.length; ++i) {
						var fid = this._file_get_new_id();
						if (filelist[i].loc === _VIA_FILE_LOC.LOCAL) {
							this.file_ref[fid] = filelist[i].src; // local file ref. stored separately
							// filelist[i].src = '';                 // no need to store duplicate of file ref.
						}
						this.store.file[fid] = new _via_file(
							fid,
							filelist[i].fname,
							filelist[i].type,
							filelist[i].loc,
							filelist[i].src
						);

						var vid = this._view_get_new_id();
						this.store.view[vid] = new _via_view([fid]); // view with single file
						this.store.project.vid_list.push(vid);

						added_fid_list.push(fid);
						added_vid_list.push(vid);
					}
					var payload = { vid_list: added_vid_list, fid_list: added_fid_list };
					console.log('typeof onevent' + typeof this.on_event);
					console.log(typeof this.emit_event);
					this.emit_event("view_bulk_add", payload);
					ok_callback(payload);
				} catch (err) {
					err_callback(err);
				}
			}.bind(this)
		);
	};

	view_del(vid) {
		return new Promise(
			function (ok_callback, err_callback) {
				try {
					if (!this.store.view.hasOwnProperty(vid)) {
						err_callback();
						return;
					}
					var vindex = this.store.project.vid_list.indexOf(vid);
					if (vindex === -1) {
						err_callback();
						return;
					}

					// delete all metadata
					var mid;
					for (var mindex in this.cache.mid_list[vid]) {
						mid = this.cache.mid_list[vid][mindex];
						delete this.store.metadata[mid];
					}
					// delete all files
					var fid;
					for (var findex in this.store.view[vid].fid_list) {
						fid = this.store.view[vid].fid_list[findex];
						delete this.store.file[fid];
						delete this.file_object_uri[fid];
					}
					// delete view
					delete this.store.view[vid];
					this.store.project.vid_list.splice(vindex, 1);

					this._cache_update_mid_list();
					this.emit_event("view_del", { vid: vid, vindex: vindex });
					ok_callback({ vid: vid, vindex: vindex });
				} catch (err) {
					err_callback(err);
				}
			}.bind(this)
		);
	};

	//
	// cache
	//
	_cache_update() {
		console.log('_cache_update');
		this._cache_update_mid_list();
		this._cache_update_attribute_group();
	};

	_cache_mid_list_del(vid, del_mid_list) {
		var mid;
		for (var mindex in del_mid_list) {
			mid = del_mid_list[mindex];
			var cindex = this.cache.mid_list[vid].indexOf(mid);
			if (cindex !== -1) {
				this.cache.mid_list[vid].splice(cindex, 1);
			}
		}
	};

	_cache_update_mid_list() {
		var vid;
		this.cache.mid_list = {};
		for (var mid in this.store.metadata) {
			vid = this.store.metadata[mid].vid;
			if (!this.cache.mid_list.hasOwnProperty(vid)) {
				this.cache.mid_list[vid] = [];
			}
			this.cache.mid_list[vid].push(mid);
		}
	};

	_cache_update_attribute_group() {
		this.cache.attribute_group = {};
		var anchor_id;
		for (var aid in this.store.attribute) {
			anchor_id = this.store.attribute[aid].anchor_id;
			if (!this.cache.attribute_group.hasOwnProperty(anchor_id)) {
				this.cache.attribute_group[anchor_id] = [];
			}
			this.cache.attribute_group[anchor_id].push(aid);
		}
	};

	_cache_get_attribute_group(anchor_id_list) {
		var aid_list = [];
		for (var i in anchor_id_list) {
			var anchor_id = anchor_id_list[i];
			if (this.cache.attribute_group.hasOwnProperty(anchor_id)) {
				aid_list = aid_list.concat(this.cache.attribute_group[anchor_id]);
			}
		}
		return aid_list;
	};

	_updateLid(data, typeOfDoc, files) {
		// console.log('calle update lid');
		const filenames = this.store.project.vid_list;
		for (let i = 0; i < filenames.length; i++) {
			// console.log('in for');
			const file = this.store.file[filenames[i]];
			data.forEach(function (ids) {
				// console.log('in for each');
				if (ids.id === file.fid) {
					// console.log('in if');
					file.lId = ids.lId;
				}
			});
		}
		this._addButtonsToDisplayData(typeOfDoc, files);
		// console.log(this.store.file);
	};

	project_save() {
		// console.log('called project save');
		//  return new Promise( function(ok_callback, err_callback) {
		try {
			// console.log(this.store.attribute[1].aname);
			try {
				const calci = document.getElementById("calculator")
				reactDOM.unmountComponentAtNode(calci);
			} catch (reason) {
				console.error('calculator not mounted');
			}

			let final = [];
			const that = this;
			let filename = this.store.project.vid_list;
			for (let i = 0; i < filename.length; i++) {
				// var files
				let x = this.store.file[filename[i]];
				let metadata = this.store.metadata;
				const temp = {};
				temp.id = x.fid;
				temp.name = x.fname;
				temp.attribute =
					this.store.attribute &&
						this.store.attribute[1] &&
						this.store.attribute[1].aname
						? this.store.attribute[1].aname
						: "";
				temp.file = x;
				temp.annotations = [];
				if (x.lId) {
					temp.lId = x.lId;
				}
				const keys = Object.keys(metadata);
				const annots = [];
				keys.forEach(function (element) {
					if (element.indexOf(temp.id + "_") === 0) {
						annots.push(metadata[element]);
					}
				});
				temp.annotations = annots;
				final.push(temp);
			}
			let val = document.getElementById("view_selector");
			if (val) {
				val = val.value
			}
			// TODO comment for testing
			saveNewFiles(final, that, val);
			this._addButtonsToDisplayData("IRS", final);
			// TODO uncomment for testing
			// saveNewFiles(final, that);

			// if ( this.store.project.pid === _VIA_PROJECT_ID_MARKER ) {
			//   filename.push('via_project_');
			// } else {
			//   filename.push(this.store.project.pid.substr(0,8) + '_');
			// }
			// filename.push(_via_util_date_to_filename_str(Date.now()));
			// filename.push('.json');

			//   const newFiles = []
			//   const re = /(?:\.([^.]+))?$/
			//   files.forEach(element => {
			//     const ext = re.exec(element)[1]
			//     const name = getFileName(ext)
			//     newFiles.push({
			//       name: name,
			//       file: this.store,
			//       contentType: mimeTypes[ext]
			//     })
			//     saveAnnotations(element.annotations, name)
			//   });
			//   await uploadFile(newFiles)
			//   return {message: 'Success'}
			// }catch(reason) {
			//   console.error(reason);
			//   throw reason
			// }
			// @todo: decide on whether we want to include the base64 data
			// of inline files (i.e. this.store.file[fid].loc === _VIA_FILE_LOC.INLINE)
			// var data_blob = new Blob([JSON.stringify(this.store)],
			//   { type: 'text/json;charset=utf-8' });

			filename = [];
			if (this.store.project.pid === _VIA_PROJECT_ID_MARKER) {
				filename.push("via_project_");
			} else {
				filename.push(this.store.project.pid.substr(0, 8) + "_");
			}
			filename.push(_via_util_date_to_filename_str(Date.now()));
			filename.push(".json");
			//  _via_util_download_as_file(data_blob, filename.join(''));
			// console.log(this.store)
			// ok_callback();
		} catch (err) {
			console.log(err);
			//  _via_util_msg_show('Failed to save project! [' + err + ']');
			//  err_callback();
		}
		//  }.bind(this));
	};

	project_load(project_data_str) {
		return new Promise(
			function (ok_callback, err_callback) {
				try {
					var project_json_data = JSON.parse(project_data_str);
					this.project_load_json(project_json_data).then(
						function (ok) {
							ok_callback();
						}.bind(this),
						function (err) {
							err_callback();
						}.bind(this)
					);
				} catch (err) {
					_via_util_msg_show("Failed to load project! [" + err + "]");
					this._init_default_project();
					console.log(err);
					err_callback();
				}
			}.bind(this)
		);
	};

	project_load_json(project_json_data) {
		return new Promise(
			function (ok_callback, err_callback) {
				try {
					var project_data = Object.assign({}, project_json_data);
					this.store = this.project_store_apply_version_fix(project_data);
					this.store0 = JSON.parse(JSON.stringify(this.store)); // helps keep track of local changes
					this._cache_update();
					this.emit_event("project_loaded", { pid: this.store.project.pid });
					ok_callback();
				} catch (err) {
					_via_util_msg_show("Failed to load project! [" + err + "]");
					this._init_default_project();
					console.warn("failed to load project");
					console.log(err);
					err_callback();
				}
			}.bind(this)
		);
	};

	project_store_apply_version_fix(d) {
		switch (d["project"]["data_format_version"]) {
			case "3.1.0":
				var local_prefix = d["config"]["file"]["path"];
				delete d["config"]["file"]["path"];
				d["config"]["file"]["loc_prefix"] = { 1: "", 2: "", 3: "", 4: "" };
				d["config"]["file"]["loc_prefix"][_VIA_FILE_LOC.LOCAL] = local_prefix;
				d["project"]["data_format_version"] = this.DATA_FORMAT_VERSION;
				d["project"]["vid_list"] = d["vid_list"];
				delete d["vid_list"];
				return d;
				break;
			default:
				return d;
				break;
		}
	};

	project_is_remote() {
		if (
			this.store.project.pid === _VIA_PROJECT_ID_MARKER &&
			this.store.project.rev === _VIA_PROJECT_REV_ID_MARKER &&
			this.store.project.rev_timestamp === _VIA_PROJECT_REV_TIMESTAMP_MARKER
		) {
			return false;
		} else {
			return true;
		}
	};

	//
	// merge
	//
	project_merge_rev(remote, merge_strategy) {
		if (typeof merge_strategy === "undefined") {
			merge_strategy = _VIA_MERGE_STRATEGY.THREE_WAY;
		}

		switch (merge_strategy) {
			case _VIA_MERGE_STRATEGY.THREE_WAY:
				this.project_merge_three_way(remote).then(
					function (ok) {
						console.log("Merge success");
					}.bind(this),
					function (err) {
						console.log("Merge failed");
					}.bind(this)
				);
				break;
			default:
				console.log("Unknown merge strategy: " + merge_strategy);
		}
	};

	project_merge_three_way(remote) {
		return new Promise(
			function (ok_callback, err_callback) {
				// see https://en.wikipedia.org/wiki/Merge_(version_control)
				// merge using the three way merge algorithm, where
				// common ancestor = this.store0 (i.e. the last revision)
				// remote          = the latest revision pulled from server
				// local           = this.store (i.e. local version of project with some revisions)

				if (this.store.project.pid !== remote.project.pid) {
					err_callback("pid mismatch");
					return;
				}

				try {
					// merge project
					this.store.project["pname"] = this.merge_3way(
						this.store0.project["pname"],
						remote.project["pname"],
						this.store.project["pname"]
					);
					this.store.project["vid_list"] = this.merge_3way(
						this.store0.project["vid_list"],
						remote.project["vid_list"],
						this.store.project["vid_list"]
					);

					// merge remote attribute, file, view and metadata
					var property_list = ["config", "attribute", "file", "view", "metadata"];
					for (var pindex in property_list) {
						var property = property_list[pindex];
						for (var id in remote[property]) {
							if (this.store[property].hasOwnProperty(id)) {
								for (var afield in this.store[property][id]) {
									this.store[property][id][afield] = this.merge_3way(
										this.store0[property][id][afield],
										remote[property][id][afield],
										this.store[property][id][afield]
									);
								}
							} else {
								// add new attribute
								this.store[property][id] = remote[property][id];
							}
						}
					}

					// check for data that was deleted in remote
					for (var pindex in property_list) {
						var property = property_list[pindex];
						for (var id in this.store0[property]) {
							if (!remote[property].hasOwnProperty(id)) {
								// something was deleted in remote, therefore we remove it from local version
								delete this.store[property][id];
							}
						}
					}

					this.store.project.rev = remote.project.rev;
					this.store.project.rev_timestamp = remote.project.rev_timestamp;
					this.store0 = JSON.parse(JSON.stringify(remote)); // update our copy of latest remote revision
					this.project_merge_on_success();
				} catch (e) {
					_via_util_msg_show("Merge failed: " + e, true);
					console.warn(e);
					err_callback(e);
				}
			}.bind(this)
		);
	};

	merge_3way(common_ancestor, remote, local) {
		if (typeof common_ancestor === "object") {
			if (Array.isArray(common_ancestor)) {
				// use array comparison
				if (_via_util_array_eq(remote, local)) {
					return local;
				} else {
					if (_via_util_array_eq(common_ancestor, local)) {
						return remote;
					} else {
						return local; // for conflicts, preserve my local updates
					}
				}
			} else {
				// use object comparison
				if (JSON.stringify(remote) === JSON.stringify(local)) {
					return local;
				} else {
					if (JSON.stringify(common_ancestor) === JSON.stringify(local)) {
						return remote;
					} else {
						return local; // for conflicts, preserve my local updates
					}
				}
			}
		} else {
			if (remote === local) {
				return local;
			} else {
				if (common_ancestor === local) {
					return remote;
				} else {
					return local; // for conflicts, preserve my local updates
				}
			}
		}
	};

	project_merge_on_success() {
		this._cache_update();
		this.emit_event("project_updated", { pid: this.store.project.pid });

		_via_util_msg_show(
			"Successfully merged with revision " + this.store.project.rev,
			true
		);
	};

	// is there any difference between local project and remote project?
	project_is_different(others_str) {
		return new Promise(
			function (ok_callback, err_callback) {
				var ours_str = JSON.stringify(this.store);
				if (ours_str === others_str) {
					err_callback();
				} else {
					ok_callback(others_str);
				}
			}.bind(this)
		);
	};

	//
	// Import
	//
	project_import_via2_json(via2_project_json) {
		return new Promise(
			function (ok_callback, err_callback) {
				try {
					var via2 = JSON.parse(via2_project_json);
					this.store = this._init_default_project();

					// add project data
					if (via2.hasOwnProperty("_via_settings")) {
						this.store.project.pname = via2["_via_settings"]["project"]["name"];
						this.store.config.file.loc_prefix[_VIA_FILE_LOC.LOCAL] =
							via2["_via_settings"]["core"]["default_filepath"];
					}

					// add attributes
					var metadata_type_anchor_id_map = {
						region: "FILE1_Z0_XY1",
						file: "FILE1_Z0_XY0",
					};
					var via2_aid_to_via3_aid_map = {};
					for (var metadata_type in metadata_type_anchor_id_map) {
						var anchor_id = metadata_type_anchor_id_map[metadata_type];
						for (var aid in via2["_via_attributes"][metadata_type]) {
							var options =
								via2["_via_attributes"][metadata_type][aid]["options"];
							var default_option_id = [];
							for (var default_oid in via2["_via_attributes"][metadata_type][aid][
								"default_options"
							]) {
								default_option_id.push(default_oid);
							}
							var via3_aid = this._attribute_get_new_id();
							var atype_text =
								via2["_via_attributes"][metadata_type][aid]["type"].toUpperCase();
							if (atype_text === "DROPDOWN") {
								atype_text = "SELECT";
							}
							var atype = _VIA_ATTRIBUTE_TYPE[atype_text];
							this.store["attribute"][via3_aid] = new _via_attribute(
								aid,
								anchor_id,
								atype,
								via2["_via_attributes"][metadata_type][aid]["description"],
								options,
								default_option_id.join(",")
							);
							via2_aid_to_via3_aid_map[aid] = via3_aid;
						}
					}

					// add file, view and metadata
					for (var fid in via2["_via_img_metadata"]) {
						var fname = via2["_via_img_metadata"][fid]["filename"];
						var floc = _via_util_infer_file_loc_from_filename(fname);
						var ftype = _via_util_infer_file_type_from_filename(fname);
						var via3_fid = this._file_get_new_id();
						this.store.file[via3_fid] = new _via_file(
							via3_fid,
							fname,
							ftype,
							floc,
							fname
						);

						var vid = this._view_get_new_id();
						this.store.view[vid] = new _via_view([via3_fid]);
						this.store.project.vid_list.push(vid);

						// import region metadata
						for (var rid in via2["_via_img_metadata"][fid]["regions"]) {
							var shape =
								via2["_via_img_metadata"][fid]["regions"][rid][
								"shape_attributes"
								];
							var z = [];
							var xy = [];
							var av = {};
							switch (shape["name"]) {
								case "rect":
									xy = [
										_VIA_RSHAPE.RECTANGLE,
										shape["x"],
										shape["y"],
										shape["width"],
										shape["height"],
									];
									break;
								case "circle":
									xy = [_VIA_RSHAPE.CIRCLE, shape["cx"], shape["cy"], shape["r"]];
									break;
								case "ellipse":
									xy = [
										_VIA_RSHAPE.ELLIPSE,
										shape["cx"],
										shape["cy"],
										shape["rx"],
										shape["ry"],
									];
									break;
								case "point":
									xy = [_VIA_RSHAPE.POINT, shape["cx"], shape["cy"]];
									break;
								case "polygon":
								case "polyline":
									if (shape["name"] === "polygon") {
										xy[0] = _VIA_RSHAPE.POLYGON;
									} else {
										xy[0] = _VIA_RSHAPE.POLYLINE;
									}
									var n = shape["all_points_x"].length;
									for (var i = 0; i < n; ++i) {
										xy.push(shape["all_points_x"][i], shape["all_points_y"][i]);
									}
									break;
								default:
									console.log(
										"Unknown shape " + shape["name"] + " in input file!"
									);
							}

							var region =
								via2["_via_img_metadata"][fid]["regions"][rid][
								"region_attributes"
								];
							for (var via2_aid in via2["_via_img_metadata"][fid]["regions"][rid][
								"region_attributes"
							]) {
								var via3_aid = via2_aid_to_via3_aid_map[via2_aid];
								var avalue =
									via2["_via_img_metadata"][fid]["regions"][rid][
									"region_attributes"
									][via2_aid];

								if (typeof avalue === "object") {
									avalue = Object.keys(avalue).join(",");
								}

								av[via3_aid] = avalue;
							}

							var mid = this._metadata_get_new_id(vid);
							this.store.metadata[mid] = new _via_metadata(vid, z, xy, av);
						}

						// import file metadata
						var file_av = {};
						for (var via2_aid in via2["_via_img_metadata"][fid][
							"file_attributes"
						]) {
							var via3_aid = via2_aid_to_via3_aid_map[via2_aid];
							var avalue =
								via2["_via_img_metadata"][fid]["file_attributes"][via2_aid];

							if (typeof avalue === "object") {
								avalue = Object.keys(avalue).join(",");
							}

							file_av[via3_aid] = avalue;
						}
						var mid = this._metadata_get_new_id(vid);
						this.store.metadata[mid] = new _via_metadata(vid, [], [], file_av);
					}
					_via_util_msg_show("Project import successful");
					this._cache_update();
					this.emit_event("project_loaded", {});
					ok_callback();
				} catch (ex) {
					console.log(ex);
					_via_util_msg_show("Failed to import project");
					err_callback(ex);
				}
			}.bind(this)
		);
	};

	async _getResults(changeStatus, scope = "", files = []) {
		if (!scope) {
			scope = this;
		}
		let res = {};
		let file = {};
		if (scope.store && scope.store.file) {
			console.log(scope.store.file);
			file = scope.store.file;
		}
		const keys = Object.keys(file);
		const file_ids = [];

		for (let i = 0; i < keys.length; i++) {
			if (file[keys[i]].lId) {
				file_ids.push(file[keys[i]].lId);
			}
		}
		// TODO testing uncomment
		// file_ids.push(464)
		if (file_ids.length) {
			const res1 = await getAnnotations(file_ids);
			if (res1 && res1.annotations) {
				res = res1.annotations;
			}
		}
		const statusElm = document.getElementById("annotatorStatus");
		if (!scope._getResultsRetry) {
			scope._getResultsRetry = 1;
		}
		console.log(scope);
		if (
			res &&
			(res.csv ||
				(res.json && res.json.length) ||
				(res.calculator && res.calculator.length) ||
				res.txt)
		) {
			statusElm.innerHTML = "";
			let execute = false;
			if (res.csv && res.csv.length) {
				await openCity("csv", res["csv"], !execute);
				execute = true;
			}
			if (res.json && res.json.length) {
				await openCity("json", res["json"], !execute);
				execute = true;
			}
			if (res.txt && res.txt.length) {
				await openCity("txt", res["txt"], !execute);
				execute = true;
			}
			if (res.calculator && res.calculator.length && res.calculator[0]) {
				console.log('in calci');
				console.log(files);
				await openCity("calculator", res["calculator"], !execute, files);
				execute = true;
			}

			scope._getResultsRetry = 0;
		} else if (
			(!scope._getResultsRetry || scope._getResultsRetry < 5) &&
			changeStatus
		) {
			setTimeout(() => {
				scope._getResultsRetry++;
				if (scope._getResultsRetry > 3) {
					statusElm.innerHTML = "Fetching Results";
				}
				scope._getResults(changeStatus, scope, files);
			}, 5000);
		} else if (scope._getResultsRetry >= 5 && changeStatus) {
			statusElm.innerHTML =
				"Failed to get results. Please click on convert to retry";
		}
		return;
	};

	async _clearResults() {
		const elms = ["csv", "txt", "json", "calculator"];
		for (let i = 0; i < elms.length; i++) {
			try {
				const elm = document.getElementById(elms[i]);
				elm.innerHTML = "Waiting for results";
			} catch (err) {
				// nothing to do
			}
		}
		return true;
	};

	// temp_add() {
	//   const parent = document.getElementById('tab')
	//   this._calciButton = getButton('calculatorButton', 'Calculator')
	//   getCalculator()
	//   this._calciButton.addEventListener('click', function () {
	//     openCity('calculator', '', true)
	//   })
	//   parent.appendChild(this._calciButton)
	// }

	_addButtonsToDisplayData(typeOfDoc, files) {
		console.log('_addButtonsToDisplayData');
		console.log(files);
		const that = this;
		// TODO comment for prod
		// that._getResults("", that, files);
		const parent = document.getElementById("tab");
		if (this._resultButtonsAdded && this._resultButtonsAdded === typeOfDoc) {
			return;
		} else if (this._resultButtonsAdded) {
			while (parent.firstChild) {
				parent.removeChild(parent.firstChild);
			}
		}
		this._convertButton = getButton("convertButton", "Convert");
		this._convertButton.addEventListener("click", function () {
			try {
				const calci = document.getElementById("calculator")
				reactDOM.unmountComponentAtNode(calci);
			} catch (reason) {
				console.error('calculator not mounted');
			}
			that._getResults("", that, files);
		});
		this._textButton = getButton("txtButton", "Text");
		this._textButton.addEventListener("click", function () {
			openCity("txt", "", true);
		});
		this._csvButton = getButton("csvButton", "CSV");
		this._csvButton.addEventListener("click", function () {
			openCity("csv", "", true);
		});
		parent.appendChild(this._convertButton);
		parent.appendChild(this._textButton);
		parent.appendChild(this._csvButton);
		// if (typeOfDoc === "IRS") {
		this._jsonButton = getButton("jsonButton", "JSON");
		this._jsonButton.addEventListener("click", function () {
			openCity("json", "", true);
		});
		this._calciButton = getButton("calculatorButton", "Calculator");
		this._calciButton.addEventListener("click", function () {
			openCity("calculator", "", true);
		});

		parent.appendChild(this._jsonButton);
		parent.appendChild(this._calciButton);
		// }
		this._resultButtonsAdded = typeOfDoc;
	};
}
function _via_util_array_eq(a, b) {
	if (a == null || b == null) {
		return false;
	}
	if (a.length != b.length) {
		return false;
	}
	var n = a.length;
	for (var i = 0; i < n; ++i) {
		if (a[i] !== b[i]) {
			return false;
		}
	}
	return true;
}
function getButton(id, text, onClickFn) {
	const elm = document.createElement('button')
	elm.classList.add('tablinks')
	elm.setAttribute('id', id)
	elm.innerHTML = text
	return elm
}
async function openCity(cityName, data = "", execute = false, files = []) {
	const tabcontent = document.getElementsByClassName("tabcontent");
	for (let i = 0; i < tabcontent.length; i++) {
		tabcontent[i].style.display = "none";
	}
	const tablinks = document.getElementsByClassName("tablinks");
	for (let i = 0; i < tablinks.length; i++) {
		tablinks[i].className = tablinks[i].className.replace(" active", "");
		tablinks[i].className = tablinks[i].className.replace(
			" buttonSelected",
			""
		);
	}
	if (execute) {
		$("#via_container").removeClass("col-md-9");
		$("#via_container").addClass("col-md-6");
		$(".card").css("width", "45%");
		const id = "#" + cityName + "Button";
		// console.log('adding active to id' + id);
		$(id).addClass("buttonSelected");
	}

	const elm = document.getElementById(cityName);
	elm.style.display = "block";
	if (data) {
		let tag = "p";
		if (typeof data === "object") {
			data = JSON.stringify(data, undefined, 2);
			tag = "pre";
		}

		const a = document.createElement("a");
		a.classList.add("btn");
		a.classList.add("btn-primary");
		a.classList.add("download-btn");
		const txtContent =
			"Download " +
			(cityName === "calculator" ? "" : "as ") +
			(cityName === "txt" ? "Text" : cityName.toUpperCase());
		a.textContent = txtContent;
		a.download = "lendexConvert." + cityName;
		const types = {
			txt: "text/plain",
			csv: "text/csv",
			json: "application/json",
			calculator: "application/json",
		};
		if (cityName !== "calculator") {
			a.href = "data:" + types[cityName] + ";charset=utf-8," + escape(data);
		} else {
			a.href = "javascript:download_calculator();";
			a.txtContent = "Download Excel";
		}
		elm.innerHTML = "";
		while (elm.firstChild) {
			elm.removeChild(elm.firstChild);
		}
		if (cityName !== "calculator") {
			elm.appendChild(a);
			elm.appendChild(createCfDiv());
			elm.appendChild(document.createElement("br"));
			const p = document.createElement(tag);
			p.innerHTML = data;
			elm.appendChild(p);
		} else {
			reactDOM.render(
				React.createElement(Calculator, { data: JSON.parse(data) }),
				document.getElementById("calculator")
			);
			console.log('got files');
			console.log(files);
			// TODO add back files if for prod
			if(files && files.length) {
			showCalculatorModal(data, files)
			}
			// await getCalculatorUI(data)
			// if (calculatorPages && calculatorPages.length) {
			//   calculatorPages.forEach(function (element) {
			//     elm.appendChild(element)
			//   })
			//   elm.appendChild(document.createElement("br"));
			//   // TODO diabled download button tll functionality is built
			//   // elm.appendChild(a);
			//   calculatorLoaded(data)
			//   // elm.appendChild(calci)
			// }
		}
	}
}

function createCfDiv() {
	const div = document.createElement("div");
	div.classList.add("clearfix");
	return div;
}
function _via_util_uid6() {
	var temp_url = URL.createObjectURL(new Blob());
	var uuid = temp_url.toString();
	URL.revokeObjectURL(temp_url);
	var n = uuid.length;
	// remove any prefix (e.g. blob:null/, blob:www.test.com/, ...)
	var uuid_suffix_str = "";
	for (var i = n - 12; i < n; i = i + 2) {
		uuid_suffix_str += String.fromCharCode(parseInt(uuid.substr(i, 2), 16));
	}
	return btoa(uuid_suffix_str).replace(/[-+/_]/gi, "X");
}