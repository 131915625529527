import React, { useState } from "react";
import Lottie from "react-lottie-player";
import avatar from "../../assets/lottie/avatar.json";
import { useDispatch } from "react-redux";
import modalSlice from "../../redux/slices/modal-slice";
import loggedInSlice from "../../redux/slices/loggedIn-slice";
import { useNavigate } from "react-router-dom";
import { setCookie } from "../../Components/login";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [showForgot, setShowForgot] = useState(false);
  const [showContact, setShowContact] = useState(false);
  // const loggedIn = useSelector((state)=>state.loggedIn.value)
  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(BASE + "login", {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (response && response.ok) {
      const data = await response.json();
      console.log(data);
      setCookie("user", data.user.id, 10);
      setCookie("accesstoken", data.user.accesstoken, 10);
      localStorage.id = data.user.id;
      localStorage.token = data.user.accesstoken;
      // setIsLoggedIn(true);
      dispatch(loggedInSlice.actions.changeLogin(true))
      dispatch(modalSlice.actions.hideModal());
      if(data.user.user_role) {
        setCookie("user_role", data.user.user_role, 10);
        navigate('/user_login')
      }else {
        navigate('/annotator')
      }
    } else {
      setError(true);
      dispatch(loggedInSlice.actions.changeLogin(false))
      setTimeout(() => setError(false), 2000);
    }
  };

  function openContact() {
    setShowContact(false);
    navigate('/contact')
  }

  return (
    <div className="container px-4 pb-5">
      <div className="text-center">
        <Lottie
          speed={0.5}
          style={{
            width: 150,
            margin: "auto",
            height: 150,
            marginTop: "-2rem",
          }}
          className="mb-3"
          loop
          animationData={avatar}
          play
        />
      </div>
      {error && (
        <div className="alert alert-danger fade show mb-5" role="alert">
          Invalid Username/Password
        </div>
      )}
      <form onSubmit={(e) => handleSubmit(e)} style={{ marginTop: "-2rem" }}>
        <h4
          className="font-weight-bold text-center"
          style={{ fontWeight: "bold" }}
        >
          Login
        </h4>
        <div className="my-3">
          <label
            htmlFor="email"
            className="form-label"
            style={{ fontWeight: "bold" }}
          >
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            placeholder="Email"
            id="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            aria-describedby="emailHelp"
          />
        </div>
        <div className="mb-3">
          <label
            htmlFor="password"
            className="form-label"
            style={{ fontWeight: "bold" }}
          >
            Password
          </label>
          <input
            type="password"
            className="form-control"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            placeholder="Password"
          />
        </div>

        <div
          id="loginErrorMessage"
          className="mb-2"
          style={{ color: "red" }}
        ></div>

        <button type="submit" className="btn bg-primary text-white py-2 w-100">
          Login
        </button>
        <button
          type="button"
          onClick={() => {
            setShowForgot(true);
            setTimeout(() => {
              setShowForgot(false);
            }, 5000);
          }}
          className="btn-link text-primary text-decoration-none w-100 text-center mt-2"
        >
          Forgot Password
        </button>
        {showForgot && (
          <div className="alert alert-info fade show mt-3 text-center" role="alert">
            Please contact{" "}
            <a href="mailto:business@metashape.ai">business@metashape.ai</a> to reset
            your password
          </div>
        )}

        <button
          type="button"
          onClick={() => {
            setShowContact(true);
            setTimeout(() => {
              openContact()
            }, 4000);
          }}
          className="btn-link text-primary text-decoration-none w-100 text-center mt-2"
        >
          Register a new account
        </button>
        {showContact && (
          <div className="alert alert-info fade show mt-3 text-center" role="alert">
            Redirecting... Please let us know your requirements
          </div>
        )}
      </form>
    </div>
  );
};

export default Login;
