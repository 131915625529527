import React, { useState, useEffect } from "react";
// import { useSearchParams } from 'react-router-dom'
import './receiptAnnotation.css'
function uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}
function getNewMenuItem() {
    const menuItem = {
        "id": uuidv4(),
        "item_name": "",
        "item_description": "",
        "item_price": "",
        "item_code": "",
        "item_cnt": "",
        "vat_type": "",
        "discount_description": "",
        "discount_amount": ""
    }
    return menuItem
}

const emptyForm = {
    "receipt_details": {
        "store_name": "Lidl",
        "receipt_id": "",
        "store_contact": "",
        "vat_id": "",
        "receipt_date": "",
        "receipt_time": "",
        "store_id": ""
    },
    "menu": [
        getNewMenuItem(),
        getNewMenuItem()
    ],
    "price_details" :{
        "Vat1%": "",
        "Vat2%": "",
        "Vat1": "",
        "Vat2": "",
        "Vat1amt": "",
        "vat2amt": "",
        "Netamt1": "",
        "Netamt2": "",
        "Total": "",
    }
}
export default function ReceiptAnnotation() {
    const [urls, setUrls] = useState([])
    const [urlsAndData, setUrlsAndData] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0)
    const [currentUrl, setCurrentUrl] = useState("")
    const [formDetails, setFormDetails] = useState(JSON.parse(JSON.stringify(emptyForm)))
    const [userTotal, setUserTotal] = useState(0)
    // const [searchParams, setSearchParams] = useSearchParams()

    useEffect(()=>{
        getUrls().then(resp=>{
            // console.log('*********');
            // console.log(searchParams);
            setData(resp)
        }).catch(reason=> {
            console.error(reason);
        })
    }, [])

    function getItemIndex(array, id) {
        let index = -1
        array.forEach((item, ind)=> {
            if(item.id == id) {
                index = ind
            }
        })
        return index
    }

    function updateMenu(fieldName, fieldValue, itemId) {
        const formDetailsCopy = JSON.parse(JSON.stringify(formDetails))
        const fieldIndex = getItemIndex(formDetailsCopy.menu, itemId)
        if(fieldIndex ==-1) {
            return
        }
        formDetailsCopy["menu"][fieldIndex][fieldName] = fieldValue
        setFormDetails(formDetailsCopy)
    }

    function updateStoreDetails(fieldName, fieldValue) {
        const formDetailsCopy = JSON.parse(JSON.stringify(formDetails))
        formDetailsCopy["receipt_details"][fieldName] = fieldValue
        setFormDetails(formDetailsCopy)
    }

    function updatePriceDetails(fieldName, fieldValue) {
        const formDetailsCopy = JSON.parse(JSON.stringify(formDetails))
        formDetailsCopy["price_details"][fieldName] = fieldValue
        setFormDetails(formDetailsCopy)
    }

    async function getUrls() {
        console.log('called get url');
        const response = await fetch(BASE + "annotation/receipt", {
			method: "GET",
            headers: getHeaders("application/json"),
		});
        const resp = await (response.json())
        return resp
    }


    function setData(resp) {
        setFormDetails(JSON.parse(JSON.stringify(emptyForm)))
        if(resp && resp.data && resp.data) {
            setUrlsAndData(resp.data)
            if(resp.data[0] && resp.data[0].formData) {
                console.log(resp.data[0].formData);
                setFormDetails(JSON.parse(resp.data[0].formData))
            }
        }
        setUrls(resp.urls)
        setCurrentUrl(resp.urls[0])
        setUserTotal(resp.userTotal)
    }

    function updateImageUrls(type = "") {
        let nextIndex = 0
        submitData(type).then(()=>{
            if(currentIndex+1 >= urls.length) {
                getUrls().then(resp=>{
                    setData(resp)
                }).catch(reason=> {
                    console.error(reason);
                })
            }else {
                nextIndex = currentIndex +1
                setCurrentIndex(currentIndex+1)
            }
            setCurrentUrl(urls[nextIndex])
        }).catch(reason=>{
            console.error(reason);
        })
    }

    async function submitData(type = "") {
        console.log(formDetails);
        try {
            if(type=='SKIPPED') {
                await fetch(BASE + "annotation/receipt", {
                    method: "POST",
                    headers: getHeaders("application/json"),
                    body: JSON.stringify({
                        url: urls[currentIndex],
                        type: 'SKIPPED'
                    })
                });
                return
            }
            if(!formDetails.receipt_details.receipt_id) {
                alert('Receipt id missing')
                throw 'Failed'
            }
            if(!formDetails.receipt_details.receipt_date) {
                alert('Receipt date missing')
                throw 'Failed'
            }
            if(!formDetails.receipt_details.store_id) {
                alert('store id missing')
                throw 'Failed'
            }
            if(!formDetails.price_details.Total) {
                alert('Total is missing')
                throw 'Failed'
            }
            let total = 0
            formDetails.menu.forEach(function(item) {
                total = total + Number(item.item_price.replace(",", '.')) + (item.discount_amount?Number(item.discount_amount.replace(",", '.')):0)
            })
            const totalDiff = total - Number(formDetails.price_details.Total.replace(",", '.'))
            if(totalDiff>0.01 || totalDiff<-0.01) {
                alert('Total and item price mismatched. please check enteries')
                throw 'Failed'
            }
            await fetch(BASE + "annotation/receipt", {
                method: "POST",
                headers: getHeaders("application/json"),
                body: JSON.stringify({
                    url: urls[currentIndex],
                    data: formDetails
                })
            });
        }catch(reason) {
            console.error(reason);
            alert("Submit failed. Please check with admin before procedding")
            throw reason
        }
    }

    function renderStoreForm() {
        const fields = [
            {
                name: "store_name",
                placeholder: "Store Name",
                value: "store_name"
            },
            {
                name: "receipt_id",
                placeholder: "Receipt Id",
                value: "receipt_id"
            },{
                name: "store_contact",
                placeholder: "Store Contact",
                value: "store_contact"
            },{
                name: "vat_id",
                placeholder: "Vat Id",
                value: "vat_id"
            },{
                name: "receipt_date",
                placeholder: "Receipt Date",
                value: "receipt_date"
            },{
                name: "receipt_time",
                placeholder: "Receipt Time",
                value: "receipt_time"
            },{
                name: "store_id",
                placeholder: "Store Id",
                value: "store_id"
            },
        ]
        return (
            <>
                <h5 className="mr-2">Store Details</h5>
                {fields.flatMap((option, index) => (
                    <div className="col-sm-4 d-inline-block" key = {"receipt-storeDetails-div-"+index}>
                       <input type="text" className="form-control mt-1" name = {option.name} 
                       key = {"receipt-storeDetails-"+index}
                       placeholder={option.placeholder} value={formDetails.receipt_details[option.value]} onChange={e=>updateStoreDetails(option.name, e.target.value)}></input>
                    </div>
                ))}
            </>
        )
    }

    function renderMenuItem(item, index) {
        const fields = [
            {
                name: "item_name",
                placeholder: "Item Name",
                value: "item_name"
            },
            {
                name: "item_description",
                placeholder: "Item Description",
                value: "item_description"
            },{
                name: "item_price",
                placeholder: "Item Price",
                value: "item_price"
            },{
                name: "item_code",
                placeholder: "Item Code",
                value: "item_code"
            },{
                name: "item_cnt",
                placeholder: "Item Quantity",
                value: "item_cnt"
            },{
                name: "vat_type",
                placeholder: "Vat Type",
                value: "vat_type"
            },{
                name: "discount_description",
                placeholder: "Discount Description",
                value: "discount_description"
            },{
                name: "discount_amount",
                placeholder: "Discount Amount",
                value: "discount_amount"
            },
        ]
        const elms = []
        const val =fields.flatMap((option, optionIndex) => {
            return (<div className='col-sm-4 d-inline-block' key = {"receipt-menu-div-"+optionIndex+"-"+index}>
                <input type="text" className="form-control mt-1" name = {option.name} 
                key = {"receipt-menu-"+optionIndex+"-"+index}
                placeholder={option.placeholder} 
                value={item[option.value]} 
                onChange={e=>updateMenu(option.name, e.target.value, item.id)}></input>
            </div>)
        })
        return val
    }

    function addMenuItemAfter(itemId) {
        const formDetailsCopy = JSON.parse(JSON.stringify(formDetails))
        const index = getItemIndex(formDetailsCopy.menu, itemId)
        if(index ==-1) {
            return
        }
        console.log('add menu idex is'+ index);
        formDetailsCopy.menu.splice(index+1,0, getNewMenuItem())
        console.log(formDetailsCopy.menu);
        setFormDetails(formDetailsCopy)
    }

    function removeMenuItem(itemId) {
        const formDetailsCopy = JSON.parse(JSON.stringify(formDetails))
        const index = getItemIndex(formDetailsCopy.menu, itemId)
        if(index ==-1) {
            return
        }
        formDetailsCopy.menu.splice(index, 1)
        if(formDetailsCopy.menu.length==0) {
            console.log('Adde empty menu');
            formDetailsCopy.menu.push(getNewMenuItem())
        }
        setFormDetails(formDetailsCopy)
    }

    function renderTotal() {
        return (
            <div className='userTotal mt-3'>
             Total : {userTotal}
            </div>
        )
    }

    function renderMenu() {
        return (
            <>
                <h5 className="my-2">Menu</h5> 
                {
                    formDetails.menu.flatMap((menuItem, index)=> {
                        return (
                            <>
                                {renderMenuItem(menuItem, index)}
                                <div className="clearfix"></div>
                                <button className="btn-sm btn-primary d-inline menuActionBtn mt-2 px-4" onClick={e=>addMenuItemAfter(menuItem.id)}><h5 className="m-0">+</h5></button>
                                <button className="btn-sm btn-danger d-inline mb-2 px-4" onClick={e=>removeMenuItem(menuItem.id)}><h5 className="m-0">-</h5></button>
                                <div className="clearfix"></div>
                            </>
                        )
                    })
                }
            </>
        )
    }

    function renderPriceDetails() {
        const fields = [
            {
                name: "Vat1%",
                placeholder: "Vat1 %",
                value: "Vat1%"
            },
            {
                name: "Vat2%",
                placeholder: "Vat2 %",
                value: "Vat2%"
            },{
                name: "Vat1",
                placeholder: "Vat 1 Code",
                value: "Vat1"
            },{
                name: "Vat2",
                placeholder: "Vat 2 Code",
                value: "Vat2"
            },{
                name: "Vat1amt",
                placeholder: "Vat 1 Amount",
                value: "Vat1amt"
            },{
                name: "vat2amt",
                placeholder: "vat 2 Amount",
                value: "vat2amt"
            },{
                name: "Netamt1",
                placeholder: "Net Amount 1",
                value: "Netamt1"
            },{
                name: "Netamt2",
                placeholder: "Net Amount 2",
                value: "Netamt2"
            },{
                name: "Total",
                placeholder: "Total",
                value: "Total"
            }
        ]
        return (
            <>
                <h5 className="mr-2">Price Details</h5>
                {fields.flatMap((option, index) => (
                    <div className="col-sm-4 d-inline-block" key = {"receipt-priceDetails-div-"+index}>
                       <input type="text" className="form-control mt-1" name = {option.name} 
                       key = {"receipt-priceDetails-"+index}
                       placeholder={option.placeholder} value={formDetails.price_details[option.value]} onChange={e=>updatePriceDetails(option.name, e.target.value)}></input>
                    </div>
                ))}
            </>
        )
    }

    return ( 
        <div className="receiptAnnotation row">
            <div className="col-sm-5 d-inline-block imageBlock">
                <img src={currentUrl} className="receiptImage"></img>
            </div>
            <div className="col-sm-7 d-inline-block receiptForm">
                {renderMenu()}
                {renderPriceDetails()}
                {renderStoreForm()}
                <div className="buttons">
                    <button className="form-control btn-sm btn-primary mt-3 annotationSubmitBtn" type="submit" onClick={updateImageUrls}>Submit</button>
                    <button className="form-control btn-sm btn-danger mt-3 skipBtn" type="submit" onClick={()=>updateImageUrls("SKIPPED")}>Skip</button>
                </div>
                {renderTotal()}
            </div>
        </div>
    )
}