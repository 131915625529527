import React, { useState, useRef } from "react";
import './preprocessing.css'

export default function PreProcessing() {
    const [baseURL, setBaseUrl] = useState("")
    const inputFile = useRef(null) 
    const [baseImage, setBaseImage] = useState("")
    const [resultImage, setResultImage] = useState("")
    const preProcessingOptions = [
        {
            value: 'select_all',
            label: 'Select all'
        },
        {
            value: 'binarization',
            label: 'Binarization'
        },
        {
            value: 'noise_removal',
            label: 'Noise Removal'
        },
        {
            value: 'deskewing',
            label: 'De skewing'
        },
        {
            value: 'contrast_enhancement',
            label: 'Contrast Enhancement'
        },
        {
            value: 'smoothing',
            label: 'Smoothing'
        },
        {
            value: 'degradation_removal',
            label: 'Degradation Removal'
        },
        {
            value: 'super_resolution',
            label: 'Super Resolution'
        },
        {
            value: 'dehazing',
            label: 'Dehazing'
        },
        {
            value: 'deblurring',
            label: 'Deblurring'
        },
        {
            value: 'auto_layout_detection',
            label: 'Auto Layout Detection'
        },
        {
            value: 'box_detection',
            label: 'Box Detection'
        }
    ]
    const [selectedOptions, setSelectedOptions] = useState([])

    function selectFile(e) {
        if(e && e.target && e.target.files && e.target.files[0]) {
            setBaseImage(URL.createObjectURL(e.target.files[0]))
        } else {
            inputFile.current.click();
        }
    }

    function isUrlValid(str) {
        const webUrl = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(str);
        const localUrl = /^https?:\/\/localhost:[0-9]{1,5}\/([-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/i.test(str)
        return webUrl||localUrl
    }

    async function submitImage() {
        if(baseURL=='') {
            alert("Please input base url")
            return
        }

        if(!isUrlValid(baseURL)) {
            alert("Please enter correct url")
            return
        }

        if(baseImage=='') {
            alert("Please click on left blank section to select an image")
            return
        }
        const processingOptions = {}
        selectedOptions.forEach(i=> {
            console.log(i);
            processingOptions[i]= "True"
        })
        const body = {
            "IDAA_Request": {
                "image_name": "",
                "preprocess": [processingOptions]
            }
        }
        console.log(JSON.stringify(body));
        // const response = await fetch(baseURL + "contact_us", {
        //     method: "POST",
        //     body: JSON.stringify(body),
        //     headers: { "Content-type": "application/json" },
        //   });
    
        //   if (response && response.ok) {
        //     setName("");
        //     setMessage("");
        //     setPhone("");
        //     setEmail("");
        //     setCompany("");
        //     setSuccess(true);
        //     fbq('track', 'Lead');
        //     setTimeout(() => {
        //       setSuccess(false);
        //     }, 2000);
        // }
    }    
    function handleCheckbox(val) {
        if(selectedOptions.includes(val)) {
            const options = JSON.parse(JSON.stringify(selectedOptions))
            options.splice(options.indexOf(val), 1)
            console.log(options);
            setSelectedOptions(options)
        }else if(val=='select_all') {
            const options = preProcessingOptions.map(i=>i.value)
            setSelectedOptions(options)
        } else {
            const options = JSON.parse(JSON.stringify(selectedOptions))
            options.push(val)
            console.log(options);
            setSelectedOptions(options)
        }
    }
    return (
        <div className="preProcessingBlock">
            <div className="baseURLInput">
                <input className="form-control" type="url" value={baseURL} onChange={(e)=>setBaseUrl(e.target.value)} placeholder="Enter base api url"></input>
            </div>
            <div className="preProcessingImages row">
                <div className="col-sm-5 float-left d-inline-block h-100 baseImageDiv text-center" onClick={()=>selectFile(false)}>
                    <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={selectFile}/>
                    <img src={baseImage} className="baseImage"></img>
                </div>
                <div className="col-sm-2 h-100 d-inline-block p-1">
                    {preProcessingOptions.flatMap((option, index) => (
                        <div className="preprocess-option form-control text-center text-break" key={"preprocessOption-"+index}>
                            <input type="checkbox" className="" value={option.value} checked={selectedOptions.includes(option.value)} onChange={(e)=>handleCheckbox(e.target.value)}></input>
                            <br/>{option.label}
                        </div>
                    ))}
                    <button type="submit" className="form-control text-break p-1 btn-sm btn-primary submitBtn" onClick={submitImage}>Submit</button>
                </div>
                <div className="col-sm-5 h-100 d-inline-block returnImageDiv">
                    <img src={resultImage} className="resultImage"></img>
                </div>
            </div>
        </div>
        
    )
}