import React, { useEffect } from 'react'
import Header from '../../Components/Header/Header'
import Footer from "../../Components/Footer";
import Product1 from './Product1';
// import Product2 from './Product2';
import { useDispatch } from "react-redux";
import modalSlice from "../../redux/slices/modal-slice";
const Product = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(modalSlice.actions.hideModal());
      }, [])
    return (
        <div>
            <Header />
            <Product1/>
            {/* <Product2/> */}
            <Footer />
        </div>
    )
}

export default Product
