// import React from "react";
import { configureStore } from "@reduxjs/toolkit";
import modalSlice from "./slices/modal-slice";
import loggedInSlice from './slices/loggedIn-slice'
const store = configureStore({
  reducer: {
    modal: modalSlice.reducer,
    loggedIn: loggedInSlice.reducer
  },
});

export default store;
