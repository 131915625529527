import React, { useEffect, useRef, useState } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header/Header";
import './ContactUs.css'
import { useDispatch } from "react-redux";
import modalSlice from "../redux/slices/modal-slice";
const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState("");
  const temp1 = useRef();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (message.length < 10) {
      setFailure('Please add more details to your message')
      setTimeout(() => {
        setFailure("");
      }, 4000);
    } else {
      const response = await fetch(BASE + "contact_us", {
        method: "POST",
        body: JSON.stringify({
          name,
          email,
          message,
          company_name: company,
          phone_no: phone,
        }),
        headers: { "Content-type": "application/json" },
      });

      if (response && response.ok) {
        setName("");
        setMessage("");
        setPhone("");
        setEmail("");
        setCompany("");
        setSuccess(true);
        fbq('track', 'Lead');
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      }
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(modalSlice.actions.hideModal());
  }, [temp1]);

  return (
    <div>
      <Header />
      {/* <div ref={temp1} className="">
        <img
          src="https://image.freepik.com/free-photo/contact-us-communication-support-service-assistance-concept_53876-128103.jpg"
          style={{ width: "100%",  objectFit: "cover" }}
          className=" contact-img"
        ></img>
      </div> */}

      <section className="py-md-5 py-4 bg-white text-center">
        <div className="container contactContainer">
          <div className="row">
            <div className="px-4">
              {success && (
                <div
                  className="alert alert-success fade show mb-5"
                  role="alert"
                >
                  Thank you. We will contact you shortly.
                </div>
              )}
              {failure && failure.length && (
                <div
                  className="alert alert-danger fade show mb-5"
                  role="alert"
                >
                  {failure}
                </div>
              )}
              <h3 className="section-heading primary fw-bold display-5 py-4">
                Curious to Know more?
              </h3>
              <form onSubmit={handleSubmit} className="pb-4">
                <div className="mb-3 row">
                  <div className="col-md-3 col-sm-6 mb-3 mb-md-0">
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div className="col-md-3 col-sm-6 mb-3 mb-md-0">
                    <input
                      type="tel"
                      className="form-control"
                      id=""
                      minLength={5}
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      placeholder="Phone No"
                      required
                    />
                  </div>
                  <div className="col-md-3 col-sm-6 mb-3 mb-md-0">
                    <input
                      type="email"
                      className="form-control"
                      id="contactEmail"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div className="col-md-3 col-sm-6 mb-3 mb-md-0">
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      placeholder="Company Name"
                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <div className="col-md-12">
                    <textarea
                      type="text"
                      minLength={10}
                      value={message}
                      className="input textbox form-control"
                      placeholder="Message"
                      onChange={(e) => setMessage(e.target.value)}
                      style={{ height: "120px" }}
                      required
                    ></textarea>
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ backgroundColor: "#21209c", borderColor: "#21209c" }}
                >
                  Submit
                </button>
              </form>
            </div>
            {/* <div className="col-md-6 px-4 py-4 contactImg2">
              <img
                src="https://image.freepik.com/free-photo/businesswoman-looking-important-contact-phone_1163-4256.jpg"
                style={{ width: "100%" }}
              ></img>
            </div> */}
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default ContactUs;
