import { _via_data } from '../../Components/Annotator/viaData'
import { _via_share } from '../../Components/Annotator/viaShare'
import { _via_import_export } from '../../Components/Annotator/importExport'
import { _via_view_manager } from '../../Components/Annotator/viewManager'
import { _via_control_panel } from '../../Components/Annotator/controlPanel'
import { _via_view_annotator } from '../../Components/Annotator/viewAnnotator'
import { _via_util_msg_hide } from '../../Components/Annotator/utils'
const _VIA_VERSION = "3.0.10";

const _VIA_VIEW_MODE = {
  UNKNOWN: 0,
  IMAGE1: 1,
  IMAGE2: 2,
  IMAGEK: 3,
  VIDEO1: 101,
  VIDEO2: 102,
  VIDEOK: 103,
  AUDIO1: 201,
  AUDIO2: 202,
  AUDIOK: 203,
};
import React from 'react'

// class PersonalInfo extends React.Component {
//   render() {
//     console.log(this.props);

//   }
// }
class VanillaChildren extends React.Component {
  render() {
    if (this.props.children) {
      console.log(this.props.children);
      return <div style={{ height: '100%', width: '100%' }} ref={ref => ref ? ref.appendChild(this.props.children) : 'randomref'}></div>;
    } else {
      console.log('no children');
      console.log(this.props);
      return <div></div>
    }
  }
}
export default class Via extends React.Component {
  emptyContainer(id) {
    if (id) {
      return (
        <div id={id}></div>
      )
    } else {
      return <div></div>
    }
  }
  constructor() {
    super()
    this.state = {
      _ID: "_via",
      via: {
      },
      displayModal: 'none',
      calculatorData: {},
      initialized: false,
      uploadedImage: ''
    }
    // this.childUpdated = this.childUpdated.bind(this)
    this.hideModal = this.hideModal.bind(this)
    this.showModal = this.showModal.bind(this)
    this.updateValue = this.updateValue.bind(this)
    this._keydown_handler = this._keydown_handler.bind(this)
    this.openCalulator = this.openCalulator.bind(this)
    this._hook_on_browser_resize = this._hook_on_browser_resize.bind(this)
  }
  hideModal() {
    this.setState({ displayModal: 'none' })
  }
  updateValue(e, param) {
    const data = this.state.calculatorData
    const params = param.split('.')
    let objectToChange = data
    let i = 0
    for (; i < params.length - 1; i++) {
      objectToChange = objectToChange[params[i]]
    }
    objectToChange[params[i]] = e.target.value
    this.setState({ calculatorData: data })
  }
  showModal(calciData, files) {
    // console.log(files[0]);
    const data = JSON.parse(calciData)[0]
    if (data.Address) {
      const keys = Object.keys(data.Address)
      for (let i in keys) {
        const key = keys[i]
        if (!data.Address[key] || !data.Address[key].trim()) {
          // console.log('deleting ' + key);
          delete data.Address[key]
        }
      }
    }
    let url = ''
    if (files && files[0] && files[0].file && files[0].file.src) {
      url = URL.createObjectURL(files[0].file.src)
    }
    // TODO uncomment for modal
    this.setState({ displayModal: 'block', calculatorData: data, uploadedImage: url })
    console.log(data);
  }
  personalInfo() {
    const address = []
    if (this.state.calculatorData && this.state.calculatorData.Address) {
      const addressLength = Object.keys(this.state.calculatorData.Address).length
      const addressKeys = Object.keys(this.state.calculatorData.Address)
      for (let i = 0; i < addressLength; i++) {
        if (i === 0) {
          address.push(<label htmlFor="address0" className="form-label col-md-4 d-inline-block mt-3">Address</label>)
        }
        if (i > 0) {
          address.push(<div className='col-md-4 d-inline-block'></div>)
        }
        address.push(
          <div className="d-inline-block col-md-8 mt-1" key = {"address-"+addressKeys[i]}>
            <input value={this.state.calculatorData.Address[addressKeys[i]]}
              onChange={e => this.updateValue(e, 'Address.' + addressKeys[i])}
              className="form-control"
              name={'address' + i} />
          </div>
        )
      }
    }
    const SSN = []
    if (this.state.calculatorData && this.state.calculatorData.SSN) {
      SSN.push(
        <div className='col-md-4 d-inline-block mt-3'>
          <label htmlFor="SSN" className="form-label">SSN</label>
        </div>
      )
      SSN.push(
        <div className='col-md-8 d-inline-block'>
          <input value={this.state.calculatorData.SSN}
            name="SSN"
            onChange={e => this.updateValue(e, 'SSN')}
            className="form-control" />
        </div>
      )
    }
    return (
      <div id="personalInfo" className='d-inline-block row ms-0 me-0'>
        <div className='col-md-4 d-inline-block'>
          <label htmlFor="name" className="form-label">Name</label>
        </div>
        <div className='col-md-8 d-inline-block'>
          <input value={this.state.calculatorData.Name}
            name="name"
            onChange={e => this.updateValue(e, 'Name')}
            className="form-control" />
        </div>
        {address}
        {SSN}
      </div>
    )
  }

  parse2LevelObject(keyName, addDefault = '') {
    const data = []
    if (this.state.calculatorData && this.state.calculatorData[keyName]) {
      const obj = this.state.calculatorData[keyName]
      const l1Keys = Object.keys(obj)
      for (let i = 0; i < l1Keys.length; i++) {
        const l2Keys = Object.keys(obj[l1Keys[i]])
        for (let j = 0; j < l2Keys.length; j++) {
          let val = obj[l1Keys[i]][l2Keys[j]]
          if((!val || !val.trim()) && addDefault && j===0) {
            val = '0'
          }
          if (val && val.trim()) {
            if (j === 0) {
              const lbl = l1Keys[i].split(/(?=[A-Z])/);
              data.push(<label htmlFor={keyName + l1Keys[i]} className="form-label col-md-4 d-inline-block mt-3" key = {"labelKey-"+keyName+'-'+l1Keys[i]}>{lbl.join(' ')}</label>)
            }
            data.push(
              <div className={"d-inline-block col mt-1"} key = {"key-"+keyName+'-'+l1Keys[i]+'-'+l2Keys[j]}>
                <input value={val}
                  onChange={e => this.updateValue(e, keyName+'.' + l1Keys[i] + '.' + l2Keys[j])}
                  className="form-control"
                  name={keyName + l1Keys[i]} />
              </div>
            )
          }
        }
        data.push(<div className='clearfix' key = {"cfKey-"+keyName+'-'+l1Keys[i]} />)
      }
    } 

    if(data.length) {
      return (
        <div id={keyName} className='row align-items-center ms-0 me-0' key ={'parseKey-'+keyName}>
          {data}
        </div>
      )
    }else {
      return null
    }
  }
  componentWillUnmount() {
    if (this.state.uploadedImage) {
      URL.revokeObjectURL(this.state.uploadedImage)
    }
  }
  _keydown_handler(e) {
    // avoid handling events when text input field is in focus
    if (!this.state || !this.state.via || !this.state.via.va) {
      console.log(this.state);
      console.error('Via va not defined keydown');

      return
    }
    if (e.target.type !== "text" && e.target.type !== "textarea") {
      this.state.via.va._on_event_keydown(e);
    }
  }
  _hook_on_browser_resize() {
    if (!this.state || !this.state.via || !this.state.via.va) {
      console.error('Via va not defined browser resize');
      return
    }
    if (typeof this.state.via.va.vid !== "undefined") {
      this.state.via.va.view_show(this.va.vid);
    }
  }
  componentDidMount() {
    console.log("Initializing VGG Image Annotator (VIA) version " + _VIA_VERSION);
    // this.via_container = document.createElement('div');
    const via = this.state.via
    via.d = new _via_data(this.showModal);
    // this.d.temp_add()


    this.s = new _via_share(this.d);

    const view_container = document.createElement("div");
    view_container.setAttribute("id", "view_container");
    // view_container.height ='100%'
    // view_container.width = '100%'
    view_container.setAttribute('style', 'height:100%;width:100%;')
    // this.via_container.appendChild(this.view_container);

    const editor_container = document.createElement("div");
    editor_container.setAttribute("id", "editor_container");
    editor_container.classList.add("hide");
    // this.via_container.appendChild(this.editor_container);

    const message_container = document.createElement("div");
    message_container.setAttribute("id", "_via_message_container");
    message_container.setAttribute("class", "message_container");
    message_container.addEventListener("click", _via_util_msg_hide);

    const message_panel = document.createElement("div");
    message_panel.setAttribute("id", "_via_message");
    message_container.appendChild(message_panel);
    // this.via_container.appendChild(this.message_container);
    //// initialise content creators and managers

    this.ie = new _via_import_export(this.d);
    via.cp = {}
    via.va = new _via_view_annotator(view_container, _VIA_VIEW_MODE, via);
    // this.editor = new _via_editor(this.d, this.va, this.editor_container);

    const view_manager_container = document.createElement("div");

    via.vm = new _via_view_manager(via, view_manager_container)
    // via.vm._init();

    // control panel shows the view_manager_container


    // window.addEventListener("keydown", this._keydown_handler.bind(this)); // @todo: should be attached only to VIA application container
    // TODO uncomment for testing
    // via.d.project_save();
    const control_panel_container = document.createElement("div");
    control_panel_container.setAttribute(
      "id",
      "via_control_panel_container"
    );
    via.cp = new _via_control_panel(via, control_panel_container)
    via.cp._set_region_shape("RECTANGLE");
    document.addEventListener("keydown", this._keydown_handler);
    document.addEventListener("resize", this._hook_on_browser_resize);
    this.setState({
      via: via,
      control_panel_container: via.cp.c,
      view_container: view_container,
      editor_container: editor_container,
      message_container: message_container,
      view_manager_container: view_manager_container
    })
  }

  openCalulator() {
    // console.log('setting item');
    localStorage.setItem("calculatorData", JSON.stringify(this.state.calculatorData))
    // console.log(JSON.stringify(this.state.calculatorData));
    // console.log('item set');
    window.open('/calculator', "_self") 
  }
  render() {
    console.log('in render');
    console.log(this.state);
    const income = this.parse2LevelObject('Income', '0')
    const expense = this.parse2LevelObject('Expenses', '0')
    return (
      <div>
        <div id="annotatorStatus"></div>
        <div id="via_page_container">
          {/* Laded bottom comemtns */}
        </div>
        <div className="via_container col-md-6" id="via_container">
          <div id="via_start_info_content">
            <div className="drag-area color-blue"
              onClick={this.state.via.vm ? this.state.via.vm._on_add_media_local.bind(this.state.via.vm) : null}
            >Click to browse File</div>
          </div>
          {/* {this.state.via.cp} */}
          <VanillaChildren>{this.state.control_panel_container}</VanillaChildren>
          <VanillaChildren>{this.state.view_container}</VanillaChildren>
          <VanillaChildren>{this.state.editor_container}</VanillaChildren>
          <VanillaChildren>{this.state.message_container}</VanillaChildren>
        </div>


        <div className="column card col-md-5">
          <div className="tab" id="tab">
          </div>
          <div id="txt" className="tabcontent">
          </div>
          <div id="json" className="tabcontent">
          </div>
          <div id="csv" className="tabcontent">
          </div>
          <div id="calculator" className="tabcontent accordion">
          </div>
          <div id="calculator2" className="tabcontent accordion">
          </div>
        </div>

        <div className="modal" id="calculatorModal" style={{ display: this.state.displayModal, overflow: 'unset' }}>
          <div className="modal-dialog" style={{ maxWidth: 'unset' }}>
            <div className="modal-content" syle={{ height: '90vh' }}>
              <div className="modal-header">
                <h5 className="modal-title">Please verify parsed values</h5>
                <button type="button" className="btn-close border-0" onClick={this.hideModal} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className='col-md-6 d-inline-block'>
                  <img src={this.state.uploadedImage} style={{ width: '100%' }}></img>
                </div>
                <div className='col-md-6 d-inline-block'>
                  {this.personalInfo()}
                  {this.parse2LevelObject('Days')}
                  {income?<div className='Income'><div className='text-center mt-1 fw-bolder'>Income</div>{income}</div>:<div/>}
                  {expense?<div className='Income'><div className='text-center mt-1 fw-bolder'>Expenses</div>{expense}</div>:<div/>}
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                {/* <button type="button" className="btn btn-secondary border-0" data-bs-dismiss="modal" onClick={this.hideModal}>Close</button> */}
                <button type="button" className="btn btn-primary border-0 shadow-none" onClick={this.openCalulator}>Calculate</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

// function cleanValue(str) {
//   let val = str.replace(/,/g,'').replace(/.$/, '')
//   try {
//     val = parseFloat(val)
//   }catch(reason) {
//     console.error('canot parse '+val+' into number');
//   }
//   return val
// }