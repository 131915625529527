function _via_util_file_ext(filename) {
	return filename.substr(filename.lastIndexOf(".") + 1);
}
export function _via_util_load_text_file(text_file, callback_function) {
	if (text_file) {
		var text_reader = new FileReader();

		text_reader.addEventListener(
			"error",
			function () {
				console.log("Error loading data text file :  " + text_file.name + " !");
				callback_function("");
			},
			false
		);

		text_reader.addEventListener(
			"load",
			function () {
				callback_function(text_reader.result);
			},
			false
		);
		text_reader.readAsText(text_file, "utf-8");
	}
}
export const _VIA_FILE_SELECT_TYPE = {
	JSON: 2,
	CSV: 4,
	TEXT: 8,
	IMAGE: 16,
	VIDEO: 32,
	AUDIO: 64,
	WEBVTT: 128,
	PDF: 264,
};
export function _via_util_infer_file_type_from_filename(filename) {
	var ext = _via_util_file_ext(filename);
	switch (ext.toLowerCase()) {
		case "ogv":
		case "mp4":
		case "avi":
		case "webm":
			return _VIA_FILE_TYPE.VIDEO;
			break;
		case "jpg":
		case "jpeg":
		case "png":
		case "bmp":
			return _VIA_FILE_TYPE.IMAGE;
			break;
		case "mp3":
		case "wav":
		case "oga":
		case "ogg":
			return _VIA_FILE_TYPE.AUDIO;
	}
}

export function _via_util_file_type_to_str(type) {
	switch (type) {
		case _VIA_FILE_TYPE.IMAGE:
			return "image";
			break;
		case _VIA_FILE_TYPE.VIDEO:
			return "video";
			break;
		case _VIA_FILE_TYPE.AUDIO:
			return "audio";
			break;
		default:
			return "unknown " + type;
	}
}

export const _VIA_FILE_TYPE = { IMAGE: 2, VIDEO: 4, AUDIO: 8 };
export function _via_util_infer_file_loc_from_filename(filename) {
	if (filename.startsWith("http://") || filename.startsWith("https://")) {
		return _VIA_FILE_LOC.URIHTTP;
	} else {
		if (filename.startsWith("file://") || filename.includes("/")) {
			return _VIA_FILE_LOC.URIFILE;
		} else {
			return _VIA_FILE_LOC.LOCAL;
		}
	}
}
const _VIA_CONFIG = { MSG_TIMEOUT: 3000 };
let _via_msg_clear_timer; // holds a reference to current message timoout
export const _VIA_FILE_LOC = { LOCAL: 1, URIHTTP: 2, URIFILE: 3, INLINE: 4 };
export function _via_util_msg_show(msg, sticky) {
	var container = document.getElementById("_via_message_container");
	var content = document.getElementById("_via_message");
	if (container && content) {
		if (_via_msg_clear_timer) {
			clearTimeout(_via_msg_clear_timer);
		}
		if (typeof sticky === "undefined" || sticky === false) {
			_via_msg_clear_timer = setTimeout(function () {
				document.getElementById("_via_message_container").style.display =
					"none";
			}, _VIA_CONFIG.MSG_TIMEOUT);
		}

		content.innerHTML =
			msg + '<span class="message_panel_close_button">&times;</span>';
		container.style.display = "block";
	}
}
export function _via_util_msg_hide() {
	document.getElementById("_via_message_container").style.display = "none";
	if (_via_msg_clear_timer) {
		clearTimeout(_via_msg_clear_timer);
	}
}

export function _via_file(fid, fname, type, loc, src) {
	this.fid = fid;
	this.fname = fname;
	this.type = type;
	this.loc = loc;
	this.src = src;
}

export function _via_view(fid_list) {
	this.fid_list = fid_list; // [ [fid00, fid01, ...], [fid10, fid11, ...] ]
}

export function _via_util_date_to_filename_str(date_str) {
	var t = new Date(date_str);
	var month_list = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	return _via_util_pad10(t.getDate()) + month_list[t.getMonth()] + t.getFullYear() + '_' + _via_util_pad10(t.getHours()) + 'h' + _via_util_pad10(t.getMinutes()) + 'm' + _via_util_pad10(t.getSeconds()) + 's';
}

function _via_util_pad10(x) {
	if (x < 10) {
		return '0' + x.toString();
	} else {
		return x;
	}
}

export const _VIA_RSHAPE = { 'POINT': 1, 'RECTANGLE': 2, 'CIRCLE': 3, 'ELLIPSE': 4, 'LINE': 5, 'POLYLINE': 6, 'POLYGON': 7, 'EXTREME_RECTANGLE': 8, 'EXTREME_CIRCLE': 9 };
export function _via_util_float_arr_to_fixed(arr, fixed) {
	var farr = [];
	for (var i in arr) {
		farr[i] = parseFloat(arr[i].toFixed(fixed));
	}
	return farr;
}
export function _via_metadata(vid, z, xy, av) {
	this.vid = vid; // view id
	this.flg = 0; // flags reserved for future
	this.z = z; // [t0, ..., tn] (temporal coordinate e.g. time or frame index)
	this.xy = xy; // [shape_id, shape_coordinates, ...] (i.e. spatial coordinate)
	this.av = av; // attribute-value pair e.g. {attribute_id : attribute_value, ...}
}