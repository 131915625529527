import { _via_event } from './viaEvent'
import {
	_via_util_load_text_file,
	_via_util_infer_file_type_from_filename,
	_via_util_file_type_to_str, _VIA_FILE_LOC, _via_util_msg_show, _VIA_FILE_SELECT_TYPE
} from './utils'
export class _via_view_manager extends _via_event {
	constructor(via, container) {
		super()
		this._ID = "_via_view_manager_";
		this.d = via.d;
		this.va = via.va;
		this.c = container;
		this.files = []

		this.view_selector_vid_list = [];
		// var is_view_filtered_by_regex = false;

		// registers on_event(), emit_event(), ... methods from
		// _via_event to let this module listen and emit events
		// new _via_event(this);

		this.d.on_event(
			"project_loaded",
			this._ID,
			this._on_event_project_loaded.bind(this)
		);
		this.d.on_event(
			"project_updated",
			this._ID,
			this._on_event_project_updated.bind(this)
		);
		this.d.on_event(
			"view_bulk_add",
			this._ID,
			this._on_event_view_bulk_add.bind(this)
		);
		this.d.on_event("view_del", this._ID, this._on_event_view_del.bind(this));
		this.va.on_event("view_show", this._ID, this._on_event_view_show.bind(this));
		this.va.on_event("view_next", this._ID, this._on_event_view_next.bind(this));
		this.va.on_event("view_prev", this._ID, this._on_event_view_prev.bind(this));
		this._on_add_media_local = this._on_add_media_local.bind(this)
		this._init();
	}
	_init() {
		this._init_ui_elements();
		this._view_selector_update();
	};

	_init_ui_elements() {
		this.pname = document.createElement("input");
		this.pname.setAttribute("type", "text");
		this.pname.setAttribute("id", "via_project_name_input");
		this.pname.setAttribute("value", this.d.store.project.pname);
		this.pname.setAttribute("title", "Project Name (click to update)");
		this.pname.addEventListener("change", this._on_pname_change.bind(this));

		this.view_selector = document.createElement("select");
		this.view_selector.setAttribute("class", "view_selector");
		this.view_selector.setAttribute("title", "Select a file for annotation");
		this.view_selector.addEventListener(
			"change",
			this._on_view_selector_change.bind(this)
		);

		// this.view_filter_regex = document.createElement('input');
		// this.view_filter_regex.setAttribute('type', 'text');
		// this.view_filter_regex.setAttribute('class', 'view_filter_regex');
		// this.view_filter_regex.setAttribute('title', 'Filter file list');
		// this.view_filter_regex.setAttribute('placeholder', 'Search');
		// this.view_filter_regex.addEventListener('input', this._on_view_filter_regex_change.bind(this));

		this.c.appendChild(this.view_selector);
	};

	//
	// UI elements change listeners
	//
	_on_pname_change(e) {
		this.d.store.project.pname = e.target.value.trim();
	};

	_on_view_selector_change(e) {
		var vid = e.target.options[e.target.selectedIndex].value;
		if (vid !== this.va.vid) {
			this.va.view_show(vid);
		}
	};

	_on_next_view() {
		if (this.view_selector.options.length) {
			var vid =
				this.view_selector.options[this.view_selector.selectedIndex].value;
			var vindex = this.view_selector_vid_list.indexOf(vid);
			if (vindex !== -1) {
				var next_vindex = vindex + 1;
				if (next_vindex >= this.view_selector_vid_list.length) {
					next_vindex = 0;
				}
				this.va.view_show(this.view_selector_vid_list[next_vindex]);
			} else {
				_via_util_msg_show("Cannot move to next view!");
			}
		}
	};

	_on_prev_view() {
		if (this.view_selector.options.length) {
			var vid =
				this.view_selector.options[this.view_selector.selectedIndex].value;
			var vindex = this.view_selector_vid_list.indexOf(vid);
			if (vindex !== -1) {
				var prev_vindex = vindex - 1;
				if (prev_vindex < 0) {
					prev_vindex = this.view_selector_vid_list.length - 1;
				}
				this.va.view_show(this.view_selector_vid_list[prev_vindex]);
			} else {
				_via_util_msg_show("Cannot move to next view!");
			}
		}
	};

	_on_event_view_show(
		data,
		event_payload
	) {
		console.log('on evet view show');
		var vid = event_payload.vid.toString();
		this.view_selector.selectedIndex = -1;

		// ensure that the view selector shows the view being displayed
		var n = this.view_selector.options.length;
		for (var i = 0; i < n; ++i) {
			if (this.view_selector.options[i].value === vid) {
				this.view_selector.selectedIndex = i;
				break;
			}
		}
	};

	_on_event_view_next(
		data,
		event_payload
	) {
		this._on_next_view();
	};

	_on_event_view_prev(
		data,
		event_payload
	) {
		this._on_prev_view();
	};

	_on_event_project_loaded(
		data,
		event_payload
	) {
		this._init_ui_elements();
		this._view_selector_update();
		if (this.d.store.project.vid_list.length) {
			// show first view by default
			this.va.view_show(this.d.store.project.vid_list[0]);
		}
	};

	_on_event_project_updated(
		data,
		event_payload
	) {
		var current_vid = this.va.vid;
		this._init_ui_elements();
		this._view_selector_update();
		if (this.d.store.project.vid_list.length) {
			if (current_vid in this.d.store.project.vid_list) {
				this.va.view_show(current_vid);
			} else {
				// show first view by default
				this.va.view_show(this.d.store.project.vid_list[0]);
			}
		}
	};

	//
	// View Selector
	//
	_view_selector_clear() {
		this.view_selector.innerHTML = "";
		this.view_selector_vid_list = [];
	};

	_view_selector_option_html(
		vindex,
		vid
	) {
		console.log('_view_selector_option_html');
		var oi = document.createElement("option");
		oi.setAttribute("value", vid);

		var file_count = this.d.store.view[vid].fid_list.length;
		var view_name;
		if (file_count === 1) {
			console.log('fiel count 1');
			var fid = this.d.store.view[vid].fid_list[0];
			view_name = this.d.store.file[fid].fname;
			oi.innerHTML = "[" + (parseInt(vindex) + 1) + "] " + decodeURI(view_name);
		} else {
			var filelist = [];
			var fid;
			for (var findex in this.d.store.view[vid].fid_list) {
				fid = this.d.store.view[vid].fid_list[findex];
				filelist.push(this.d.store.file[fid].fname);
			}
			oi.innerHTML = "[" + (parseInt(vindex) + 1) + "] " + filelist.join(", ");
		}
		console.log(oi);
		return oi;
	};

	_view_selector_update() {
		// if (this.is_view_filtered_by_regex) {
		//   this._view_selector_update_regex();
		// } else {
		this._view_selector_update_showall();
		// }
	};

	_view_selector_update_regex(regex) {
		if (regex === "" || typeof regex === "undefined") {
			this._view_selector_update_showall();
		} else {
			var existing_vid = "";
			if (this.view_selector.options.length) {
				if (this.view_selector.selectedIndex !== -1) {
					existing_vid =
						this.view_selector.options[this.view_selector.selectedIndex].value;
				}
			}
			this._view_selector_clear();
			var vid, fid;
			for (var vindex in this.d.store.project.vid_list) {
				vid = this.d.store.project.vid_list[vindex];
				for (var findex in this.d.store.view[vid].fid_list) {
					fid = this.d.store.view[vid].fid_list[findex];
					if (this.d.store.file[fid].fname.match(regex) !== null) {
						this.view_selector.appendChild(
							this._view_selector_option_html(vindex, vid)
						);
						this.view_selector_vid_list.push(vid);
						break;
					}
				}
			}
			this.is_view_selector_regex_active = true;
			var existing_vid_index = this.view_selector_vid_list.indexOf(existing_vid);
			if (existing_vid_index === -1) {
				if (this.view_selector_vid_list.length) {
					this.va.view_show(this.view_selector_vid_list[0]);
				}
			} else {
				this.view_selector.selectedIndex = existing_vid_index;
			}
		}
	};

	_view_selector_update_showall() {
		console.log('called update show all');
		var existing_selectedIndex = this.view_selector.selectedIndex;
		var existing_vid;
		if (existing_selectedIndex !== -1) {
			existing_vid = this.view_selector.options[existing_selectedIndex].value;
		}
		this._view_selector_clear();

		var vid;
		for (var vindex in this.d.store.project.vid_list) {
			vid = this.d.store.project.vid_list[vindex];
			this.view_selector.appendChild(
				this._view_selector_option_html(vindex, vid)
			);
			this.view_selector_vid_list.push(vid);
		}
		console.log('existing select index'+existing_selectedIndex);
		// this.is_view_filtered_by_regex = false;
		if (existing_selectedIndex !== -1) {
			var existing_vid_index = this.view_selector_vid_list.indexOf(existing_vid);
			if (existing_vid_index === -1) {
				this.view_selector.selectedIndex = -1;
			} else {
				this.view_selector.selectedIndex = existing_vid_index;
			}
		}
	};

	// _on_view_filter_regex_change () {
	//   var regex = this.view_filter_regex.value;
	//   this._view_selector_update_regex(regex);
	// }

	_file_add_from_filelist(filelist) {
		console.log('_file_add_from_filelist');
		this.d.view_bulk_add_from_filelist(filelist).then(
			function (ok) {
				console.log('view_bulk_add_from_filelist resturned');
				var filetype_summary = {};
				var fid, ftype_str;
				for (var findex in ok.fid_list) {
					fid = ok.fid_list[findex];
					ftype_str = _via_util_file_type_to_str(this.d.store.file[fid].type);
					if (!filetype_summary.hasOwnProperty(ftype_str)) {
						filetype_summary[ftype_str] = 0;
					}
					filetype_summary[ftype_str] = filetype_summary[ftype_str] + 1;
					console.log(this.d.store.file[fid]);
				}
				_via_util_msg_show(
					"Added " +
					ok.fid_list.length +
					" files. " +
					JSON.stringify(filetype_summary)
				);
			}.bind(this),
			function (err) {
				_via_util_msg_show("Failed to add files! [" + err + "]");
				console.warn(err);
			}.bind(this)
		);
	};

	_on_add_media_local() {
		console.log('_on_add_media_local called');
		console.log(this);
		const that = this
		console.log(typeof this._via_util_file_select_local);
		this._via_util_file_select_local(
			_VIA_FILE_SELECT_TYPE.IMAGE |
			_VIA_FILE_SELECT_TYPE.VIDEO |
			_VIA_FILE_SELECT_TYPE.AUDIO |
			_VIA_FILE_SELECT_TYPE.PDF,
			that._file_add_local.bind(that),
			true
		);
	};

	_file_add_local(e) {
		console.log('called add local');
		this.files = e.target.files;
		const that = this;
		for (var findex = 0; findex < that.files.length; ++findex) {
			if (that.files[findex].type === "application/pdf") {
				// console.log('calling convert');
				convertPdfToImages(that.files[findex], that);
			} else {
				that._file_add_local_functionality([that.files[findex]]);
			}
		}
	};
	_file_add_local_functionality(
		files,
		local = true
	) {
		console.log('called file add local functionaluty');
		var filelist = [];
		for (var findex = 0; findex < files.length; ++findex) {
			console.log("check 1");
			console.log(files[findex]);
			filelist.push({
				fname: files[findex].name,
				type: _via_util_infer_file_type_from_filename(files[findex].name),
				loc: local ? _VIA_FILE_LOC.LOCAL : _VIA_FILE_LOC.URIHTTP,
				src: files[findex],
				lId: "",
			});
			console.log(filelist);
		}
		this._file_add_from_filelist(filelist);
	};

	_on_event_view_bulk_add(
		data,
		event_payload
	) {
		console.log('event emmiter called');
		console.log(event_payload);
		this._view_selector_update();
		this.d._cache_update();
		if (event_payload.vid_list.length) {
			this.va.view_show(event_payload.vid_list[0]);
		}
	};

	_on_add_media_remote() {
		var url = window.prompt(
			"Enter URL of an image, audio or video (e.g. http://www....)",
			""
		);
		var filelist = [
			{
				fname: url,
				type: _via_util_infer_file_type_from_filename(url),
				loc: _VIA_FILE_LOC.URIHTTP,
				src: url,
			},
		];

		this._file_add_from_filelist(filelist);
	};

	_on_add_media_bulk() {
		this._via_util_file_select_local(
			_VIA_FILE_SELECT_TYPE.TEXT,
			this._on_add_media_bulk_file_selected,
			false
		);
	};

	_on_add_media_bulk_file_selected(e) {
		if (e.target.files.length) {
			_via_util_load_text_file(
				e.target.files[0],
				this._on_add_media_bulk_file_load.bind(this)
			);
		}
	};

	_on_add_media_bulk_file_load(
		file_data
	) {
		var url_list = file_data.split("\n");
		if (url_list.length) {
			var filelist = [];
			for (var i = 0; i < url_list.length; ++i) {
				if (url_list[i] === "" || url_list[i] === " " || url_list[i] === "\n") {
					continue; // skip
				}
				filelist.push({
					fname: url_list[i],
					type: _via_util_infer_file_type_from_filename(url_list[i]),
					loc: _via_util_infer_file_loc_from_filename(url_list[i]),
					src: url_list[i],
				});
			}
			this._file_add_from_filelist(filelist);
		}
	};

	_on_del_view() {
		this.d.view_del(this.va.vid).then(
			function (ok) {
				_via_util_msg_show("Deleted view " + (parseInt(ok.vindex) + 1));
			}.bind(this),
			function (err) {
				console.warn(err);
			}.bind(this)
		);
	};

	_on_event_view_del(
		data,
		event_payload
	) {
		this._view_selector_update();
		var vindex = event_payload.vindex;
		if (this.d.store.project.vid_list.length) {
			if (vindex < this.d.store.project.vid_list.length) {
				this.va.view_show(this.d.store.project.vid_list[vindex]);
			} else {
				this.va.view_show(
					this.d.store.project.vid_list[this.d.store.project.vid_list.length - 1]
				);
			}
		} else {
			this.va._init();
		}
	};
	_via_util_file_select_local(type, handler, multiple) {
		console.log('_via_util_file_select_local called');
		let fsel = document.createElement("input");
		fsel.setAttribute("type", "file");
		fsel.setAttribute("name", "files[]");
		if (typeof multiple === "undefined" || multiple === true) {
			fsel.setAttribute("multiple", "multiple");
		}
	
		switch (type) {
			case _VIA_FILE_SELECT_TYPE.IMAGE:
				fsel.accept = "image/*";
				break;
			case _VIA_FILE_SELECT_TYPE.VIDEO:
				fsel.accept = "video/*";
				break;
			case _VIA_FILE_SELECT_TYPE.AUDIO:
				fsel.accept = "audio/*";
				break;
			case _VIA_FILE_SELECT_TYPE.TEXT:
				fsel.accept = ".csv,.txt";
				break;
			case _VIA_FILE_SELECT_TYPE.JSON:
				fsel.accept = ".json";
				break;
			case _VIA_FILE_SELECT_TYPE.VIDEO | _VIA_FILE_SELECT_TYPE.AUDIO:
				fsel.accept = "video/*,audio/*";
				break;
			case _VIA_FILE_SELECT_TYPE.VIDEO |
				_VIA_FILE_SELECT_TYPE.AUDIO |
				_VIA_FILE_SELECT_TYPE.IMAGE:
				fsel.accept = "video/*,audio/*,image/*";
				break;
			case _VIA_FILE_SELECT_TYPE.WEBVTT:
				fsel.accept = ".vtt";
				break;
		}
		fsel.onchange = function (e) {
			handler(e);
			console.log('fsel');
			console.log(e.target);
			console.log(e.target.value);
			if (e.target.value) {
				document.getElementById("via_start_info_content")?.remove();
			}
		};
		fsel.click();
	}
}