import React, { useEffect } from "react";
import { useState } from "react";
import Lottie from "react-lottie-player";
import avatar from "../../assets/lottie/avatar.json";
import { useDispatch } from "react-redux";
import modalSlice from "../../redux/slices/modal-slice";

const EditProfile = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    fetch(BASE + "get_profile", {
      method: "POST",
      headers: getHeaders("application/json"),
    })
      .then((res) => res.json())
      .then((value) => {
        setEmail(value.email);
        setName(value.name);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isEdit) {
      setIsEdit(true);
      return false;
    }
    const response = await fetch(BASE + "save_profile", {
      method: "POST",
      body: JSON.stringify({ name, email, password }),
      headers: getHeaders("application/json"),
    });
    if (response && response.ok) {
      dispatch(modalSlice.actions.hideModal());
    }
  };

  return (
    <div className="container px-4 pb-5">
      <div className="text-center">
        <Lottie
          speed={0.5}
          style={{
            width: 150,
            margin: "auto",
            height: 150,
            marginTop: "-2rem",
          }}
          className="mb-3"
          loop
          animationData={avatar}
          play
        />
      </div>
      <form onSubmit={(e) => handleSubmit(e)} style={{ marginTop: "-2rem" }}>
        <h4
          className="font-weight-bold text-center"
          style={{ fontWeight: "bold" }}
        >
          Profile
        </h4>
        <div className="my-3">
          <label
            htmlFor="name"
            className="form-label"
            style={{ fontWeight: "bold" }}
          >
            Name
          </label>
          <input
            className="form-control"
            placeholder="Name"
            id="name"
            disabled={!isEdit}
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>
        <div className="my-3">
          <label
            htmlFor="email"
            className="form-label"
            style={{ fontWeight: "bold" }}
          >
            Email address
          </label>
          <input
            type="email"
            disabled={!isEdit}
            className="form-control"
            placeholder="Email"
            id="profileEmail"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            aria-describedby="emailHelp"
          />
        </div>
        <div className="mb-3">
          <label
            htmlFor="password"
            className="form-label"
            style={{ fontWeight: "bold" }}
          >
            Password
          </label>
          <input
            type="password"
            disabled={!isEdit}
            className="form-control"
            id="profilePassword"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            placeholder="Password"
          />
        </div>

        <div
          id="loginErrorMessage"
          className="mb-2"
          style={{ color: "red" }}
        ></div>

        <button type="submit" className="btn bg-primary text-white py-2 w-100">
          {isEdit?'Save Profile':'Edit Profile'}
        </button>

        {/* <div
          role="button"
          onClick={() => {
            dispatch(
              modalSlice.actions.showModal({
                body: <Signup />,
              })
            );
          }}
          className="mt-3 underline"
        >
          <u>Create your account? Signup</u>
        </div> */}
      </form>
    </div>
  );
};

export default EditProfile;
