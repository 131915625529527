import React, { useEffect } from "react";
import "./home.css";
import Header from "../../Components/Header/Header";

import FirstSection from "./FirstSection";
import Benefits from "./benefits";
import WhatWeDo from "./whatWeDo";
import Contact from "./contact";
// import Features from "./Features";
// import Client from "./Client";
// import Offers from "./Offers/Offers";
import Footer from "../../Components/Footer";
// import { checkLogin } from "../../Components/login";
// import Blogs from "./Blogs";

const Home = () => {
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  // useEffect(() => {
  //   loggedIn = checkLogin()
  // });
  useEffect(() => {
    window.scrollTo(0, 0)
    window.via_container = '';
    window.via = '';
    document.getElementById("via_container_main").innerHTML = '';
  }, [])
  return (
    <div className="homePage">
      <Header/>

      <FirstSection/>
      <Benefits />
      <div className="clearfix"></div>
      <WhatWeDo />
      <div className="clearfix"></div>
      <Contact />
      {/* <Client /> */}
      {/* <Features /> */}
      {/* <Offers /> */}
      {/* <Blogs /> */}
      <div className="clearfix"></div>
      <Footer />
    </div>
  );
};

export default Home;
