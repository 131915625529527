import React from "react";
import { useDispatch, useSelector } from "react-redux";
import modalSlice from "../../redux/slices/modal-slice";

const Modal = () => {
  const { visible, body, dismissable, title } = useSelector(
    (state) => state.modal
  );
  const dispatch = useDispatch();
  if (!visible) return null;

  return (
    <div
      className="modal d-block"
      style={{ zIndex: 10 }}
      id="exampleModalCenter"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-backdrop" style={{zIndex:-1}}></div>
     
      <div className="modal-dialog modal-dialog-sm modal-dialog-centered" role="document">
        <div className="modal-content" 
        // style={{backgroundImage= `url(${bg})`}}
        >
        {/* <img src={bg} className="image-bg" /> */}
          <div className="modal-header border-0">
            <h5 className="modal-title" id="exampleModalLabel">
              {title && <div className="">{title}</div>}
            </h5>
            <button
              onClick={() => {
                if (dismissable) dispatch(modalSlice.actions.hideModal());
              }}
              type="button"
              className="btn-close"
              style={{ color: "#e2142d" }}
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {body && <div className="">{body}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
