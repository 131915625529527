// import React from 'react'
import { createSlice } from "@reduxjs/toolkit";

const loggedInSlice = createSlice({
    name: "loggedIn",
    initialState: {
      loggedIn: false
    },
    reducers: {
      changeLogin: (state, value) => {
        console.log('updating login');
        console.log(value.payload);
        state.loggedIn = value.payload
      },
    },
  });

export default loggedInSlice
