import React from "react";
// import logo from "../assets/images/LendExLogo.png";
import logo from "../assets/svgs/logo.svg";
import './Footer.css'
export default function Footer() {
  return (
    <div className="text-white blueColor footerMain">
      <div className="container footerContent">
        <img
          src={logo}
          height="80"
          width="80" className="hideMobile"
        />

        <div className="py-4">
          <div className="row py-2">
            <div className="col-md-4">
              <h4 className="footer-head">Artificial Intelligence </h4>

              <p>At MetaShape.ai, by using our next-generation data architecture, we help organizations virtually manage agile workflows. We thrive because of our market knowledge and great team behind our product. As our CEO says, "Efficiencies will come from proactively transforming how we do business."</p>
            </div>
            <div className="col-md-4 footer-block hideMobile">
              <h4 className="footer-head">Support</h4>
              <ul className="footer-link">
                <li className=""><a href="#">Home</a></li>
                <li className=""><a href="#">About Us</a></li>
                <li className=""><a href="#">Product</a></li>
                <li className=""><a href="#">Contact Us</a></li>
              </ul>
            </div>
            <div className="col-md-4">
              <h4 className="footer-head hideMobile">Get in touch!</h4>
              <p>Mail us at <a href="mailto:business@metashape.ai">business@metashape.ai</a></p>

              <h4 className="footer-head mt-5 hideMobile">Social Media</h4>
              <ul className="social-icon">
                <li className="">
                  <a href="https://www.linkedin.com/company/metashape-ai/" target="_blank" style={{color: "white"}}>
                  <svg
                    className="footer-icon"
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14.381.5H1.619C1.014.496.517.977.5 1.581v12.794c.014.606.513 1.088 1.119 1.081h12.762c.606.007 1.105-.475 1.119-1.081V1.581C15.483.977 14.986.496 14.381.5zM4.944 13.256h-2.2V6.125h2.2v7.131zM3.869 5.131c-.702 0-1.277-.56-1.294-1.262a1.25 1.25 0 0 1 1.294-1.294c.678.047 1.204.611 1.204 1.291s-.526 1.243-1.204 1.291v-.025zm9.431 8.081h-2.175V9.725c0-.831 0-1.912-1.162-1.912s-1.338.912-1.338 1.831v3.531H6.4v-7.05h2.075v.938h.044C8.952 6.31 9.77 5.861 10.638 5.9c2.244 0 2.663 1.5 2.663 3.406v3.906z"
                      fill="currentColor"
                    ></path>
                  </svg></a>
                </li>
                {/* <li className="">
                  <svg
                    className="footer-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 14 12"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.45 11.588c2.132.013 4.181-.828 5.689-2.336s2.349-3.557 2.336-5.689v-.369c.547-.401 1.021-.894 1.4-1.456a5.82 5.82 0 0 1-1.619.444 2.85 2.85 0 0 0 1.25-1.563c-.552.331-1.156.564-1.787.688-.874-.931-2.264-1.16-3.39-.559s-1.71 1.884-1.422 3.128C4.639 3.764 2.525 2.694 1.094.931a2.82 2.82 0 0 0 .875 3.75c-.438-.017-.867-.137-1.25-.35v.031a2.83 2.83 0 0 0 2.219 2.781 2.83 2.83 0 0 1-1.25.05A2.82 2.82 0 0 0 4.3 9.125c-.994.8-2.23 1.241-3.506 1.25a6.11 6.11 0 0 1-.669-.056 7.98 7.98 0 0 0 4.325 1.25"
                      fill="currentColor"
                    ></path>
                  </svg>
                </li> */}
              </ul>
            </div>

          </div>
        </div>
      
      <div className="copy-right">
        <p className="" style={{ color: "#c4c4c4", fontSize: "12px" }}>
          © {new Date().getFullYear()} MetaShape. All Rights Reserved | Privacy
          Policy | Employee Privacy Policy
        </p>
      </div>
      </div>
    </div>

  );
}
