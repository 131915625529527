import React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header/Header";
// import Aboutbanner from "./../assets/images/banner.jpg";
import Aboutbanner from "./../assets/images/aboutImage.jpg";

const AboutUs = () => {
  return (
    <div>
      <Header />
      <div className="" style={{ position: "relative" }}>
        <img
          src={Aboutbanner}
          style={{ height: "525px", objectFit: "cover", width: "100%" }}
        />
      </div>
      <div className="overlay"></div>

      <section className="bg-white px-3 px-md-0" style={{ padding: "100px 0" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2
                className="primary fw-bold display-5 py-4"
                style={{ position: "relative" }}
              >
                Who We Are
                <span
                  className="header-left"
                  style={{
                    position: "absolute",
                    fontSize: "120px",
                    top: "-10px",
                    left: "0",
                    lineHeight: "96px",
                    top: "-5px",
                    opacity: "0.1",
                  }}
                >
                  01
                </span>
              </h2>
              <p style={{ lineHeight: "32px" }}>
                We are a diverse team of scientists, developers & experts from
                diverse domains with a vision to provide smart automated
                solutions to expedite our customers journey. Who we are: The
                vision with which we started was to build intelligent systems
                for people working in varied areas, helping them solve complex
                problems for faster and more accurate decisions. To do so, we
                started gathering the best of talents through extensive searches
                around the globe. And, today our core members are scientists
                from computational science, along with domain experts, from
                various institutions and industries.
              </p>
              <p style={{ lineHeight: "32px" }}>
                Our investments in research and development help us bring out
                optimal solutions to the problems. On top of that, our domain
                experts work as a constant guide, and drive to our developers &
                clients for working out a state-of-the-art solutions using
                machine intelligence.
              </p>
            </div>
            <div className="col-md-6">
              <div data-aos="fade-left">
                <img
                  src="https://image.freepik.com/free-vector/startup-managers-presenting-analyzing-sales-growth-chart-group-workers-with-heap-cash-rocket-bar-diagrams-with-arrow-heap-money_74855-14166.jpg"
                  style={{ width: "100%" }}
                ></img>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="px-3 px-md-0"
        style={{ backgroundColor: "#ebf2fc", padding: "100px 0" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 graphic-img">
              <img
                src="https://image.freepik.com/free-vector/investment-crowdfunding-concept_74855-7563.jpg"
                style={{ width: "100%" }}
              ></img>
            </div>
            <div className="col-md-6">
              <h2
                className="primary fw-bold display-5 py-4"
                style={{ position: "relative" }}
              >
                Mission
                <span
                  className="header-left"
                  style={{
                    position: "absolute",
                    fontSize: "120px",
                    top: "-10px",
                    left: "0",
                    lineHeight: "96px",
                    top: "-5px",
                    opacity: "0.1",
                  }}
                >
                  02
                </span>
              </h2>
              <p style={{ lineHeight: "32px" }}>
                The company works with the purpose of providing solutions for
                complex tasks which various industries are still doing manually
                and enabling them with strong & smart automations to fasten up
                there processes accurately.
              </p>
            </div>
            <div className="col-md-6 d-md-none">
              <img
                src="https://image.freepik.com/free-vector/investment-crowdfunding-concept_74855-7563.jpg"
                style={{ width: "100%" }}
              ></img>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white px-3 px-md-0" style={{ padding: "100px 0" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2
                className="primary fw-bold display-5 py-4"
                style={{ position: "relative" }}
              >
                Vision
                <span
                  className="header-left"
                  style={{
                    position: "absolute",
                    fontSize: "120px",
                    top: "-10px",
                    left: "0",
                    lineHeight: "96px",
                    top: "-5px",
                    opacity: "0.1",
                  }}
                >
                  03
                </span>
              </h2>
              <p style={{ lineHeight: "32px" }}>
                Our only vision is to smartly automate the tasks done by the
                clients in a way which helps them fasten up their mechanisms and
                do better decision making. We think the technology is to be
                developed by humans for humans to make their life easier.
              </p>
            </div>
            <div className="col-md-6">
              <img
                src="https://image.freepik.com/free-vector/woman-choosing-bank-piggybank-choosing-instrument-saving-planning-budget-loan_74855-11205.jpg"
                style={{ width: "100%" }}
              ></img>
            </div>
          </div>
        </div>
      </section>
      {/* <section
        className=""
        style={{ backgroundColor: "#ebf2fc", padding: "100px 0" }}
      >
        <div className="container">
          <div className="row">
            <h2
              className="primary fw-bold display-5 py-4 text-center"
              style={{ position: "relative" }}
            >
              Why Join Us
              <span
                className="header-left"
                style={{
                  position: "absolute",
                  fontSize: "120px",
                  top: "-10px",
                  left: "0",
                  right: "0",
                  lineHeight: "96px",
                  top: "-5px",
                  opacity: "0.1",
                }}
              >
                Lend Ex
              </span>
            </h2>
            <div className="col-md-6">
              <ul className="py-5 wrapper-list">
                <li>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </li>
                <li>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s
                </li>
                <li>
                  It has survived not only five centuries, but also the leap
                  into electronic typesetting,
                </li>
              </ul>
            </div>
            <div className="col-md-6">
              <ul className="py-5 wrapper-list">
                <li>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </li>
                <li>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s
                </li>
                <li>
                  It has survived not only five centuries, but also the leap
                  into electronic typesetting,
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section> */}
      <Footer />
    </div>
  );
};

export default AboutUs;
