import React, { useState } from "react";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import CalculatorContext from "../../../contexts/CalculatorContext";

const CorporateGuarantor = ({ data, setData }) => {
  const [corporateGuarantor, setCorporateGuarantor] = useState([
    { name: "", percentOwnership: "", description: "" },
  ]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...corporateGuarantor];
    list[index][name] = value;
    setCorporateGuarantor(list);
    setData({ ...data, page3: { ...data.page3, corporateGuarantors: list } });
  };

  const handleAddClick = () => {
    setCorporateGuarantor([
      ...corporateGuarantor,
      { name: "", percentOwnership: "", description: "" },
    ]);
  };

  const handleRemoveClick = (index) => {
    const list = [...corporateGuarantor];
    list.splice(index, 1);
    setCorporateGuarantor(list);
  };

  return (
    <div>
      {corporateGuarantor.map((x, i) => (
        <div key={i} className="border rounded p-2 mt-3">
          <div className="d-flex align-items-center justify-content-between">
            <p style={{ fontWeight: "bold" }}>Corporate Guarantor(s)</p>
            <div>
              {corporateGuarantor.length - 1 === i && (
                <FontAwesomeIcon
                  role="button"
                  onClick={handleAddClick}
                  icon={faPlusCircle}
                  style={{
                    color: "#21209c",
                    height: "21",
                    width: "21",
                  }}
                />
              )}
              {corporateGuarantor.length !== 1 && (
                <FontAwesomeIcon
                  role="button"
                  onClick={() => handleRemoveClick(i)}
                  icon={faMinusCircle}
                  style={{
                    color: "#e2142d",
                    height: "21",
                    width: "21",
                    marginLeft: "0.8em",
                  }}
                />
              )}
            </div>
          </div>
          <div className="row g-3 align-items-center mb-1">
            <div className="col-6">
              <label htmlFor="inputPassword6" className="col-form-label">
                Name
              </label>
            </div>
            <div className="col-6">
              <input
                type="text"
                className="form-control"
                placeholder="Name of the guarentor"
                name="name"
                value={x.name}
                onChange={(e) => handleInputChange(e, i)}
              />
            </div>
          </div>

          <div className="row g-3 align-items-center my-1">
            <div className="col-6">
              <label htmlFor="inputPassword6" className="col-form-label">
                Percent Ownership
              </label>
            </div>
            <div className="col-6">
              <input
                type="text"
                className="form-control"
                placeholder="%"
                name="percentOwnership"
                value={x.percentOwnership}
                onChange={(e) => handleInputChange(e, i)}
              />
            </div>
          </div>

          <div className="row g-3 align-items-center my-1">
            <div className="col-6">
              <label htmlFor="inputPassword6" className="col-form-label">
                Description
              </label>
            </div>
            <div className="col-6">
              <input
                type="text"
                className="form-control"
                id="page2PurchaseAmountinput"
                placeholder="Description"
                name="description"
                value={x.description}
                onChange={(e) => handleInputChange(e, i)}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CorporateGuarantor;
