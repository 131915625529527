import React, { useState, useEffect } from "react";
// import CalculatorContext from "../../contexts/CalculatorContext";
import CorporateGuarantor from "./component/CorporateGuarantor";
import Guarantor from "./component/Guarantor";
import IncomeExpense from "./component/IncomeExpense";
import LoanDescription from "./component/LoanDescription";
import PropertyDescription from "./component/PropertyDescription";

const Page3 = ({ data, setData }) => {
  const [addrtype, setAddrType] = useState();

  const handleAddrTypeChange = (e) => {
    setAddrType(e.target.value);
    setData({ ...data, page3: { ...data.page3, asset: addrtype } });
  };

  useEffect(() => {
  }, [data]);

  return (
    <>
      <div className="row g-3 align-items-center mb-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Date
          </label>
        </div>
        <div className="col-6">
          <input
            type="date"
            className="form-control"
            id="page2PurchaseAmountinput"
            value={data?.page3?.date}
            onChange={(e) =>
              setData({
                ...data,
                page3: {
                  ...data.page3,
                  date: e.target.value,
                },
              })
            }
          />
        </div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Loan
          </label>
        </div>
        <div className="col-6">
          <input
            type="text"
            className="form-control"
            id="page2PurchaseAmountinput"
            value={data?.page3?.loan}
            onChange={(e) =>
              setData({
                ...data,
                page3: {
                  ...data.page3,
                  loan: e.target.value,
                },
              })
            }
          />
        </div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Property Address
          </label>
        </div>
        <div className="col-6">
          <div>{data?.page1?.address}</div>
        </div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Asset
          </label>
        </div>
        <div className="col-6">
          <select
            className="form-select"
            aria-label="Default select example"
            defaultValue={addrtype}
            onChange={handleAddrTypeChange}
          >
            <option value="1">Multi Family</option>
            <option value="2">Mixed Use</option>
            <option value="3">Warehouse</option>
            <option value="4">Office</option>
            <option value="5">Retail</option>
            <option value="6">Owner Occupied</option>
            <option value="7">Hotel/Restaurant</option>
            <option value="8">Describe your own</option>
          </select>
        </div>
      </div>

      <PropertyDescription data={data} setData={setData} />

      <Guarantor data={data} setData={setData} />

      <CorporateGuarantor data={data} setData={setData} />

      <LoanDescription data={data} setData={setData} />

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Property Value
          </label>
        </div>
        <div className="col-6">
          {data.page3?.propertyValue ? data.page3.propertyValue.toFixed(2) : 0}
        </div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Loan Amount
          </label>
        </div>
        <div className="col-6">{data.page2?.loanAmount ? data.page2.loanAmount.toFixed(2) : 0}</div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Rate
          </label>
        </div>
        <div className="col-6">
          <input
            type="text"
            className="form-control"
            id="page2PurchaseAmountinput"
            value={data?.page3?.rate}
            onChange={(e) =>
              setData({
                ...data,
                page3: {
                  ...data.page3,
                  rate: Number(e.target.value),
                },
              })
            }
          />
        </div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Fee
          </label>
        </div>
        <div className="col-6">
          <input
            type="text"
            className="form-control"
            id="page2PurchaseAmountinput"
            value={data?.page3?.fee}
            onChange={(e) =>
              setData({
                ...data,
                page3: {
                  ...data.page3,
                  fee: Number(e.target.value),
                },
              })
            }
          />
        </div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Amortization in Months
          </label>
        </div>
        <div className="col-6">
          <input
            type="text"
            className="form-control"
            id="page2PurchaseAmountinput"
            value={data?.page3?.amortizationInMonths}
            onChange={(e) =>
              setData({
                ...data,
                page3: {
                  ...data.page3,
                  amortizationInMonths: Number(e.target.value),
                },
              })
            }
          />
        </div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Years of Amortization
          </label>
        </div>
        <div className="col-6">
          {data.page3?.yearsOfAmortization ? data.page3.yearsOfAmortization : 0}
        </div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            LTV
          </label>
        </div>
        <div className="col-6">{data.page3?.LTV ? data.page3.LTV.toFixed(2) : 0}</div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Monthly P & I
          </label>
        </div>
        <div className="col-6">{data.page3?.monthlyPI ? data.page3.monthlyPI.toFixed(2) : 0}</div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Interest portion on first month payment
          </label>
        </div>
        <div className="col-6">
          {data.page3?.interestPortion ? data.page3.interestPortion.toFixed(2) : 0}
        </div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Annual P & I
          </label>
        </div>
        <div className="col-6">{data.page3?.annualPI ? data.page3.annualPI.toFixed(2) : 0}</div>
      </div>

      <IncomeExpense data={data} setData={setData} />
    </>
  );
};

export default Page3;
