import React from "react";
// import Login from "../../pages/Login/Login";
import modalSlice from "../../redux/slices/modal-slice";
import { useDispatch } from "react-redux";
import homeImage from "../../assets/svgs/home.svg"
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";

const slides = [
  "Automation",
  "Accurate Decision Making",
  "Expedited Underwriting",
  "Financial Analysis",
  "Risk Mitigation",
  "Complex Analysis",
  "Structuring",
  "Hyperautomation"
];

const FirstSection = () => {
  // function loginSimulate(e) {
  //   const elm = document.getElementById('loginButton')
  //   if(elm) {
  //     elm.click()
  //   }else {
  //     window.location.href = "/annotator";
  //   }
  // }
  const dispatch = useDispatch();
  const [active, setActive] = useState(0);
  // const {loggedIn} = useSelector((state) => state.loggedIn)
  // const ref = useRef();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(modalSlice.actions.hideModal());
    const interval = setInterval(() => {
      setActive((a) => {
        a = a + 1;
        if (a == slides.length) {
          return 0;
        } else {
          return a;
        }
      });
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {/* <div ref={ref} role="listitem" className="listitems d-none">
        <div className="bar" style={{ backgroundImage: `url(${bgimg})` }}>
          <div className="bar-container">
            <div className="bar-text">
              Two Credit Unions Winning With AI-Driven Auto Lending
            </div>
            <a href="#" className="btn btn-sm btn-contact">
              Read The Case Studies
            </a>
          </div>
          <a
            onClick={() => ref.current.remove()}
            className="w-inline-block close"
          >
            <div>✕</div>
          </a>
        </div>
      </div> */}

      <section className="first-section" style={{ backgroundImage: `url(${homeImage})` }}>
        {/* <video autoPlay playsInline loop muted className="video-class">
          <source src={video} type="video/mp4" />
        </video> */}
        <div className="first-section-text ">
          <h1 className="homePageFirstText">Artificial Intelligence for</h1>
          <div>
            {slides.flatMap((slide, index) => (
              <div
                key={index}
                className={`animate__animated animate__fadeInDown animate__faster display-3-sub ${active === index ? "" : "d-none"
                  }`}
              >
                <span>
                  {slide}
                </span>
              </div>
            ))}
            <span className="homePage-section1-text3">
              Annotate & Automate
            </span>
          </div>
          <div className="btn btn-homePage" onClick={() => {
            navigate('/contact')
          }}>
            Get Started&nbsp;&nbsp;&nbsp;-&gt;
          </div>
        </div>
        {/* <div className="scroll-down">
          <a href="" className=" w-inline-block">
            <div className="w-wmbed">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  d="M16 28l10-10-1.41-1.41L17 24.17V2h-2v22.17l-7.59-7.58L6 18l10 10z"
                  fill="#fff"
                ></path>
              </svg>
            </div>
          </a>
        </div> */}
      </section>
    </>
  );
};

export default FirstSection;
