import React from "react";
import './benefits.css';
import megaphone from '../../assets/svgs/Megaphone.svg';
import MaskSad from '../../assets/svgs/MaskSad.svg'
import Crosshair from '../../assets/svgs/Crosshair.svg'
import TrendUp from '../../assets/svgs/TrendUp.svg'

const benefitsList = [
	{
		title: 'CLOSING THE GAP',
		text: 'The products & services should be an effective solution meeting business requirement and improving company processes making them more efficient.',
		icon: megaphone
	},
	{
		title: 'COST EFFECTIVE',
		text: 'The solutions should be cost-effective so that they stand out from the replacements used by various companies.',
		icon: MaskSad
	},
	{
		title: 'TAILORED SOLUTIONS',
		text: 'The solutions used by the businesses should be customized and according to their requirement, improving their work processes.',
		icon: Crosshair
	},
	{
		title: 'MAKING LIFE EASY',
		text: 'Solutions should be easy-to-use and help the users in a targeted way making their work life smooth.',
		icon: TrendUp
	},
]
export default function Benefits() {
	return (
		<section className="sectionMain">
			<div className="sectionHeading">
				<span className="colorSec">How</span> can we help?
			</div>
			<div className="sectionSubHeading">
			The rapidly growing digital world has a pressing dual need for both speed and accuracy.
			</div>
			<div className="benefits-details row">
			{benefitsList.flatMap((slide, index) => (
              <div key={'benefits-'+index} className='col-sm-3 benefits-list-wrapper'>
                <div className="benefits-list-heading">
					<img src = {slide.icon} className='benefits-list-image'></img>
					<span className="benefits-list-headingText">{slide.title}</span>
				</div>
				<div className="benefits-list-text">
					{slide.text}
				</div>
              </div>
            ))}
			</div>
		</section>
	)
}