import React, { useContext, useState } from "react";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Note = ({ data, setData }) => {
    const [note, setNote] = useState([
        { note: "" },
      ]);
    
      const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...note];
        list[index][name] = value;
        setNote(list);
        setData({ ...data, page3: { ...data.page3, note: list } });
      };
    
      const handleAddClick = () => {
        setNote([
          ...note,
          { note: ""},
        ]);
      };
    
      const handleRemoveClick = (index) => {
        const list = [...note];
        list.splice(index, 1);
        setNote(list);
      };

    return (
        <div>
      {note.map((x, i) => (
        <div key={i} className="border rounded p-2 mt-3">
          <div className="d-flex align-items-center justify-content-between">
            <p style={{ fontWeight: "bold" }}></p>
            <div>
              {note.length - 1 === i && (
                <FontAwesomeIcon
                  role="button"
                  onClick={handleAddClick}
                  icon={faPlusCircle}
                  style={{
                    color: "#21209c",
                    height: "21",
                    width: "21",
                  }}
                />
              )}
              {note.length !== 1 && (
                <FontAwesomeIcon
                  role="button"
                  onClick={() => handleRemoveClick(i)}
                  icon={faMinusCircle}
                  style={{
                    color: "#e2142d",
                    height: "21",
                    width: "21",
                    marginLeft: "0.8em",
                  }}
                />
              )}
            </div>
          </div>
          <div className="row g-3 align-items-center mb-1">
            <div className="col-6">
              <label htmlFor="inputPassword6" className="col-form-label">
              Note
              </label>
            </div>
            <div className="col-6">
              <input
                type="text"
                className="form-control"
                placeholder=""
                name="customLabel"
                value={x.note}
                onChange={(e) => handleInputChange(e, i)}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
    )
}

export default Note
