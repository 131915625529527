import React from "react";
// import { useEffect } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
// import { useContext } from "react";
// import CalculatorContext from "../../contexts/CalculatorContext";

const Calculator1 = ({ data, setData }) => {
  // console.log("data",data[0]?.Address)
  useDeepCompareEffect(()=>{
    // console.log("called calci1 use effetct");
    // console.log(data);
  },[data])
  return (
    <>
      <div className="row g-3 align-items-center mb-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Property Address
          </label>
        </div>
        <div className="col-6">
          <textarea
            className="form-control "
            id="page1PropertyAddressinput"
            rows="2"
            value={data?.page1?.address.trim()}
            onChange={(e) =>
              setData({
                ...data,
                page1: { ...data?.page1, address: e.target.value },
              })
            }
          />
        </div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Lot Size
          </label>
        </div>
        <div className="col-6">
          <input
            type="number"
            min="0"
            step="0.0001"
            className="form-control"
            id="page1LotSizeinput"
          />
        </div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Borrower(s) [Business or LLC]
          </label>
        </div>
        <div className="col-6">
          <input type="text" className="form-control" id="page1Borrowerinput" />
        </div>
      </div>

      <p className="mt-4" style={{ fontWeight: "bold" }}>
        Principal
      </p>
      <div className="row g-3 align-items-center mb-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Name
          </label>
        </div>
        <div className="col-6">
          <input
            type="text"
            required
            className="form-control"
            id="page1Borrowerinput"
            value={data?.page1?.name}
            onChange={(e) =>
              setData({
                ...data,
                page1: { ...data?.page1, name: e.target.value },
              })
            }
          />
        </div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Address
          </label>
        </div>
        <div className="col-6">
          <input
            type="text"
            className="form-control"
            id="page1Borrowerinput"
            value={data?.page1?.userAddress}
            onChange={(e) =>
              setData({
                ...data,
                page1: {
                  ...data.page1,
                  userAddress: e.target.value,
                },
              })
            }
          />
        </div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Date Of Birth
          </label>
        </div>
        <div className="col-6">
          <input
            type="text"
            className="form-control"
            id="page1Borrowerinput"
            value={data?.page1?.dob}
            onChange={(e) =>
              setData({
                ...data,
                page1: {
                  ...data.page1,
                  dob: e.target.value,
                },
              })
            }
          />
        </div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Social Security Number
          </label>
        </div>
        <div className="col-6">
          <input
            type="text"
            className="form-control"
            id="page1Borrowerinput"
            value={data?.page1?.securityNumber}
            onChange={(e) =>
              setData({
                ...data,
                page1: {
                  ...data.page1,
                  securityNumber: e.target.value,
                },
              })
            }
          />
        </div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Primary Phone Number
          </label>
        </div>
        <div className="col-6">
          <input
            type="text"
            className="form-control"
            id="page1Borrowerinput"
            value={data?.page1?.phoneNumber}
            onChange={(e) =>
              setData({
                ...data,
                page1: {
                  ...data.page1,
                  phoneNumber: e.target.value,
                },
              })
            }
          />
        </div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Email Address
          </label>
        </div>
        <div className="col-6">
          <input
            type="text"
            className="form-control"
            id="page1Borrowerinput"
            value={data?.page1?.email}
            onChange={(e) =>
              setData({
                ...data,
                page1: {
                  ...data.page1,
                  email: e.target.value,
                },
              })
            }
          />
        </div>
      </div>

      <div className="d-flex flex-row-reverse">
        <button
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTwo"
          aria-expanded="false"
          aria-controls="collapseTwo"
          className="bg-primary rounded text-white py-1 px-3 mt-2 collapsed"
          style={{
            marginRight: "10px",
            fontWeight: "bold",
            borderColor: "#21209c",
          }}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default Calculator1;
