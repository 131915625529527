import React from "react";
import Lottie from "react-lottie-player";
import { useNavigate } from "react-router-dom";
import product from "../../assets/lottie/products.json";

const Product1 = () => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className="container-fluid px-5 px-md-0"
        style={{
          backgroundColor: "#0d0d3e",
          color: "white",
          minHeight: "92vh",
        }}
      >
        <div className="container product">
          <div className="row">
            <div className="col-md-6 mt-md-5">
              <h1
                className="display-3 fw-bold py-4"
                style={{ position: "relative" }}
              >
                Why us?
              </h1>
                <ul style={{ lineHeight: "32px" }}>
                  <li>Automatic creation of business reports, statements and more that requires referencing from various sources multiple times.</li>
                  <li>Cumbersome & time-consuming manual processes of copy, paste and format made easy.</li>
                </ul>
            </div>
            <div className="col-md-6">
              <Lottie
                className="mb-3 lottie"
                loop
                animationData={product}
                play
              />
            </div>
          </div>
        </div>
      </div>
      <section className="bg-white px-3 px-md-0" style={{ padding: "100px 0" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2
                className="primary fw-bold display-5 py-4"
                style={{ position: "relative" }}
              >
                IDP Engine
                <span
                  className="header-left"
                  style={{
                    position: "absolute",
                    fontSize: "120px",
                    top: "-10px",
                    left: "0",
                    lineHeight: "96px",
                    top: "-5px",
                    opacity: "0.1",
                  }}
                >
                  01
                </span>
              </h2>
                <ul style={{ lineHeight: "32px" }}>
                  <li>AI Engine brings to you a perfect break through to all your roadblocks.</li>
                  <li>Intelligent automation for processing document.</li>
                  <li>Customized document templates & labels.</li>
                  <li>Focused actions by the company professionals.</li>
                  <li>Accurate results for the document analyzed.</li>
                  <li>Efficient outputs by the end-users.</li>
                  <li>Purposed extensions for further automation.</li>
                </ul>
            </div>
            <div className="col-md-6">
              <img
                src="orig/images/Product1.png"
                style={{ width: "100%", marginTop: "-70px" }}
              ></img>
            </div>
          </div>
        </div>
      </section>

      <section
        className="px-3 px-md-0"
        style={{ backgroundColor: "#ebf2fc", padding: "100px 0" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 graphic-img">
              <img
                src="orig/images/Lendex.png"
                style={{ width: "65%" }}
              ></img>
            </div>
            <div className="col-md-6">
              <h2
                className="primary fw-bold display-5 py-4"
                style={{ position: "relative" }}
              >
                Lendex
                <span
                  className="header-left"
                  style={{
                    position: "absolute",
                    fontSize: "120px",
                    top: "-10px",
                    left: "0",
                    lineHeight: "96px",
                    top: "-5px",
                    opacity: "0.1",
                  }}
                >
                  02
                </span>
              </h2>
              <p style={{ lineHeight: "32px" }}>
              Pioneers in bringing Automation & Machine Learning to Banking and Financial Services. From Automating Commercial Loan Originations, to hyper-expediting Financial Analysis, Lendex.ai brings you the future of Banking and Corporate Analytics.
              </p>
            </div>
            <div className="col-md-6 d-md-none">
              <img
                src="https://image.freepik.com/free-vector/investment-crowdfunding-concept_74855-7563.jpg"
                style={{ width: "100%" }}
              ></img>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="bg-white px-3 px-md-0" style={{ padding: "100px 0" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2
                className="primary fw-bold display-5 py-4"
                style={{ position: "relative" }}
              >
                Product 3
                <span
                  className="header-left"
                  style={{
                    position: "absolute",
                    fontSize: "120px",
                    top: "-10px",
                    left: "0",
                    lineHeight: "96px",
                    top: "-5px",
                    opacity: "0.1",
                  }}
                >
                  03
                </span>
              </h2>
              <p style={{ lineHeight: "32px" }}>
                Our only vision is to smartly automate the tasks done by the
                clients in a way which helps them fasten up their mechanisms and
                do better decision making. We think the technology is to be
                developed by humans for humans to make their life easier.
              </p>
            </div>
            <div className="col-md-6">
              <img
                src="https://image.freepik.com/free-vector/woman-choosing-bank-piggybank-choosing-instrument-saving-planning-budget-loan_74855-11205.jpg"
                style={{ width: "100%" }}
              ></img>
            </div>
          </div>
        </div>
      </section> */}

      <section
        className="px-3 px-md-0"
        style={{ padding: "100px 0" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img
                src="https://image.freepik.com/free-vector/investment-crowdfunding-concept_74855-7563.jpg"
                style={{ width: "100%" }}
              ></img>
            </div>
            <div className="col-md-6">
              <h2
                className="primary fw-bold display-5 py-4"
                style={{ position: "relative" }}
              >
                Starting your journey?
                <span
                  className="header-left"
                  style={{
                    position: "absolute",
                    fontSize: "120px",
                    top: "-10px",
                    left: "0",
                    lineHeight: "96px",
                    top: "-5px",
                    opacity: "0.1",
                  }}
                >
                  ?
                </span>
              </h2>
              <p style={{ lineHeight: "32px" }}>
              We empower organizations to automate their processes by closing the loop and leveraging them with agile frameworks using artificial intelligence.</p>
              <p style={{ lineHeight: "32px" }}>We help businesses grow organically by making their tasks faster, accurate and cheaper.
              </p>
              <button
                onClick={() => navigate("/contact")}
                className="btn btn-sm btn-contact mt-3"
                style={{ backgroundColor: "#21209c", color: "white" }}
              >
                Contact Us
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Product1;
