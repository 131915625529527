// import CalculatorContext from "../../contexts/CalculatorContext";
import "./calculator.css";
import Calculator1 from "./Calculator1";
import Calculator2 from "./Calculator2";
import Page3 from "./Page3";

import React, { Component } from "react";

function parseData(data) {
  const mapping = {
    active: true,
    page1: {
      name: data[0]?.Name,
      address: flatJoin(data[0]?.Address),
    },
    page2: {
      purchaseAmount: 1,
      type: "NewPurchase",
      loanAmount: null,
      preliminaryLTV: null,
      annualPI: null,
      totalRefinanceAmount: null,
      approximateLTV: null,
    },
    page3: {
      income: {
        custom: Object.keys(data[0]?.Income).reduce((pV, cV) => {
          const value = flatAddNumber(data[0]?.Income[cV]);
          if (value) {
            return Object.assign(
              {
                ...pV,
                [cV]: flatAddNumber(data[0]?.Income[cV]),
              },
              {}
            );
          } else {
            return pV;
          }
        }, {}),
      },
      expense: {
        custom: Object.keys(data[0]?.Expenses).reduce((pV, cV) => {
          const value = flatAddNumber(data[0]?.Expenses[cV]);
          if (value) {
            return Object.assign(
              {
                ...pV,
                [cV]: flatAddNumber(data[0]?.Expenses[cV]),
              },
              {}
            );
          } else {
            return pV;
          }
        }, {}),
      },
      yearsOfAmortization: 0,
      LTV: 0,
      interestPortion: 0,
      monthlyPI: 0,
      annualPI: 0,
      propertyValue: 0
    },
  };

  return mapping
}

function flatJoin(obj) {
  if (!obj) return "";
  return Object.keys(obj).reduce((pV, cV) => pV + obj[cV] + "\n", "");
}

function flatAddNumber(obj) {
  if (!obj) return 0;
  return Object.keys(obj).reduce((pV, cV) => {
    const value =
      pV +
      (isNaN(obj[cV].replace(",", "")) || obj[cV] === ""
        ? 0
        : Number(obj[cV].replace(",", "")));
    return value;
  }, 0);
}
class Calculator extends Component {
  constructor(props) {
    super(props);
    let mapping = {}
    if (props && props.data) {
      mapping = parseData(props.data)
    }
    // console.log("Mapping", mapping);
    this.state = {
      stopLoad: true,
      data: mapping,
    };
  }

  componentDidMount() {
    // console.log('called did mount');
    try {
      const data = localStorage.getItem('calculatorData')
      console.log('got item');
      console.log(data);
      if (data) {
        localStorage.removeItem('calculatorData')
        const parsedData = parseData([JSON.parse(data)])
        console.log(parsedData);
        this.setState({ data: JSON.parse(JSON.stringify(parsedData)), stopLoad: false })
      }else {
        this.setState({stopLoad: false})
      }
    } catch (reason) {
      console.error(reason);
      console.error('No calci data stored');
    }
  }

  setData(data) {
    if (typeof data === "function") {
      this.setState({
        data: data(this.state.data),
      });
    } else {
      const loanAmount =
        data?.page2?.type === "NewPurchase"
          ? data?.page2?.purchaseAmount - data?.page2?.downPayment
          : data?.page2?.existingDebt + data?.page2?.additionalCashoutRequested;
      const preliminaryLTV = (loanAmount / data?.page2?.purchaseAmount) * 100;

      // const annualPI = data?.page2?.monthlyPrincipalInterest * 12;
      const totalRefinanceAmount =
        data?.page2?.existingDebt + data?.page2?.additionalCashoutRequested;
      const approximateLTV =
        (totalRefinanceAmount / data?.page2?.approximateValueProperty) * 100;



      const propertyValue =
        data?.page2?.type === "NewPurchase"
          ? data?.page2?.purchaseAmount
          : data?.page2?.approximateValueProperty;

      const yearsOfAmortization = data?.page3?.amortizationInMonths / 12;
      const LTV =
        (data?.page2?.purchaseAmount - data?.page2?.downPayment) /
        data?.page2?.purchaseAmount;

      const interestPortion = loanAmount * (data?.page3?.rate / 100 / 12);

      const monthlyPI =
        interestPortion /
        [
          1 -
          Math.pow(
            1 + data?.page3?.rate / 100 / 12,
            -1 * data?.page3?.amortizationInMonths
          ),
        ];

      const annualPI = monthlyPI * 12;

      data.page3 = {
        ...data.page3,
        yearsOfAmortization: yearsOfAmortization,
        LTV: LTV,
        interestPortion: interestPortion,
        monthlyPI: monthlyPI,
        annualPI: annualPI,
        propertyValue: propertyValue
      }
      data.page2 = {
        ...data.page2,
        type: data.active ? "NewPurchase" : "Refinance",
        loanAmount: isNaN(loanAmount) ? null : loanAmount,
        preliminaryLTV: isNaN(preliminaryLTV) ? null : preliminaryLTV,
        annualPI: isNaN(annualPI) ? null : annualPI,
        totalRefinanceAmount: isNaN(totalRefinanceAmount)
          ? null
          : totalRefinanceAmount,
        approximateLTV: isNaN(approximateLTV) ? null : approximateLTV,
      }
      this.setState({
        data,
      });
    }
  }

  //   curl --location --request POST 'https://lendex.ai/api/saveCalculator' \
  // --header 'userid: 3' \
  // --header 'accesstoken: 74381388157' \
  // --header 'Content-Type: application/json' \
  // --data-raw '{
  //     "data": {
  //         "income": [],
  //         "expenses": []
  //     },
  //     "file_id": 380
  // }'

  async handleDownload(e) {
    e.preventDefault();
    const self = this;
    let res = await fetch("https://lendex.ai/api/saveCalculator", {
      method: "POST",
      body: JSON.stringify({
        data: self.state.data,
        "file_id": 1
      }),
      headers: {
        userid: getCookie("user"),
        accesstoken: getCookie("accesstoken"),
        "Content-Type": "application/json",
      },
    });
    let resData = await res.json();
    // window.location.reload();
  }

  render() {
    return (
      <form method="post" action="" onSubmit={this.handleDownload.bind(this)}>
        <div className="accordion" id="accordionExample">
          {/* <button
            className="p-2 mt-1 text-uppercase"
            type="submit"
            style={{
              backgroundColor: "#0d6efd",
              color: "white",
              borderRadius: "6px",
            }}
          >
            Download Calculator
          </button> */}
          <div className="accordion-item mt-3">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
                style={{ fontWeight: "bold" }}
              >
                Calculator 1
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                {this.state.stopLoad?<div/>:<Calculator1
                  data={this.state.data}
                  setData={this.setData.bind(this)}
                />}
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
                style={{ fontWeight: "bold" }}
              >
                Calculator 2
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
              {this.state.stopLoad?<div/>:<Calculator2
                  data={this.state.data}
                  setData={this.setData.bind(this)}
                />}
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
                style={{ fontWeight: "bold" }}
              >
                Calculator 3
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
              {this.state.stopLoad?<div/>:<Page3
                  data={this.state.data}
                  setData={this.setData.bind(this)}
                />}
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

window.Calculator = Calculator;

export default Calculator;
