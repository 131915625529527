import React, { useContext, useState } from "react";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomLabel = ({ data, setData }) => {
  const [customLabel, setCustomLabel] = useState(
    data?.page3?.income?.custom ?? {}
  );

  const handleInputChange = (key, value) => {
    if (!value) return;
    const list = { ...customLabel, [key]: value };
    setCustomLabel(list);
    setData({
      ...data,
      page3: {
        ...data.page3,
        income: {
          ...data.page3?.income,
          custom: { ...list },
        },
      },
    });
  };

  const handleAddClick = () => {
    let label = prompt("Enter Label name?");
    if (!label || label.trim() === "") return;
    const list = { ...customLabel, [label]: "" };
    setCustomLabel(list);
    setData({
      ...data,
      page3: {
        ...data.page3,
        income: {
          ...data.page3?.income,
          custom: { ...list },
        },
      },
    });
  };

  const handleRemoveClick = (key) => {
    const obj = { ...customLabel };
    delete obj[key];
    setCustomLabel({ ...obj });
    setData({
      ...data,
      page3: {
        ...data.page3,
        income: {
          ...data.page3?.income,
          custom: { ...obj },
        },
      },
    });
  };

  return (
    <div className="mt-4">
      <div
        className="d-inline-flex align-items-center border p-2"
        role="button"
        onClick={handleAddClick}
      >
        Add a new Field{" "}
        <FontAwesomeIcon
          role="button"
          icon={faPlusCircle}
          style={{
            color: "#21209c",
            height: "21",
            width: "21",
            marginLeft: 4,
          }}
        />
      </div>
      {Object.keys(customLabel).map((key, i) => (
        <div key={i} className="mt-3  rounded p-2 ">
          <div className="d-flex align-items-center justify-content-between">
            <p style={{ fontWeight: "bold" }}></p>
            <div>
              <FontAwesomeIcon
                role="button"
                onClick={() => handleRemoveClick(key)}
                icon={faMinusCircle}
                style={{
                  color: "#e2142d",
                  height: "21",
                  width: "21",
                  marginLeft: "0.8em",
                }}
              />
            </div>
          </div>
          <div className="row g-3 align-items-center mb-1">
            <div className="col-6">
              <label htmlFor="inputPassword6" className="col-form-label">
                *{key}
              </label>
            </div>
            <div className="col-6">
              <input
                type="number"
                className="form-control"
                placeholder=""
                name={key}
                value={customLabel[key] ?? 0}
                onChange={(e) => handleInputChange(key, e.target.valueAsNumber)}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CustomLabel;
