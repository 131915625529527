import React from "react";
// import logo from "../../assets/images/LendExLogo.png";
import logo from "../../assets/svgs/logo.svg";
// import Login from "../../pages/Login/Login";
import modalSlice from "../../redux/slices/modal-slice";
import { useDispatch, useSelector } from "react-redux";
import EditProfile from "../../pages/Login/EditProfile";
import { NavLink, useNavigate } from "react-router-dom";
import "./header.css";
import {setCookie} from '../login';
import loggedInSlice from "../../redux/slices/loggedIn-slice";
import Login from "../../pages/Login/Login";
// import { useEffect } from "react";
const Header = () => {
  const dispatch = useDispatch();
  const {loggedIn} = useSelector((state) => state.loggedIn)
  // console.log('header login is' +loggedIn);
  const navigate = useNavigate()
  function logout() {
    setCookie("user", "");
    setCookie("accesstoken", "");
    dispatch(loggedInSlice.actions.changeLogin(false))
    navigate('/')
  }

  return (
    <nav className="navbar navbar-expand-lg text-white">
      <div className="container">
        <a className="navbar-logo" href="/">
          <img src={logo} height="50" width="50" />
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <svg
            style={{ color: "#fff" }}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-list navbar-toggler-icon"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>
        </button>

        <div
          className="collapse navbar-collapse justify-content-center text-center"
          id="navbarNav"
        >

          <ul className="navbar-nav header-nav">
            <li className="nav-item">
              <NavLink
                className="nav-link text-white"
                exact="true"
                to="/"
                activeClassName="active"
              ><span className="hideMobile">&nbsp;&nbsp;</span>Home&nbsp;&nbsp;</NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                className="nav-link text-white"
                exact="true"
                to="/product"
                activeClassName="active"
              ><span className="hideMobile">&nbsp;&nbsp;</span>Product&nbsp;&nbsp;</NavLink>
            </li>

            {/* <li className="nav-item">
              <NavLink
                className="nav-link text-white"
                exact
                to="/#about"
                activeClassName="active"
              >&nbsp;&nbsp;About&nbsp;&nbsp;</NavLink>
              {/* 
              <a
                className="nav-link "
                aria-current="page"
                href="/about"
                style={{ color: "white" }}
              >
                About Us
              </a> * /}
            </li> */}

            <li className="nav-item">
              <NavLink
                className="nav-link text-white"
                exact="true"
                to="/contact"
                activeClassName="active"
              ><span className="hideMobile">&nbsp;&nbsp;</span>Contact&nbsp;&nbsp;</NavLink>
              {/* 
              <a
                className="nav-link"
                href="/contact"
                style={{ color: "white" }}
              >
                Contact Us
              </a> */}
            </li>

            {loggedIn ? (
              <li className="nav-item" id="annotatorButton">
                <NavLink
                  className="nav-link text-white"
                  exact="true"
                  to="/annotator"
                  activeClassName="active"
                ><span className="hideMobile">&nbsp;&nbsp;</span>Annotator&nbsp;&nbsp;</NavLink>
              </li>
            ) : (
              ""
            )}

            {loggedIn ? (
              <li className="nav-item" id="editProfileButton">
                <button
                  className="nav-link text-white editProfileBtn"
                  onClick={() => {
                    dispatch(
                      modalSlice.actions.showModal({
                        body: <EditProfile />,
                      })
                    );
                  }}
                >Edit Profile</button>
              </li>
            ) : (
              ""
            )}
          </ul>
          {/* <!-- Login button on top --> <button
                data-toggle="modal"
                data-target="#exampleModalCenter"
                id="loginButton"
                onClick={() => {
                  dispatch(
                    modalSlice.actions.showModal({
                      body: <Login setIsLoggedIn={setIsLoggedIn} />,
                    })
                  );
                }}
                className="btn btn-sm btn-logout"
                style={{ fontWeight: "bold" }}
              >
                <div className="btn-t">Login</div>
              </button> */}
          <div className="logInButtons">
            {!loggedIn ? (
              <button
              className="btn btn-sm btn-logout text-white "
              onClick={() => {
                dispatch(
                  modalSlice.actions.showModal({
                    body: <Login />,
                  })
                );
              }}
            ><span className="btn-t">Login</span></button>
            ) : (
              <button
                onClick={() => logout()}
                className="btn btn-sm btn-logout"
                id="logoutButton"
                style={{ fontWeight: "bold" }}
              >
                <div className="btn-t">Logout</div>
              </button>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
