import React, { useEffect, useState } from "react";
import './contact.css';
export default function Contact() {
	const [email, setEmail] = useState("");
	const [name, setName] = useState("");
	const [message, setMessage] = useState("");
	const [hidden, setMessageHidden] = useState(true)
	const handleSubmit = async (e) => {
		e.preventDefault();
		const response = await fetch(BASE + "contact_us", {
			method: "POST",
			body: JSON.stringify({ name, email, message }),
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		});
		setMessageHidden(false)
	};
	useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
	return (
		<section className="sectionMain">
			<div className="sectionHeading sectionContact">
				<div className="sectionHeadingContact">
					<span className="colorSec">Contact</span> us
				</div>
				<form onSubmit={(e) => handleSubmit(e)} className="contactForm">
					<input
						type="text"
						className="form-control contactName"
						placeholder="Enter your name"
						id="name"
						onChange={(e) => setName(e.target.value)}
						value={name}
						aria-describedby="name"
					/>
					<input
						type="email"
						className="form-control contactEmail"
						placeholder="Enter your email"
						id="contactEmail"
						onChange={(e) => setEmail(e.target.value)}
						value={email}
						aria-describedby="emailHelp"
					/>

					<textarea
						className="form-control contactMessage"
						placeholder="Message"
						id="message"
						rows="10"
						onChange={(e) => setMessage(e.target.value)}
						value={message}
						aria-describedby="messageHelp"
					/>
					<button type="submit" className="btn text-white contactSubmit">
						Send
					</button>
				</form>
				<p hidden={hidden}>We will contact you shortly</p>
			</div>
		</section>
	)
}