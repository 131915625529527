import React from "react";
import Header from "../../Components/Header/Header";
// import Cookies from 'js-cookie';
import Via from './_via_attribute'
// import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { checkLogin } from "../../Components/login";
const Annotator = () => {
  // const navigate = useNavigate()
  const {loggedIn} = useSelector((state) => state.loggedIn)
  // loggedIn = loggedIn.value
  console.log(loggedIn);
  // componentDidMount() {
  //   if (
  //     this.state.userCookie &&
  //     this.state.token &&
  //     this.state.userCookie.length > 0 &&
  //     this.state.token.length > 0 && !this.state.containerLoaded
  //   ) {
  //     this.setState({ loggedIn: true })
  //   }
  // }


  function redirectHome() {
    // return <div>home</div>
    // navigate('/')
    window.location.href='/'
    return <div>Redirecting to home...</div>
  }

  function loadAnnotator() {
    // console.log('success1');
    return (
      <div style={{
        background: "#fff8",
        backdropFilter: "blur(5px)"
      }}>
        <Header />
        <Via />
      </div>
    );
  }

    if (loggedIn) {
      const elm = loadAnnotator()
      return elm
    } else {
      const login = checkLogin()
      if(login) {
        return loadAnnotator()
      }else {
        return redirectHome()
      }
    }
}
export default Annotator;


{/* 
          <!-- TODO for adding multiple import/export options -->
          <div data-pageid="page_import_export" className="via_page">
            <div className="toolbar"><span onclick="_via_util_page_hide(e)" className="text_button">&times;</span></div>
            <h2>Export</h2>
            <ul>
              <li>Select Export Format:
                <select id="via_page_export_format">
                  <option value="via3_csv">VIA3 (CSV)</option>
                  <option value="temporal_segments_csv">Only Temporal Segments as CSV</option>
                </select>
              </li>
            </ul>
            <h2>Import</h2>
            <ul>
              <li>VIA Shared Project: <input id="via_page_import_pid" type="text"
                placeholder="e.g. 71578187-3cd3-45d0-8198-7c441fbc06af" style={{"width":"25em"}} />
              </li>
              <li>VIA2 project created (json file):
                <input id="via_page_import_via2_project_json" type="file" />
              </li>
            </ul>
  
            <div className="controls">
              <button id="via_page_button_import" onclick="_via_util_page_process_action(e)">Import</button>
              <button id="via_page_button_export" onclick="_via_util_page_process_action(e)">Export</button>
              <button onclick="_via_util_page_hide(e)">Cancel</button>
            </div>
          </div>
  
          <div data-pageid="page_fileuri_bulk_add" className="via_page">
            <div className="toolbar"><span onclick="_via_util_page_hide(e)" className="text_button">&times;</span></div>
            <p>File Type:&nbsp;<select id="via_page_fileuri_filetype">
              <option value="2" selected>Image</option>
            </select>
            </p>
            <h2>Paste File URI (one URI per line)</h2>
            <textarea id="via_page_fileuri_urilist"
              placeholder="For example, (1) http://www.robots.ox.ac.uk/~vgg/software/via/images/via_logo.png ; (2) file:///data/images/img001.jpg ; (3) file:///C:/Documents%20and%20Settings/tlm/video001.mp4"
              rows="10" cols="80"></textarea>
            <h2>Import URI from a File</h2>
            <input id="via_page_fileuri_importfile" type="file" />
  
            <div className="controls">
              <button id="via_page_fileuri_button_bulk_add" onclick="_via_util_page_process_action(e)">Add</button>
              <button onclick="_via_util_page_hide(e)">Cancel</button>
            </div>
          </div> */}

{/* <div data-pageid="page_demo_instructions" className="via_page">
            <div className="toolbar"><span onClick={_via_util_page_hide} className="text_button">&times;</span></div>
            <h1>Some Quick Tips</h1>
            <ul>
              <li>Click and drag mouse cursor to define a region around an object.</li>
              <li>Use the <span className="text_button" onClick="via.editor.show()">attribute editor</span> to define or
                update
                attributes (e.g. name, colour, etc) of user defined regions.</li>
            </ul>
          </div> */}
