import {
  Route,
  Routes,
  BrowserRouter
} from "react-router-dom";
import Modal from "../Components/Modal/Modal";
import AboutUs from "../pages/AboutUs";
import Annotator from "../pages/annotator/Annotator";
import User_page from "../pages/productSpecific/demo";
// import { data } from "../pages/Calculator/data";
import ContactUs from "../pages/ContactUs";
import Home from "../pages/Home/Home";
import Product from "../pages/Product/Product";
import { checkLogin } from '../Components/login';
import loggedInSlice from "../redux/slices/loggedIn-slice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Calculator from "../pages/Calculator/Calculator";
// import ChatBot from "../Components/Demo/chatBot";
import ProlabMVP from "../pages/companies/prolab/mvp";
import PreProcessing from "../pages/development/preprocessing";
import ReceiptAnnotation from "../pages/development/receiptAnnotation";
// import PrivateRoute from "./PrivateRoute";
// import PublicRoute from "./PublicRoute";

function MySwitch() {
  // const location = useLocation();
  return (
    // <Switch
    //  location={location}
    //  >
    //   <PrivateRoute path="/annotator">
    //     <Annotator input={data} />
    //   </PrivateRoute>

    //   <PublicRoute path="/">
    //     <Home />
    //   </PublicRoute>

    //   <PublicRoute path="/about">
    //     <Home />
    //   </PublicRoute>

    //   <Route path="*">
    //     <Redirect to="/" />
    //   </Route>
    // </Switch>
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/product" element={<Product />} />
      <Route exact path="/about" element={<AboutUs />} />
      <Route exact path="/contact" element={<ContactUs />} />
      <Route exact path="/annotator" element={<Annotator />} />
      <Route exact path="/user_login" element={<User_page />} />
      <Route exact path="/calculator" element={<Calculator />} />
      {/* <Route exact path="/demo/chatbot" element={<ChatBot />} /> */}
      <Route exact path="/prolab/mvp" element={<ProlabMVP />} />
      <Route exact path="/idaa/preprocessing" element={<PreProcessing />} />
      <Route exact path="/annotation/receipt" element={<ReceiptAnnotation />} />
    </Routes>
  );
}

const AppRouter = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    const login = checkLogin()
    // console.log('app router effect called****************')
    // console.log('login in app router is '+login);
    dispatch(loggedInSlice.actions.changeLogin(login))
  })
  return (
    <BrowserRouter>
      <Modal />
      <MySwitch />
    </BrowserRouter>
  );
};

export default AppRouter;
