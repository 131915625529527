// import React from 'react'
import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
    name: "modal",
    initialState: {
      visible: false,
      dismissable: true,
    },
    reducers: {
      showModal: (state, { payload }) => {
        return { ...state, ...payload, visible: true };
      },
      hideModal: (state) => {
        state.visible = false;
      },
    },
  });

export default modalSlice
