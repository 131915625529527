import React, { useContext, useState } from "react";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LoanDescription = ({ data, setData }) => {
  const [loanDescription, setLoanDescription] = useState([
    { loanDescription: "" },
  ]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...loanDescription];
    list[index][name] = value;
    setLoanDescription(list);
    setData({ ...data, page3: { ...data.page3, loanDescription: list } });
  };

  const handleAddClick = () => {
    setLoanDescription([...loanDescription, { loanDescription: "" }]);
  };

  const handleRemoveClick = (index) => {
    const list = [...loanDescription];
    list.splice(index, 1);
    setLoanDescription(list);
  };

  return (
    <div>
      {loanDescription.map((x, i) => (
        <div key={i} className="border rounded p-2 mt-3">
          <div className="d-flex align-items-center justify-content-between">
            <p style={{ fontWeight: "bold" }}></p>
            <div>
              {loanDescription.length - 1 === i && (
                <FontAwesomeIcon
                  role="button"
                  onClick={handleAddClick}
                  icon={faPlusCircle}
                  style={{
                    color: "#21209c",
                    height: "21",
                    width: "21",
                  }}
                />
              )}
              {loanDescription.length !== 1 && (
                <FontAwesomeIcon
                  role="button"
                  onClick={() => handleRemoveClick(i)}
                  icon={faMinusCircle}
                  style={{
                    color: "#e2142d",
                    height: "21",
                    width: "21",
                    marginLeft: "0.8em",
                  }}
                />
              )}
            </div>
          </div>
          <div className="row g-3 align-items-center mb-1">
            <div className="col-6">
              <label htmlFor="inputPassword6" className="col-form-label">
                Loan Description
              </label>
            </div>
            <div className="col-6">
              <input
                type="text"
                className="form-control"
                placeholder="Description"
                name="loanDescription"
                value={x.loanDescription}
                onChange={(e) => handleInputChange(e, i)}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LoanDescription;
