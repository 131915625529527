import React from "react";
import './whatwedo.css';

const whatWeDoList = [
	{
		title: 'Artificial Intelligence',
		text: 'Tailored AI solutions to improve business operations',
		curvesClass: 'whatWeDo-TRBL'
	},
	{
		title: 'Cloud/Edge Solutions',
		text: 'Modernized apps or services meeting business needs',
		curvesClass: 'whatWeDo-TLBR'
	},
	{
		title: 'Data Engineering',
		text: 'Solutions to business issues around data collection, mining and analysis.',
		curvesClass: 'whatWeDo-TRBL'
	},
	{
		title: 'Product Commissioning',
		text: 'Services to inspect and test the operational systems been used in an organization',
		curvesClass: 'whatWeDo-TLBR'
	},
	{
		title: 'Research',
		text: 'Consulting services from experts to businesses around various domains',
		curvesClass: 'whatWeDo-TLBR'
	},
	{
		title: 'Full Stack Developments',
		text: 'Tailored websites and applications for improved business processes',
		curvesClass: 'whatWeDo-TRBL'
	},
	{
		title: 'Advanced SEO',
		text: 'Optimization of website for better customer reach',
		curvesClass: 'whatWeDo-TLBR'
	},
	{
		title: 'Digital Marketing',
		text: 'Digital promotions of companies for better customer connections',
		curvesClass: 'whatWeDo-TRBL'
	}
]
export default function WhatWeDo() {
	return (
		<section className="sectionMain">
			<div className="sectionHeading">
				<span className="colorSec">What</span> we do?
			</div>
			<div className="sectionSubHeading">
			The rapidly growing digital world has a pressing dual need for both speed and accuracy.
			</div>
			<div className="whatwedo-details row">
			{whatWeDoList.flatMap((slide, index) => (
              <div key={'whatwedo-'+index} className={`col-sm-3 whatwedo-slides-wrapper`}>
				  <div className={`whatwedo-slides-data ${slide.curvesClass}`}>
                <div className="whatwedo-list-heading">
					{slide.title}
				</div>
				<div className="whatwedo-list-text">
					{slide.text}
				</div>
				</div>
              </div>
            ))}
			</div>
		</section>
	)
}