import {_via_event} from './viaEvent'
import {_VIA_FILE_TYPE, _via_util_msg_show, _VIA_FILE_SELECT_TYPE, _via_util_load_text_file} from './utils'
export class _via_control_panel extends _via_event{
	constructor(via, container) {
		super()
		this.state = {
			_ID: "_via_control_panel_"
		}
		this.state.via = via
		this.via = via
		this.c= container
		// this.updateFn = updateFn
		// registers on_event(), emit_event(), ... methods from
		// _via_event to let this module listen and emit events
		// new _via_event(this);
		this._init();
	}
	_init () {
		this._add_spacer();
		this.c.appendChild(this.state.via.vm.c);
		this._add_spacer();
		this._add_view_manager_tools();

		this._add_spacer();
		this._add_project_tools();
		this._add_spacer();
		this._add_attribute_selection();
		console.log('reached');
		// this.updateFn('control_panel_container', this.c)
	};

	_add_spacer () {
		var spacer = document.createElement("div");
		spacer.setAttribute("class", "spacer");
		this.c.appendChild(spacer);
	};

	_on_view_attribute_change (e) {
		let newValue = e.target.options[e.target.selectedIndex].value;
		const prevValue = this._selectedAttributeParams.type;
		if (prevValue !== newValue) {
			this._selectedAttributeParams.type = newValue;
			if (newValue === "OTHERS") {
				// TODO
				// this._newOthersAttribute.classList.remove('d-none')
				// this._addAttributeToList.classList.remove('d-none')
			} else {
				this._selectedAttributeParams.subFields = [];
				// TODO
				// this._newOthersAttribute.classList.add('d-none')
				// this._addAttributeToList.classList.add('d-none')
			}
		}
	};
	_on_new_attribute () {
		// console.log('called');
		const existingValues = this._selectedAttributeParams.subFields;
		// console.log(existingValues);
		const newParamValue = this._newOthersAttribute.value;
		// console.log('new param value is: '+ newParamValue);
		if (!existingValues.includes(newParamValue)) {
			this._selectedAttributeParams.subFields.push(newParamValue);
			this._newOthersAttribute.value = "";
		}
		// console.log(this._selectedAttributeParams.subFields);
	};
	_showAttributeInCanvas (parent) {
		// console.log('in function');
		// TODO
		// const elm = parent.smetadata_container
		// elm.classList.remove('hide');
		// while (elm.firstChild) {
		//   elm.removeChild(elm.firstChild);
		// }
		// const selectInput = document.createElement('select')
		// selectInput.setAttribute('id', 'selectOthersAttribute')
		// const options = this._getOtherOptions()
		// options.forEach(function (option) {
		//   selectInput.appendChild(option)
		// })
		// elm.appendChild(selectInput)
	};
	_getOtherOptions () {
		const list = this._selectedAttributeParams.subFields;
		const options = [];
		list.forEach(function (value) {
			const elm = document.createElement("option");
			elm.setAttribute("value", value);
			elm.innerHTML = value;
			options.push(elm);
		});
		return options;
	};
	_add_attribute_selection () {
		this._selectAttributeElm = document.createElement("select");
		this._selectAttributeElm.setAttribute("class", "view_selector");
		this._selectAttributeElm.setAttribute("id", "view_selector");
		this._selectAttributeElm.setAttribute("title", "Select type of document");
		this._selectAttributeElm.addEventListener(
			"change",
			this._on_view_attribute_change.bind(this)
		);
		// TODO poplate options and subFields from file
		const option1 = document.createElement("option");
		option1.setAttribute("value", "IRS");
		option1.innerHTML = "Income Tax Statement";
		const option2 = document.createElement("option");
		option2.setAttribute("value", "OTHERS");
		option2.innerHTML = "Others";
		this._selectAttributeElm.value = "OTHERS";
		this._selectedAttributeParams = { type: "OTHERS", subFields: [] };
		// TODO testing uncomment
		// this._selectAttributeElm.value = "IRS"
		// this._selectedAttributeParams = { type: "IRS", subFields: [] }
		// this._selectAttributeElm.appendChild(option1)
		this._selectAttributeElm.appendChild(option2);
		this._selectAttributeElm.appendChild(option1);
		const pushTo = this._selectAttributeElm
		const options = ["10th", "12th", "DL", "DelhiPolice", "IndoID"] 
		const optionsText = ["10th", "12th", "Indian Driving License", "Delhi Police", "Indonesian Id"]

		for(let i=0; i<options.length; i++) {
			const temp = document.createElement("option");
			temp.setAttribute("value", options[i]);
			temp.innerHTML = optionsText[i];
			pushTo.appendChild(temp);
		}

		this.c.appendChild(this._selectAttributeElm);
		// TODO uncomment when other attributes is ready
		this._add_custom_attribute_options();
	};
	_add_custom_attribute_options () {
		this._newOthersAttribute = document.createElement("input");
		this._newOthersAttribute.setAttribute("type", "text");
		this._newOthersAttribute.setAttribute("style", "width:8em;padding:0px 6px;");
		this._newOthersAttribute.setAttribute("placeholder", "Param name");
		this._newOthersAttribute.className = "d-none";
		this.c.appendChild(this._newOthersAttribute);

		this._addAttributeToList = _via_util_get_svg_button(
			"micon_add_circle",
			"Add param to list",
			"add_attribute_local"
		);
		// addAttributeToList.className = 'd-none'
		this._addAttributeToList.setAttributeNS(null, "class", "svg_button d-none");
		this._addAttributeToList.setAttribute("id", "newOthersAttributePlus");
		this._addAttributeToList.addEventListener(
			"click",
			this._on_new_attribute.bind(this)
		);
		this.c.appendChild(this._addAttributeToList);
	};

	_add_view_manager_tools () {
		var prev_view = _via_util_get_svg_button(
			"micon_navigate_prev",
			"Show Previous File",
			"show_prev"
		);
		prev_view.addEventListener(
			"click",
			this.state.via.vm._on_prev_view.bind(this.state.via.vm)
		);
		this.c.appendChild(prev_view);

		var next_view = _via_util_get_svg_button(
			"micon_navigate_next",
			"Show Next File",
			"show_next"
		);
		next_view.addEventListener(
			"click",
			this.state.via.vm._on_next_view.bind(this.state.via.vm)
		);
		this.c.appendChild(next_view);

		var add_media_local = _via_util_get_svg_button(
			"micon_add_circle",
			"Add Image or PDF from Local Computer",
			"add_media_local"
		);
		add_media_local.addEventListener(
			"click",
			this.state.via.vm._on_add_media_local.bind(this.state.via.vm)
		);
		this.c.appendChild(add_media_local);

		var add_media_bulk = _via_util_get_svg_button(
			"micon_lib_add",
			"Bulk add file URI ( e.g. file:///... or http://... ) contained in a local CSV file where each row is a remote or local filename.",
			"add_media_bulk"
		);
		//add_media_bulk.addEventListener('click', this.state.via.vm._on_add_media_bulk.bind(this.state.via.vm));
		// add_media_bulk.addEventListener(
		// 	"click",
		// 	function () {
		// 		var action_map = {
		// 			via_page_fileuri_button_bulk_add:
		// 				this._page_on_action_fileuri_bulk_add.bind(this),
		// 		};
		// 		_via_util_page_show("page_fileuri_bulk_add", action_map);
		// 	}.bind(this)
		// );
		// this.c.appendChild(add_media_bulk);

		var del_view = _via_util_get_svg_button(
			"micon_remove_circle",
			"Remove the Current File",
			"remove_media"
		);
		del_view.addEventListener(
			"click",
			this.state.via.vm._on_del_view.bind(this.state.via.vm)
		);
		this.c.appendChild(del_view);
	};
	_set_region_shape (shape) {
		this.state.via.va.set_region_draw_shape("_via");
		for (var si in this.shape_selector_list) {
			if (si === shape) {
				this.shape_selector_list[si].classList.add("svg_button_selected");
			} else {
				this.shape_selector_list[si].classList.remove("svg_button_selected");
			}
		}
	};
	_add_project_tools () {
		// TODO add back after export is enabled
		// var load = _via_util_get_svg_button(
		// 	"micon_open",
		// 	"Open existing via project"
		// );
		// load.addEventListener(
		// 	"click",
		// 	function () {
		// 		this.via.vm._via_util_file_select_local(
		// 			_VIA_FILE_SELECT_TYPE.JSON,
		// 			this._project_load_on_local_file_select.bind(this),
		// 			false
		// 		);
		// 	}.bind(this)
		// );
		// console.log(this.c);
		// console.log(typeof this.c);
		// this.c.appendChild(load);

		var save = _via_util_get_svg_button("micon_save", "Save current document");
		save.addEventListener('click', function () {
			this.via.d.project_save();
		  }.bind(this));
		this.c.appendChild(save);
		
		// TODO add back to add import/export
		// var import_export_annotation = _via_util_get_svg_button(
		// 	"micon_import_export",
		// 	"Open existing documents"
		// );
		// import_export_annotation.addEventListener(
		// 	"click",
		// 	this._page_show_import_export.bind(this)
		// );
		// this.c.appendChild(import_export_annotation);
	};
	_page_show_import_export (d) {
		var action_map = {
			via_page_button_import: this._page_on_action_import.bind(this),
			via_page_button_export: this._page_on_action_export.bind(this),
		};
		_via_util_page_show("page_import_export", action_map);
	};
	_page_on_action_import (d) {
		if (d._action_id === "via_page_button_import") {
			if (d.via_page_import_pid !== "") {
				this.state.via.s._project_pull(d.via_page_import_pid).then(
					function (remote_rev) {
						try {
							var project = JSON.parse(remote_rev);
							// clear remote project identifiers
							project.project.pid = _VIA_PROJECT_ID_MARKER;
							project.project.rev = _VIA_PROJECT_REV_ID_MARKER;
							project.project.rev_timestamp = _VIA_PROJECT_REV_TIMESTAMP_MARKER;
							this.state.via.d.project_load_json(project);
						} catch (e) {
							_via_util_msg_show("Malformed response from server: " + e);
						}
					}.bind(this),
					function (err) {
						_via_util_msg_show(err + ": " + d.via_page_import_pid);
					}.bind(this)
				);
				return;
			}

			if (d.via_page_import_via2_project_json.length === 1) {
				_via_util_load_text_file(
					d.via_page_import_via2_project_json[0],
					this._project_import_via2_on_local_file_read.bind(this)
				);
				return;
			}
			_via_util_msg_show(
				"To import an existing shared project, you must enter its project-id."
			);
		}
	};
	_page_on_action_export (d) {
		if (d._action_id === "via_page_button_export") {
			this.state.via.ie.export_to_file(d.via_page_export_format);
		}
	};
	_project_load_on_local_file_select (e) {
		if (e.target.files.length === 1) {
			_via_util_load_text_file(
				e.target.files[0],
				this._project_load_on_local_file_read.bind(this)
			);
		}
	};
	_project_load_on_local_file_read (
		project_data_str
	) {
		this.state.via.d.project_load(project_data_str);
	};
	_project_import_via2_on_local_file_read =
		function (project_data_str) {
			this.state.via.d.project_import_via2_json(project_data_str);
		};
	_share_show_info () {
		if (this.state.via.d.project_is_remote()) {
			this.state.via.s.exists(this.state.via.d.store.project.pid).then(
				function () {
					this.state.via.s._project_pull(this.state.via.d.store.project.pid).then(
						function (ok) {
							try {
								var d = JSON.parse(ok);
								var remote_rev_timestamp = new Date(
									parseInt(d.project.rev_timestamp)
								);
								var local_rev_timestamp = new Date(
									parseInt(this.state.via.d.store.project.rev_timestamp)
								);

								var pinfo = "<table>";
								pinfo +=
									"<tr><td>Project Id</td><td>" + d.project.pid + "</td></tr>";
								pinfo +=
									"<tr><td>Remote Revision</td><td>" +
									d.project.rev +
									" (" +
									remote_rev_timestamp.toUTCString() +
									")</td></tr>";
								pinfo +=
									"<tr><td>Local Revision</td><td>" +
									this.state.via.d.store.project.rev +
									" (" +
									local_rev_timestamp.toUTCString() +
									")</td></tr>";
								pinfo += "</table>";
								if (d.project.rev !== this.state.via.d.store.project.rev) {
									pinfo +=
										'<p>Your version of this project is <span style="color:red;">old</span>. Press <svg class="svg_icon" onclick="" viewbox="0 0 30 30"><use xlink:href="#micon_download"></use></svg> to fetch the most recent version of this project.</p>';
								} else {
									pinfo +=
										'<p>You already have the <span style="color:blue;">latest</span> revision of this project.</p>';
								}

								document.getElementById("via_page_share_project_info").innerHTML =
									pinfo;
								document.getElementById("via_page_share_project_id").innerHTML =
									d.project.pid;
								_via_util_page_show("page_share_already_shared");
							} catch (e) {
								console.log(e);
								_via_util_msg_show("Malformed server response." + e);
							}
						}.bind(this),
						function (pull_err) {
							_via_util_msg_show("Failed to pull project.");
							console.warn(pull_err);
						}.bind(this)
					);
				}.bind(this),
				function (exists_err) {
					_via_util_page_show("page_share_not_shared_yet");
					console.warn(exists_err);
				}.bind(this)
			);
		} else {
			_via_util_page_show("page_share_not_shared_yet");
		}
	};
	_share_show_pull () {
		if (this.state.via.d.project_is_remote()) {
			// check if remote project has newer version
			this.state.via.s._project_pull(this.state.via.d.store.project.pid).then(
				function (ok) {
					try {
						var d = JSON.parse(ok);
						if (d.project.rev === this.state.via.d.store.project.rev) {
							_via_util_msg_show(
								"You already have the latest revision of this project"
							);
							return;
						} else {
							this.state.via.d.project_merge_rev(d);
						}
					} catch (e) {
						_via_util_msg_show("Malformed response from server.");
						console.warn(e);
					}
				}.bind(this),
				function (err) {
					_via_util_msg_show("Failed to pull project.");
					console.warn(err);
				}.bind(this)
			);
		} else {
			var action_map = {
				via_page_button_open_shared: this._page_on_action_open_shared.bind(this),
			};
			_via_util_page_show("page_share_open_shared", action_map);
		}
	};
	_page_on_action_open_shared (d) {
		if (d._action_id === "via_page_button_open_shared") {
			this.state.via.s.pull(d.via_page_input_pid);
		}
	};
	_page_on_action_fileuri_bulk_add (d) {
		if (d.via_page_fileuri_urilist.length) {
			this.fileuri_bulk_add_from_url_list(d.via_page_fileuri_urilist);
		}

		if (d.via_page_fileuri_importfile.length === 1) {
			switch (parseInt(d.via_page_fileuri_filetype)) {
				case _VIA_FILE_TYPE.IMAGE:
					_via_util_load_text_file(
						d.via_page_fileuri_importfile[0],
						this.fileuri_bulk_add_image_from_file.bind(this)
					);
					break;
				case _VIA_FILE_TYPE.AUDIO:
					_via_util_load_text_file(
						d.via_page_fileuri_importfile[0],
						this.fileuri_bulk_add_audio_from_file.bind(this)
					);
					break;
				case _VIA_FILE_TYPE.VIDEO:
					_via_util_load_text_file(
						d.via_page_fileuri_importfile[0],
						this.fileuri_bulk_add_video_from_file.bind(this)
					);
					break;
				default:
					_via_util_load_text_file(
						d.via_page_fileuri_importfile[0],
						this.fileuri_bulk_add_auto_from_file.bind(this)
					);
			}
		}
	};
	fileuri_bulk_add_image_from_file (
		uri_list_str
	) {
		console.log('adding image from file');
		this.fileuri_bulk_add_from_url_list(uri_list_str, _VIA_FILE_TYPE.IMAGE);
	};
	fileuri_bulk_add_audio_from_file (
		uri_list_str
	) {
		this.fileuri_bulk_add_from_url_list(uri_list_str, _VIA_FILE_TYPE.AUDIO);
	};
	fileuri_bulk_add_video_from_file (
		uri_list_str
	) {
		this.fileuri_bulk_add_from_url_list(uri_list_str, _VIA_FILE_TYPE.VIDEO);
	};
	fileuri_bulk_add_video_from_file (
		uri_list_str
	) {
		this.fileuri_bulk_add_from_url_list(uri_list_str, _VIA_FILE_TYPE.VIDEO);
	};
	fileuri_bulk_add_auto_from_file (
		uri_list_str
	) {
		this.fileuri_bulk_add_from_url_list(uri_list_str, 0);
	};
	fileuri_bulk_add_from_url_list (
		uri_list_str,
		type
	) {
		var uri_list = uri_list_str.split("\n");
		if (uri_list.length) {
			var filelist = [];
			for (var i = 0; i < uri_list.length; ++i) {
				if (uri_list[i] === "" || uri_list[i] === " " || uri_list[i] === "\n") {
					continue; // skip
				}
				var filetype;
				if (type === 0 || typeof type === "undefined") {
					filetype = _via_util_infer_file_type_from_filename(uri_list[i]);
				} else {
					filetype = type;
				}

				filelist.push({
					fname: uri_list[i],
					type: filetype,
					loc: _via_util_infer_file_loc_from_filename(uri_list[i]),
					src: uri_list[i],
				});
			}
			this.state.via.vm._file_add_from_filelist(filelist);
		}
	};
}
const _VIA_SVG_NS = "http://www.w3.org/2000/svg";
function _via_util_get_svg_button(icon_id, title, id) {
	// xmlns="http://www.w3.org/2000/svg"
	// 		xmlnsXlink="http://www.w3.org/1999/xlink"
	const el = document.createElement('img')
	el.setAttribute('src', 'orig/images/'+icon_id+'.png')
	// var el = document.createElementNS(_VIA_SVG_NS, "svg");
	// el.setAttributeNS(null, "viewBox", "0 0 30 30");
	// el.innerHTML =
	//   '<use xlink:href="#' + icon_id + '"></use><title>' + title + "</title>";
	el.classList.add("svg_button");
	if (typeof id !== "undefined") {
	  el.setAttribute("id", id);
	}
	return el;
}