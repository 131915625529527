import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header/Header";
import { Navigation as SideNavigation } from 'react-minimal-side-navigation';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './demo.css'
function sleep(milliseconds) {
	let start = new Date().getTime();
	for (var i = 0; i < 1e7; i++) {
		if ((new Date().getTime() - start) > milliseconds) {
			break;
		}
	}
}
const User_page = () => {
	const [products, setProducts] = useState([])
	const [selectedItem, updateSelectedItem] = useState(0)
	const [selectedText, updateSelectedText] = useState('')
	const [selectedFiles, updateSelectedFiles] = useState([])
	const [uploadButton, toggleUploadButton] = useState(false)
	const [showCarousel, toggleCarousel] = useState(false)
	const [annotations, setAnnotations] = useState([])
	const allowedExtensions = ['.jpg', '.jpeg', '.png', '.bmp']
	useEffect(() => {
		fetch(BASE + "userProductList", {
			method: "GET",
			headers: {
				userid: getCookie("user"),
				accesstoken: getCookie("accesstoken")
			},
		}).then(products => products.json())
			.then(products => {
				console.log('setting products');
				console.log(products);
				setProducts(products)
			});
	}, [])

	function fileSelected(e) {
		toggleCarousel(false)
		console.log(e.target.files);
		if (e && e.target && e.target.files && e.target.files.length) {
			let selectedFiles = [...e.target.files]
			const files = []
			let ignoreFiles = 0

			selectedFiles.forEach(function (element) {
				const name = element.name
				// const nameWithoutExt = name.substr(0, name.lastIndexOf('.'))
				const ext = name.substr(name.lastIndexOf('.'), name.length - 1)
				if (allowedExtensions.includes(ext)) {
					files.push({ file: element, name: getFileName(ext) })
				} else {
					ignoreFiles++
				}
			})
			if (files.length) {
				updateSelectedFiles(files)
			}
			let text = ''
			if (files.length > 1) {
				text = files.length + ' files selected'
			} else if (files.length) {
				text = files[0].file.name + ' selected'
			}
			if (ignoreFiles) {
				if (text) {
					text += '. '
				}
				text += ignoreFiles
				if (ignoreFiles > 1) {
					text += ' files not supported yet'
				} else {
					text += ' file not supported yet'
				}
			}
			updateSelectedText(text)
			toggleUploadButton(true)
		}
	}

	async function uploadFiles() {
		// From service.js
		toggleUploadButton(false)
		updateSelectedText('Uploading files. This may take few minutes')
		await uploadFile(selectedFiles)
		// const filePromises = []
		const responses = []
		const file_ids = []
		for (let i = 0; i < selectedFiles.length; i++) {
			const res = await fetch(BASE + "saveAnnotations", {
				method: "POST",
				headers: getHeaders("application/json"),
				redirect: "follow", // manual, *follow, error
				body: JSON.stringify({
					annotations: [],
					file_name: selectedFiles[i].name,
					file_id: "",
					file_type: "IndoID",
					local_id: i,
				}),
			})
			const data = await res.json()
			file_ids.push(data.lId)
			await getResults(data.lId, selectedFiles[i].file)
		}
		

		// const text = await res.json()
		// 	responses.push(text)
		// 	console.log(text);
		// const responses = await Promise.all(filePromises)
		// for(let i=0; i<responses.length; i++) {

		// }
		updateSelectedText('')
		toggleCarousel(true)
	}
	async function getResults(file_id, selected_file) {
		let count = 5
		do {
			const finalArr = annotations
			updateSelectedText(`Processing ${finalArr.length+1}/${selectedFiles.length}`)
			sleep(3000)
			const AIResponse = await getAnnotations([file_id])
			let dataReceived = false
			AIResponse.annotations.annotations.forEach(function (elm) {
				if (elm.csv || elm.json || elm.txt) {
					finalArr.push({data: elm, file: selected_file})
					dataReceived = true
				}
			})
			if (dataReceived) {
				setAnnotations(finalArr)
				count = 0
			} else {
				sleep(1500)
				if (finalArr.length) {
					setAnnotations(finalArr)
				}
				count--;
			}
		} while (count > 0)
	}
	function getCarousel() {
		if (showCarousel) {
			const elements = []
			annotations.forEach(function (data, index) {
				elements.push(
					<div key={'carouselimages-' + index}>
						<div className="col-sm-4 d-inline-block carousel-block">
							<img src={URL.createObjectURL(data.file)} style={{ overflow: "hidden" }} className='d-inline' />
						</div>
						{/* <div className="col-sm-4 d-inline-block carousel-block">{annotations[index].txt}</div>
						<div className="col-sm-4 d-inline-block carousel-block"><pre>{JSON.stringify(annotations[index].json, null, 4)}</pre></div> */}
						<div className="col-sm-8 d-inline-block carousel-block"><pre>{JSON.stringify(data.data.json, null, 4)}</pre></div>
					</div>
				)
			})
			return (
				<Carousel showThumbs={false} className="carousel-main">
					{elements}
				</Carousel>
			)
		} else {
			return (<div />)
		}
	}

	function getSelectedRightPane(itemId) {
		if (itemId === 1) {
			return (
				<div className="demoRightPane">
					{/* <input directory="" webkitdirectory="" type="file" /> */}
					<label htmlFor="files" className="btn btn-label">Select File</label>
					<input id="files" style={{ "display": "none" }} type="file" onChange={fileSelected}
						accept={allowedExtensions.join(',')} />
					<label htmlFor="folder" className="btn btn-label">Select Folder</label>
					<input id="folder" directory="" webkitdirectory="" style={{ "display": "none" }}
						type="file" onChange={fileSelected} />
					<p className="statusText">{selectedText}</p>
					<input type='submit' className="btn btn-upload" hidden={!uploadButton} onClick={uploadFiles} value="Upload" />
				</div>
			)
		} else {
			return (
				<p>{itemId}</p>
			)
		}
	}

	if (products.length) {
		const navList = []
		products.forEach(element => {
			navList.push({
				title: element.name,
				itemId: element.id
			})
		});
		if (selectedItem === 0) {
			updateSelectedItem(navList[0].itemId)
		}
		return (
			<div>
				<Header />
				<div className="productBody">
					<div className="col-sm-3 d-inline-block productBodyLeft">
						<SideNavigation
							// you can use your own router's api to get pathname
							activeItemId={navList[0].itemId}
							onSelect={({ itemId }) => {
								// maybe push to the route
								updateSelectedItem(itemId)
							}}
							items={navList}
						/>
					</div>
					<div className="col-sm-9 d-inline-block productBodyRight">
						{getSelectedRightPane(selectedItem)}
					</div>
					{getCarousel()}
				</div>
				<Footer />
			</div>
		);
	} else {
		return (
			<p>Getting product list</p>
		)
	}
};

export default User_page;
