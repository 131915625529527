import React, { useContext, useState } from "react";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PropertyDescription = ({ data, setData }) => {
  const [propertyDescription, setPropertyDescription] = useState([
    { propertyDescription: "" },
  ]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...propertyDescription];
    list[index][name] = value;
    setPropertyDescription(list);
    setData({ ...data, page3: { ...data.page3, description: list } });
  };

  const handleAddClick = () => {
    setPropertyDescription([
      ...propertyDescription,
      { propertyDescription: "" },
    ]);
  };

  const handleRemoveClick = (index) => {
    const list = [...propertyDescription];
    list.splice(index, 1);
    setPropertyDescription(list);
  };

  return (
    <div>
      {propertyDescription.map((x, i) => (
        <div key={i} className="border rounded p-2 mt-3">
          <div className="d-flex align-items-center justify-content-between">
            <p style={{ fontWeight: "bold" }}></p>
            <div>
              {propertyDescription.length - 1 === i && (
                <FontAwesomeIcon
                  role="button"
                  onClick={handleAddClick}
                  icon={faPlusCircle}
                  style={{
                    color: "#21209c",
                    height: "21",
                    width: "21",
                  }}
                />
              )}
              {propertyDescription.length !== 1 && (
                <FontAwesomeIcon
                  role="button"
                  onClick={() => handleRemoveClick(i)}
                  icon={faMinusCircle}
                  style={{
                    color: "#e2142d",
                    height: "21",
                    width: "21",
                    marginLeft: "0.8em",
                  }}
                />
              )}
            </div>
          </div>
          <div className="row g-3 align-items-center mb-1">
            <div className="col-6">
              <label htmlFor="inputPassword6" className="col-form-label">
                Property Description
              </label>
            </div>
            <div className="col-6">
              <input
                type="text"
                className="form-control"
                placeholder="Description"
                name="propertyDescription"
                value={x.propertyDescription}
                onChange={(e) => handleInputChange(e, i)}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PropertyDescription;
