module.exports.checkLogin = () => {
	const user = getCookie('user')
	const token = getCookie('accesstoken')
	if (
		user &&
		token &&
		user.length > 0 &&
		token.length > 0
	  ) {
		return true
	  }
	  return false
}

module.exports.setCookie = (c_name, value, exdays) => {
	// console.log("called set cookie");
	let exdate = new Date();
	exdate.setDate(exdate.getDate() + exdays);
	let c_value =
		escape(value) +
		(exdays == null ? "" : "; expires=" + exdate.toUTCString()) +
		"; path=/";
	// console.log(c_name);
	// console.log(c_value);
	document.cookie = c_name + "=" + c_value;
	// console.log(document.cookie);
}