import React, { useEffect } from "react";
import { useMemo } from "react";
// import CalculatorContext from "../../../contexts/CalculatorContext";
import CustomLabel from "./CustomLabel";
import ExpenseCustomLabel from "./ExpenseCustomLabel";
import Note from "./Note";
import OtherCustomLabel from "./OtherCustomLabel";
import VacancyCustomLabel from "./VacancyCustomLabel";

const IncomeExpense = ({ data, setData }) => {
  const potentialGrossIncome = useMemo(() => {
    return (
      (data?.page3?.income?.commercialGrossIncome ?? 0) +
      (data?.page3?.income?.residentialGrossIncome ?? 0) +
      (data?.page3?.income?.custom
        ? Object.keys(data?.page3?.income?.custom).reduce(
            (pV, cV) => pV + Number(data?.page3?.income?.custom?.[cV]),
            0
          )
        : 0)
    );
  }, [data?.page3?.income]);

  const totalOtherIncome = useMemo(() => {
    return (
      (data?.page3?.otherIncome?.RETaxes ?? 0) +
      (data?.page3?.otherIncome?.utilities ?? 0) +
      (data?.page3?.otherIncome?.custom
        ? Object.keys(data?.page3?.otherIncome?.custom).reduce(
            (pV, cV) => pV + Number(data?.page3?.otherIncome?.custom?.[cV]),
            0
          )
        : 0)
    );
  }, [data?.page3?.otherIncome]);

  const totalPotentialGrossIncome = potentialGrossIncome + totalOtherIncome;

  const GIMwithoutReimbursement =
    data?.page2?.purchaseAmount / potentialGrossIncome;

  const GIMwithReimbursement =
    data?.page2?.purchaseAmount / totalPotentialGrossIncome;

  const effectiveGrossIncome = useMemo(() => {
    return (
      totalPotentialGrossIncome -
      ((data?.page3?.vacancy?.commercial ?? 0) +
        (data?.page3?.vacancy?.residential ?? 0) +
        (data?.page3?.vacancy?.custom
          ? Object.keys(data?.page3?.vacancy?.custom).reduce(
              (pV, cV) => pV + Number(data?.page3?.vacancy?.custom?.[cV]),
              0
            )
          : 0))
    );
  }, [totalPotentialGrossIncome, data?.page3?.vacancy]);

  const totalOperatingExpenses = useMemo(
    () =>
      data?.page3?.expense?.custom
        ? Object.keys(data?.page3?.expense?.custom).reduce(
            (pV, cV) => pV + Number(data?.page3?.expense?.custom?.[cV]),
            0
          )
        : 0,
    [data?.page3?.expense]
  );

  const netOperatingIncome = effectiveGrossIncome - totalOperatingExpenses;

  const loanAmount = data?.page2?.loanAmount


  const propertyValue = data.page3?.propertyValue

  // const interestPortion = data.page3?.interestPortion

  // const monthlyPI = data.page3?.monthlyPI
  const annualPI = data.page2?.annualPI;

  const excessFunds = netOperatingIncome - annualPI;

  const DSCR = netOperatingIncome / annualPI;

  const ROIloanInclude =
    (DSCR /
      (propertyValue - loanAmount + totalOperatingExpenses)) *
    100;

  const ROIonlyInterest =
    ((netOperatingIncome - annualPI * 12) /
      (propertyValue - loanAmount + totalOperatingExpenses)) *
    100;

  const ROIloanNotInclude =
    (netOperatingIncome /
      (propertyValue - loanAmount + totalOperatingExpenses)) *
    100;

  useEffect(() => {
    setData((a) => ({
      ...a,
      page3: {
        ...a.page3,
        potentialGrossIncome: potentialGrossIncome,
        totalOtherIncome: totalOtherIncome,
        totalPotentialGrossIncome: totalPotentialGrossIncome,
        GIMwithoutReimbursement: GIMwithoutReimbursement,
        GIMwithReimbursement: GIMwithReimbursement,
        effectiveGrossIncome: effectiveGrossIncome,
        totalOperatingExpenses: totalOperatingExpenses,
        netOperatingIncome: netOperatingIncome,
        annualPI: annualPI,
        excessFunds: excessFunds,
        DSCR: DSCR,
        ROIloanInclude: ROIloanInclude,
        ROIonlyInterest: ROIonlyInterest,
        ROIloanNotInclude: ROIloanNotInclude,
      },
    }));
  }, [
    ROIloanNotInclude,
    ROIonlyInterest,
    ROIloanInclude,
    DSCR,
    excessFunds,
    annualPI,
    netOperatingIncome,
    totalOperatingExpenses,
    effectiveGrossIncome,
    GIMwithReimbursement,
    totalPotentialGrossIncome,
    GIMwithoutReimbursement,
    totalOtherIncome,
    potentialGrossIncome,
  ]);

  return (
    <div className="border rounded p-2 mt-3">
      <p style={{ fontWeight: "bold" }}>Income Expense</p>
      <div className="row g-3 align-items-center ">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Source
          </label>
        </div>
        <div className="col-6">
          <input
            type="text"
            className="form-control"
            placeholder="Enter here"
          />
        </div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            *Commercial Gross Income
          </label>
        </div>
        <div className="col-6">
          <input
            type="number"
            className="form-control"
            onChange={(e) =>
              setData({
                ...data,
                page3: {
                  ...data.page3,
                  income: {
                    ...data.page3?.income,
                    commercialGrossIncome: Number(e.target.value ?? "0"),
                  },
                },
              })
            }
          />
        </div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            *Residential Gross Income
          </label>
        </div>
        <div className="col-6">
          <input
            type="number"
            className="form-control"
            onChange={(e) =>
              setData({
                ...data,
                page3: {
                  ...data.page3,
                  income: {
                    ...data.page3?.income,
                    residentialGrossIncome: Number(e.target.value ?? "0"),
                  },
                },
              })
            }
          />
        </div>
      </div>

      <CustomLabel data={data} setData={setData} />

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Potential Gross Income
          </label>
        </div>
        <div className="col-6">
          {potentialGrossIncome ? potentialGrossIncome?.toFixed(2) : 0}
        </div>
      </div>

      <div className=" mb-1 mt-3" style={{ fontWeight: "bold" }}>
        Other Income- *Reimbursement
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            *RE Taxes(over base year of '14-15')
          </label>
        </div>
        <div className="col-6">
          <input
            type="number"
            className="form-control"
            value={data?.page3?.RETaxes}
            onChange={(e) =>
              setData({
                ...data,
                page3: {
                  ...data.page3,
                  otherIncome: {
                    ...data.page3?.otherIncome,
                    RETaxes: Number(e.target.value),
                  },
                },
              })
            }
          />
        </div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            *Utilities, Water & Sewer & Heat
          </label>
        </div>
        <div className="col-6">
          <input
            type="number"
            className="form-control"
            value={data?.page3?.utilities}
            onChange={(e) =>
              setData({
                ...data,
                page3: {
                  ...data.page3,
                  otherIncome: {
                    ...data.page3?.otherIncome,
                    utilities: Number(e.target.value),
                  },
                },
              })
            }
          />
        </div>
      </div>

      <OtherCustomLabel data={data} setData={setData} />

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Total Other Income
          </label>
        </div>
        <div className="col-6">
          {totalOtherIncome ? totalOtherIncome.toFixed(2) : 0}
        </div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Total Potential Gross Income
          </label>
        </div>
        <div className="col-6">
          {totalPotentialGrossIncome
            ? totalPotentialGrossIncome?.toFixed(2)
            : 0}
        </div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            GIM without reimbursement
          </label>
        </div>
        <div className="col-6">
          {GIMwithoutReimbursement ? GIMwithoutReimbursement?.toFixed(2) : 0}
        </div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            GIM with reimbursement
          </label>
        </div>
        <div className="col-6">
          {GIMwithReimbursement ? GIMwithReimbursement?.toFixed(2) : 0}
        </div>
      </div>

      <div className="  mb-1 mt-3" style={{ fontWeight: "bold" }}>
        *Vacancy & Collection Loss
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            *Commercial
          </label>
        </div>
        <div className="col-6">
          <input
            type="number"
            className="form-control"
            value={data?.page3?.commercial}
            onChange={(e) =>
              setData({
                ...data,
                page3: {
                  ...data.page3,
                  vacancy: {
                    ...data.page3?.vacancy,
                    commercial: Number(e.target.value),
                  },
                },
              })
            }
          />
        </div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            *Residential
          </label>
        </div>
        <div className="col-6">
          <input
            type="number"
            className="form-control"
            value={data?.page3?.residential}
            onChange={(e) =>
              setData({
                ...data,
                page3: {
                  ...data.page3,
                  vacancy: {
                    ...data.page3?.vacancy,
                    residential: Number(e.target.value),
                  },
                },
              })
            }
          />
        </div>
      </div>

      <VacancyCustomLabel data={data} setData={setData} />

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Effective Gross Income
          </label>
        </div>
        <div className="col-6">
          {effectiveGrossIncome ? effectiveGrossIncome.toFixed(2) : 0}
        </div>
      </div>

      <div className="  mb-1 mt-3" style={{ fontWeight: "bold" }}>
        Operating Expenses
      </div>

      <ExpenseCustomLabel data={data} setData={setData} />

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Total Operating Expenses
          </label>
        </div>
        <div className="col-6">
          {totalOperatingExpenses ? totalOperatingExpenses.toFixed(2) : 0}
          {data[0]?.Validation?.TotalExpenses?.A}
        </div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Net Operating Income
          </label>
        </div>
        <div className="col-6">
          {netOperatingIncome ? netOperatingIncome.toFixed(2) : 0}
        </div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Annual P+I payment
          </label>
        </div>
        <div className="col-6">{annualPI ? annualPI.toFixed(2) : 0}</div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            Excess funds (Shortfall)
          </label>
        </div>
        <div className="col-6">{excessFunds ? excessFunds.toFixed(2) : 0}</div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            DSCR
          </label>
        </div>
        <div className="col-6">{DSCR ? DSCR.toFixed(2) : 0}</div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            ROI (P&I loan payment included)
          </label>
        </div>
        <div className="col-6">
          {ROIloanInclude ? ROIloanInclude.toFixed(2) : 0}
        </div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            ROI (Only Interest portion of loan payment included)
          </label>
        </div>
        <div className="col-6">
          {ROIonlyInterest ? ROIonlyInterest.toFixed(2) : 0}
        </div>
      </div>

      <div className="row g-3 align-items-center my-1">
        <div className="col-6">
          <label htmlFor="inputPassword6" className="col-form-label">
            ROI (P&I loan payment not included)
          </label>
        </div>
        <div className="col-6">
          {ROIloanNotInclude ? ROIloanNotInclude.toFixed(2) : 0}
        </div>
      </div>

      <Note data={data} setData={setData} />
    </div>
  );
};

export default IncomeExpense;
