import React from "react";
// import { useEffect } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
// import { useContext } from "react";
// import { useState } from "react";
// import CalculatorContext from "../../contexts/CalculatorContext";

const Calculator2 = ({ data, setData }) => {

  useDeepCompareEffect(() => {
    // console.log("called calci2 use effetct");
  }, [
    data
  ]);

  return (
    <>
      <div className="d-flex" style={{ fontWeight: "bold" }}>
        <div
          role="button"
          className={`p-1 px-3 ${!data.active ? "border" : ""}`}
          onClick={(e) => {
            setData({
              ...data,
              page2: {
                ...data.page2,
                type:e.target.value
              },
              active: true
            });
          }}
          style={
            data.active
              ? { backgroundColor: "#21209c", color: "white" }
              : { color: "#21209c" }
          }
        >
          New Purchase
        </div>
        <div
          role="button"
          className={`p-1 px-3 ${data.active ? "border" : ""}`}
          onClick={(e) => {
            console.log(data);
            setData({
              ...data,
              page2: {
                ...data.page2,
                type:e.target.value
              },
              active: false
            });
          }}
          style={
            !data.active
              ? { backgroundColor: "#21209c", color: "white" }
              : { color: "#21209c" }
          }
        >
          Refinance
        </div>
      </div>

      {data.active ? (
        // New Purchase

        <div>
          <div className="row g-3 align-items-center my-1">
            <div className="col-6">
              <label htmlFor="inputPassword6" className="col-form-label">
                Purchase Amount
              </label>
            </div>
            <div className="col-6">
              <input
                type="number"
                min="0"
                value={data?.page2?.purchaseAmount ?? ""}
                onChange={(e) => {
                  console.log(data);
                  setData({
                    ...data,
                    page2: {
                      ...data.page2,
                      purchaseAmount: Number(e.target.value),
                    },
                  });
                }}
                className="form-control"
                id="page2PurchaseAmountinput"
              />
            </div>
          </div>

          <div className="row g-3 align-items-center my-1">
            <div className="col-6">
              <label htmlFor="inputPassword6" className="col-form-label">
                Down Payment
              </label>
            </div>
            <div className="col-6">
              <input
                type="number"
                min="0"
                value={data?.page2?.downPayment ?? ""}
                onChange={(e) =>
                  setData({
                    ...data,
                    page2: {
                      ...data.page2,
                      downPayment: Number(e.target.value),
                    },
                  })
                }
                className="form-control"
                id="page2DownPaymentinput"
              />
            </div>
          </div>

          <div className="row g-3 align-items-center my-1">
            <div className="col-6">
              <label htmlFor="inputPassword6" className="col-form-label">
                Loan Amount
              </label>
            </div>
            <div className="col-6" id="page2LoanAmountValue">
              {data.page2?.loanAmount ? data.page2.loanAmount.toFixed(2) : 0}
            </div>
          </div>

          <div className="row g-3 align-items-center my-1">
            <div className="col-6">
              <label htmlFor="inputPassword6" className="col-form-label">
                Preliminary LTV
              </label>
            </div>
            <div className="col-6" id="page2PreliminaryLTVValue">
              {data.page2?.preliminaryLTV ? data.page2.preliminaryLTV.toFixed(2) : 0}
            </div>
          </div>
        </div>
      ) : (
        // Refinance

        <div>
          <div className="row g-3 align-items-center my-1">
            <div className="col-6">
              <label htmlFor="inputPassword6" className="col-form-label">
                Existing Debt
              </label>
            </div>
            <div className="col-6">
              <input
                type="number"
                min="0"
                className="form-control"
                id="page2existingDebtinput"
                defaultValue={0}
                value={data?.page2?.existingDebt ?? ""}
                onChange={(e) =>
                  setData({
                    ...data,
                    page2: {
                      ...data.page2,
                      existingDebt: Number(e.target.value),
                    },
                  })
                }
              />
            </div>
          </div>

          <div className="row g-3 align-items-center my-1">
            <div className="col-6">
              <label htmlFor="inputPassword6" className="col-form-label">
                Interest (%)
              </label>
            </div>
            <div className="col-6">
              <input
                type="number"
                min="0"
                defaultValue="0"
                className="form-control"
                id="page2existingDebtInterstinput"
                value={data?.page2?.interest ?? ""}
                onChange={(e) =>
                  setData({
                    ...data,
                    page2: {
                      ...data.page2,
                      interest: Number(e.target.value),
                    },
                  })
                }
              />
            </div>
          </div>

          <div className="row g-3 align-items-center my-1">
            <div className="col-6">
              <label htmlFor="inputPassword6" className="col-form-label">
                Monthly Principal and Interest
              </label>
            </div>
            <div className="col-6">
              <input
                type="number"
                min="0"
                className="form-control"
                id="page2monthlyPIinput"
                value={data?.page2?.monthlyPrincipalInterest ?? ""}
                onChange={(e) =>
                  setData({
                    ...data,
                    page2: {
                      ...data.page2,
                      monthlyPrincipalInterest: Number(e.target.value),
                    },
                  })
                }
              />
            </div>
          </div>

          <div className="row g-3 align-items-center my-1">
            <div className="col-6">
              <label htmlFor="inputPassword6" className="col-form-label">
                Annual Prinipal and Interest
              </label>
            </div>
            <div className="col-6" id="page2AnnualPIInput">
              {data.page2?.annualPI ? data.page2.annualPI.toFixed(2) : 0}
            </div>
          </div>

          <div className="row g-3 align-items-center my-1">
            <div className="col-6">
              <label htmlFor="inputPassword6" className="col-form-label">
                Existing Amortization (years)
              </label>
            </div>
            <div className="col-6">
              <input
                type="number"
                min="0"
                className="form-control"
                id="page2existingAmortizationInput"
                value={data?.page2?.existingAmortization ?? ""}
                onChange={(e) =>
                  setData({
                    ...data,
                    page2: {
                      ...data.page2,
                      existingAmortization: Number(e.target.value),
                    },
                  })
                }
              />
            </div>
          </div>

          <div className="row g-3 align-items-center my-1">
            <div className="col-6">
              <label htmlFor="inputPassword6" className="col-form-label">
                Existing Term (years)
              </label>
            </div>
            <div className="col-6">
              <input
                type="number"
                min="0"
                className="form-control"
                id="page2PurchaseAmountinput"
                value={data?.page2?.existingTerm ?? ""}
                onChange={(e) =>
                  setData({
                    ...data,
                    page2: {
                      ...data.page2,
                      existingTerm: Number(e.target.value),
                    },
                  })
                }
              />
            </div>
          </div>

          <div className="row g-3 align-items-center my-1">
            <div className="col-6">
              <label htmlFor="inputPassword6" className="col-form-label">
                Additional Cashout Requested
              </label>
            </div>
            <div className="col-6">
              <input
                type="number"
                min="0"
                className="form-control"
                id="page2PurchaseAmountinput"
                value={data?.page2?.additionalCashoutRequested ?? ""}
                onChange={(e) =>
                  setData({
                    ...data,
                    page2: {
                      ...data.page2,
                      additionalCashoutRequested: Number(e.target.value),
                    },
                  })
                }
              />
            </div>
          </div>

          <div className="row g-3 align-items-center my-1">
            <div className="col-6">
              <label htmlFor="inputPassword6" className="col-form-label">
                Total Refinance Amount
              </label>
            </div>
            <div className="col-6">
              {data.page2?.totalRefinanceAmount ? data.page2.totalRefinanceAmount.toFixed(2) : 0}
            </div>
          </div>

          <div className="row g-3 align-items-center my-1">
            <div className="col-6">
              <label htmlFor="inputPassword6" className="col-form-label">
                Approximate Value of Property
              </label>
            </div>
            <div className="col-6">
              <input
                type="number"
                min="0"
                className="form-control"
                id="page2PurchaseAmountinput"
                value={data?.page2?.approximateValueProperty ?? ""}
                onChange={(e) =>
                  setData({
                    ...data,
                    page2: {
                      ...data.page2,
                      approximateValueProperty: Number(e.target.value),
                    },
                  })
                }
              />
            </div>
          </div>

          <div className="row g-3 align-items-center my-1">
            <div className="col-6">
              <label htmlFor="inputPassword6" className="col-form-label">
                Approximate LTV
              </label>
            </div>
            <div className="col-6">
              {data.page2?.approximateLTV ? data.page2.approximateLTV.toFixed(2) : 0}
            </div>
          </div>
        </div>
      )}

      <div className="d-flex flex-row-reverse">
        <button
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseThree"
          aria-expanded="false"
          aria-controls="collapseThree"
          className="bg-primary rounded text-white py-1 px-3 mt-2 collapsed"
          style={{
            marginRight: "10px",
            fontWeight: "bold",
            borderColor: "#21209c",
          }}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default Calculator2;
